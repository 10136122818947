import React from 'react';
import { HashLoader } from 'react-spinners';

const FullScreenLoader = () => {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 w-full h-full z-[1000] flex justify-center items-center">
      <HashLoader size={60} color={'#996BE3'} />
    </div>
  );
};

export default FullScreenLoader;
