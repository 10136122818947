import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import lookup from 'country-code-lookup';

export type PersonalTableDataType = {
  type: string;
  information: any;
};

type Props = {
  data: PersonalTableDataType[];
  country: any;
};

const PersonalTable: React.FC<Props> = ({ data, country }) => {
  return (
    <table className="secondary text-[#6E7191]">
      <thead className="bg-[#F7F7FC]">
        <tr>
          <th className="!p-4 w-10">Data type</th>
          <th className="border-b">Core information</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            className={`${
              index % 2 === 1 ? 'bg-[#F7F7FC]' : 'bg-white'
            } font-semibold`}
            key={index}>
            <td className="bg-[#F7F7FC]">{item.type}</td>
            <td className="border-b">
              {['Registered IP:', 'Last logged IP:'].includes(item.type) &&
                lookup?.byCountry(country)?.iso2 && (
                  <ReactCountryFlag
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    countryCode={lookup.byCountry(country).iso2}
                    style={{
                      width: '1.25rem',
                      height: '1.25rem',
                      borderRadius: '50%',
                      marginRight: '0.5rem'
                    }}
                    svg
                  />
                )}
              {item.information}
            </td>
            {/* <td>{item.country && <img src={UKflag} alt="flag" />}</td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PersonalTable;
