import React, { useState, useMemo } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import Icon from '@/components/common/Icon';
import MapPin, { MapPinProps } from '@/components/common/MapPin';
import { UserGeographyItem } from '@/type/dashboard';
import mapFeature from '@/constants/map-feature.json';
import './style.css';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from "react";

type Props = {
  data: UserGeographyItem[];
};

const MapCard: React.FC<Props> = ({ data }) => {
  const currentTheme = useContext(CurrentThemeContext);
  const [pinData, setPinData] = useState<MapPinProps>({
    visible: false,
    x: 0,
    y: 0
  });

  const MapCardColor =
    currentTheme.theme === 'YELLOWAFRICA' ? '#D5A23D' : '#610BEF';

  const processed = useMemo(() => {
    const active: string[] = [];
    const users: number[] = [];
    data?.forEach((item) => {
      active.push(item.countryISO);
      users.push(item.activeUsers);
    });

    return {
      active,
      users
    };
  }, [data]);

  const handleMapOver = (geo: any) => (e: any) => {
    const index = processed.active.findIndex((item) => item === geo.id);
    setPinData({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      countryName: geo.properties.name,
      users: processed.users[index] || 0,
      changes: 5.3
    });
  };

  const handleCourseLeave = () => {
    setPinData({
      visible: false,
      x: 0,
      y: 0
    });
  };

  return (
    <div>
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-lg font-semibold">Users around the world</h3>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-[820px]">
          <ComposableMap
            projection="geoMercator"
            projectionConfig={{
              center: [0, 30],
              scale: 110
            }}
            onMouseLeave={handleCourseLeave}>
            <Geographies geography={mapFeature}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: {
                        fill: processed.active.includes(geo.id)
                          ? MapCardColor
                          : 'transparent',
                        stroke: MapCardColor
                      },
                      hover: {
                        fill: MapCardColor
                      },
                      pressed: {
                        fill: MapCardColor
                      }
                    }}
                    onClick={handleMapOver(geo)}
                  />
                ))
              }
            </Geographies>
          </ComposableMap>
          <MapPin data={pinData} />
        </div>
      </div>
    </div>
  );
};

export default MapCard;
