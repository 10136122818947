import React, { useMemo, useEffect } from 'react';
import Coin from '../Coin/index';
import { TableColumn } from '@/type/table';
import { styled } from '@mui/material/styles';
import {
  DataGridPro,
  gridClasses,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import 'react-tooltip/dist/react-tooltip.css';
import Box from '@mui/material/Box';

type Props = {
  data: Record<string, any>[];
  columns: TableColumn[];
  selectable?: boolean;
  pagination?: boolean;
  onClickAction?: (data: any) => void;
  odd?: boolean;
  hei?: string;
  size?: 'md' | 'lg' | 'sm';
  disableColumnFilter?: boolean;
  setGridReference?: any;
};

const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F7F7FC'
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#FCFCFC'
  },
  ['& .MuiDataGrid-columnHeader']: {
    backgroundColor: '#F7F7FC',
    fontSize: '14px'
  },
  ['& .MuiDataGrid-columnHeader:first-of-type']: {
    borderTopLeftRadius: '0.75rem'
  },
  ['& .MuiDataGrid-columnHeader:last-of-type']: {
    borderTopRightRadius: '0.75rem'
  },
  letterSpacing: 'normal',
  fontSize: '13px',
  borderRadius: '0.75rem',
  fontFamily: 'Sans-serif ',
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${'#d3d3d3'}`
  },
  '& .MuiDataGridPro-cell': {
    color: 'rgba(0,0,0,.85)'
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0
  }
}));

const Table: React.FC<Props> = ({
  data,
  columns,
  hei,
  disableColumnFilter,
  setGridReference
}) => {
  const apiRef = useGridApiRef();
  useEffect(() => {
    if (setGridReference) {
      setGridReference(apiRef);
    }
  }, [apiRef]);
  const cols: Array<any> = [];

  const user = useMemo(() => {
    const account = localStorage.getItem('dd_account');
    if (account) return JSON.parse(account);
    return null;
  }, []);

  columns.map((column) => {
    // fill the cols array with info from the columns data
    const newObject = {
      field: column.field,
      headerName: column.label,
      sortable: column.label === 'Comment' ? false : true,
      minWidth: 120,
      flex: 1,
      headerClassName: column.headerClassName ? column.headerClassName : '',
      renderCell: column.render,
      renderHeader: column.renderHeader ? column.renderHeader : ''
    };
    cols.push(newObject);
  });

  return (
    <Box sx={{ height: !hei ? '70vh' : '50vh', width: '100%' }}>
      <StripedDataGridPro
        getRowId={
          (row) =>
            row.transactionsId
              ? row.transactionsId // if we are inside transactions
              : row.id
              ? row.id // if we are inside users
              : row.employeeId
              ? row.employeeId // if we are inside employees
              : row.wallet // if we are inside wallet (inside specific user)
              ? row.wallet
              : row.createDate // if we are inside notes (inside specific user)
        }
        rows={data}
        rowCount={data.length}
        apiRef={apiRef}
        pagination
        columns={cols}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        disableColumnMenu={disableColumnFilter ? disableColumnFilter : false}
        disableChildrenSorting={
          disableColumnFilter ? disableColumnFilter : false
        }
      />
    </Box>
  );
};

export default Table;
