import React, { useState } from 'react';
import dayjs from 'dayjs';
import Table from '@/components/common/Table';
import { TableColumn } from '@/type/table';
import { UserNotes } from '@/type/user';
import Icon from '@/components/common/Icon';
import IconButton from '@/components/common/IconButton';
import AddNoteToUserModal from '@/components/widgets/modal/AddNoteToUserModal';
import ViewNoteModal from '@/components/widgets/modal/ViewNoteModal';

const addModalOpener = () => {
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);

  return (
    <>
      <div className="flex flex-between content-end">
        <div className="text">{'Comment'}</div>
        <IconButton
          className="hover:bg-slate-200 self-center ml-3"
          onClick={() => {
            setIsAddNoteModalOpen(true);
          }}>
          <Icon name="add" />
        </IconButton>
      </div>
      <AddNoteToUserModal
        open={isAddNoteModalOpen}
        onClose={() => setIsAddNoteModalOpen(false)}
      />
    </>
  );
};

const viewNoteModal = (row: any) => {
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);

  return (
    <>
      <div onClick={() => setIsAddNoteModalOpen(true)}>{row.value}</div>
      <ViewNoteModal
        open={isAddNoteModalOpen}
        subject={row.row.noteSubject}
        note={row.row.note}
        onClose={() => setIsAddNoteModalOpen(false)}
      />
    </>
  );
};

const commentColumn: TableColumn[] = [
  {
    field: 'employeeName',
    label: 'Employee'
  },
  {
    field: 'createDate',
    label: 'Date',
    render: (row: any) => (
      <div>{dayjs(row.value).format('DD MMM YYYY hh:mm')}</div>
    )
  },
  {
    field: 'noteSubject',
    label: 'Subject'
  },
  {
    field: 'note',
    label: 'Comment',
    headerClassName: 'w-full',
    renderHeader: addModalOpener,
    render: viewNoteModal
  }
];

type Props = {
  data: UserNotes;
};

const CommentTable: React.FC<Props> = ({ data }) => {
  return (
    <div className="bg-white rounded-xl">
      <Table
        columns={commentColumn}
        data={data}
        pagination={false}
        size="sm"
        odd={false}
        disableColumnFilter={true}
      />
    </div>
  );
};

export default CommentTable;
