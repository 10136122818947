import React, { useMemo } from 'react';
import Icon from '@/components/common/Icon';
import PieChart from '@/components/widgets/charts/PieChart';
import { chartColor } from '@/constants/colors';
import Card from '@/components/common/Card';
import { PieChartDataItem, DepositVsWithdraw } from '@/type/dashboard';

type Props = {
  data: DepositVsWithdraw;
};

const FinanceInfoCard: React.FC<Props> = ({ data }) => {
  const processed: PieChartDataItem[] = useMemo(() => {
    return [
      {
        label: 'Deposit',
        amount: data.deposit,
        changes: 1.2
      },
      {
        label: 'Withdraw',
        amount: data.withdraw,
        changes: 0.8
      }
    ];
  }, [data]);

  return (
    <Card title="Deposit / Withdraw">
      <div className="flex mt-7 items-center xl:flex-row flex-wrap justify-center">
        <PieChart data={processed} />

        <div className="flex flex-col gap-4 justify-center">
          {processed.map((item, index) => (
            <div key={index}>
              <div className="flex justify-between flex-wrap">
                <div className="flex">
                  <span
                    className="w-4 h-4 rounded-full mr-2 self-center"
                    style={{ backgroundColor: chartColor[index] }}
                  />
                  <p className="text-sm self-center">{item.label}</p>
                </div>
                <div className="pl-3 bg-[#EFF0F6] rounded-lg px-2 ml-2 py-1 self-center">
                  <div className="font-bold text-[#14142B]">
                    {item.amount && data.deposit && data.withdraw
                      ? `${(
                          (item.amount * 100) /
                          (data.deposit + data.withdraw)
                        ).toFixed(0)}%`
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default FinanceInfoCard;
