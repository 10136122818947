import Modal, { ModalProps } from '@/components/common/Modal';

type Props = Partial<ModalProps> & {
  image: string;
};

const ImageModal: React.FC<Props> = ({
  open = true,
  onClose = () => null,
  image
}) => {
  return (
    <Modal open={open} onClose={onClose} noBackground={true}>
      <img className="mt-5 rounded-2xl" src={image} />
    </Modal>
  );
};

export default ImageModal;
