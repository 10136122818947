import React, { useMemo } from 'react';
import Icon from '@/components/common/Icon';
import PieChart from '@/components/widgets/charts/PieChart';
import { chartColor } from '@/constants/colors';
import { toNormalizedNumber } from '@/utils/string';
import Card from '@/components/common/Card';
import { PieChartDataItem, Wallet } from '@/type/dashboard';

type Props = {
  data: Wallet[];
  setNonCustodialWalletsTotal: any;
  nonCustodialWalletsTotal: number;
};

const NonCustodialWalletsCard: React.FC<Props> = ({
  data,
  nonCustodialWalletsTotal,
  setNonCustodialWalletsTotal
}) => {
  const processed: PieChartDataItem[] = useMemo(() => {
    let totalAmountUSD = 0;
    const arr = data.map((dataItem) => {
      totalAmountUSD += dataItem.totalAmount;
      return {
        label: dataItem.walletType,
        amount: dataItem.percent || 0,
        changes: 1.2
      };
    });
    setTimeout(() => setNonCustodialWalletsTotal(totalAmountUSD), 0);
    return arr;
  }, [data]);

  return (
    <Card title="Non custodial wallets connected">
      {processed.length > 0 ? (
        <>
          <div className="flex items-center mb-6 -mt-2">
            <h3 className="font-bold text-title">{`${toNormalizedNumber(
              nonCustodialWalletsTotal
            )}`}</h3>
          </div>
          <div className="flex h-4/6 mt-7 items-center xl:flex-row flex-wrap justify-center">
            <PieChart data={processed} />

            <div className="flex flex-col gap-4 justify-center">
              {processed.map((item, index) => (
                <div key={index}>
                  <div className="flex justify-between flex-wrap">
                    <div className="flex items-center">
                      <span
                        className="w-2 h-2 rounded-full mr-2"
                        style={{
                          backgroundColor: chartColor[index]
                        }}
                      />
                      <img
                        src={`/wallets_icons/${item.label.toLowerCase()}.svg`}
                        className="mr-1"
                      />
                      <p className="text-sm">{item.label}:</p>
                    </div>
                    <div className="pl-3 bg-[#EFF0F6] rounded-lg px-2 ml-2 py-1">
                      <div className="font-bold text-[#14142B]">{`${item.amount.toFixed(
                        0
                      )}%`}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <h1 className="font-bold text-title">N/A</h1>
      )}
    </Card>
  );
};

export default NonCustodialWalletsCard;
