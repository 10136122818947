import React, { useState } from 'react';
import UserTable from '@/components/widgets/tables/UserTable';
import Button from '@/components/common/Button';
import FilterUser from '@/components/widgets/filters/FilterUser';
import { exportToJson } from '@/utils/download';
import HeaderSection from '@/components/widgets/sections/HeaderSection';

const Users = () => {
  const [gridReference, setGridReference] = useState();

  const triggerExportEvent = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (gridReference?.current?.exportDataAsCsv) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gridReference.current.exportDataAsCsv();
    }
  };

  return (
    <>
      <HeaderSection pageName="Users" triggerExportEvent={triggerExportEvent} />
      <div className="mx-6">
        <FilterUser />

        <UserTable setGridReference={setGridReference} />
      </div>
    </>
  );
};

export default Users;
