import { Link } from 'react-router-dom';
import Icon from '@/components/common/Icon';
import Table from '@/components/common/Table';
import Status from '@/components/common/Status';
import AvatarPlaceholder from '@/assets/images/avatar-placeholder.png';
import { TableColumn } from '@/type/table';
import { useAppSelector } from '@/store';
import { usersSelector } from '@/store/user';
import { commonDateFormat } from '@/utils/date';

type Props = {
  setGridReference?: any;
};

const userColumns: TableColumn[] = [
  {
    field: 'id',
    label: 'UserId',
    cellClassName: 'max-w-[80px]',
    tooltip: true,
    render: (row: any) => (
      <Link
        to={`/user/${row.value}`}
        className="text-sm text-left text-ctascale-lightMain font-semibold">
        {row.value}
      </Link>
    )
  },
  {
    field: 'customerName',
    label: 'Name'
  },
  {
    field: 'countryName',
    label: 'Country'
  },
  {
    field: 'email',
    label: 'Email'
  },
  {
    field: 'phoneNumber',
    label: 'Phone number'
  },
  {
    field: 'kyc',
    label: 'KYC',
    cellClassName: '!py-2 w-[70px]',
    render: (row: any) => (
      <div className="flex justify-center">
        <Status status={row.value} />
      </div>
    )
  },
  {
    field: 'registrationDate',
    label: 'Registration date',
    render: (row: any) => <>{commonDateFormat(row.value, true)}</>
  },
  {
    field: 'subscriptionPlan',
    label: 'Subscription plan'
  }
];

const UserTable: React.FC<Props> = ({ setGridReference }) => {
  const users = useAppSelector(usersSelector);

  return (
    <div className="rounded-xl">
      <Table
        setGridReference={setGridReference}
        pagination
        columns={userColumns}
        data={users || []}
      />
    </div>
  );
};

export default UserTable;
