import React, { useState, useEffect } from 'react';
import IconButton from '@/components/common/IconButton';
import Icon from '@/components/common/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@/components/common/Button';
import { exportToJson } from '@/utils/download';
import PersonalInformation from '@/components/widgets/sections/PersonalInformation';
import WalletTable from '@/components/widgets/tables/WalletTable';
import ReferredUsersTable from '@/components/widgets/tables/ReferredUsersTable';
import FilterUserTransaction from '@/components/widgets/filters/FilterUserTransaction';
import {
  getUserWallets,
  getUserInfo,
  getUserNotes
} from '@/service/user.service';
import Loader from '@/components/widgets/loaders/Loader';
import TransactionTable from '@/components/widgets/tables/TransactionTable';
import { UserInformation, UserWallet, UserNotes } from '@/type/user';
import CommentTable from '@/components/widgets/tables/CommentTable';
import HeaderSection from '@/components/widgets/sections/HeaderSection';

const UserDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [wallet, setWallet] = useState<UserWallet>({} as UserWallet);
  const [profileInfo, setProfileInfo] = useState<UserInformation>(
    {} as UserInformation
  );
  const [userNotes, setUserNotes] = useState<UserNotes>({} as UserNotes);
  const [initialUserName, setInitialUserName] = useState('');

  useEffect(() => {
    if (params.id) {
      const payload = { customeridp: params.id };

      Promise.all([
        getUserInfo(payload),
        getUserWallets(payload),
        getUserNotes(payload)
      ])
        .then(([info, wallet, notes]) => {
          setWallet(wallet);
          setProfileInfo(info);
          setInitialUserName(info.customerName);
          setUserNotes(notes);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.id]);

  if (loading) return <Loader />;

  return (
    <>
      <HeaderSection pageName="User profile" origin="userDetails" />

      <div className="mx-6">
        <div className="mb-8">
          <h2 className="text-title font-medium mb-4">Personal information</h2>
          <PersonalInformation
            setProfileInfo={setProfileInfo}
            data={profileInfo}
            initialUserName={initialUserName}
            userId={params?.id ? params.id : ''}
          />
        </div>

        <div className="grid grid-cols-2 gap-4 mb-8">
          <div className="col-span-1">
            <p className="text-xl font-semibold mb-4">User Notes</p>
            <div className="flex-1">
              <CommentTable data={userNotes} />
            </div>
          </div>
          <div className="col-span-1">
            <p className="text-xl font-semibold mb-4">User Balance</p>
            <div className="flex-1">
              <WalletTable data={wallet} />
            </div>
          </div>
        </div>

        <div className="mb-8">
          <p className="text-xl font-semibold mb-4">Transactions</p>
          <FilterUserTransaction />
          <TransactionTable fromUserPage={true} />
        </div>

        <div>
          <p className="text-xl font-semibold mb-4">Referred users</p>
          <ReferredUsersTable />
        </div>
      </div>
    </>
  );
};

export default UserDetail;
