import React from 'react';
import { DotLoader } from 'react-spinners';
import clsx from 'clsx';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';

type Props = {
  className?: string;
};

const Loader: React.FC<Props> = ({ className }) => {
  const currentTheme = useContext(CurrentThemeContext);
  return (
    <div
      className={clsx(
        'h-full w-full flex justify-center items-center p-5',
        className
      )}>
      <DotLoader
        size={60}
        color={currentTheme.theme === 'YELLOWAFRICA' ? '#D5A23D' : '#BB7FFF'}
      />
    </div>
  );
};

export default Loader;
