import React, { useState, useMemo, ChangeEvent } from 'react';
import Popper from '@/components/common/Popper';
import Icon from '@/components/common/Icon';
import Checkbox from '@/components/common/Checkbox';
import clsx from 'clsx';
import './style.css';

export type FilterItem = {
  label: string;
  value: string;
  avatar?: any;
};

type Props = {
  label: string;
  onChangeFilter: (value: string[]) => void;
  selectedValue: string[];
  searchable?: boolean;
  placeholder?: string;
  options: FilterItem[];
  multi?: boolean;
  pickFirstValueWhenEmpty?: boolean;
};

const DropdownFilter: React.FC<Props> = ({
  label,
  onChangeFilter,
  selectedValue,
  options,
  searchable,
  placeholder = '',
  multi,
  pickFirstValueWhenEmpty
}) => {
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChangeOption = (value: string) => (status: boolean) => {
    if (status) {
      onChangeFilter(multi ? [...selectedValue, value] : [value]);
    } else {
      onChangeFilter(
        multi
          ? selectedValue.filter((item) => item !== value)
          : pickFirstValueWhenEmpty
          ? [filteredOption[0].value]
          : []
      );
    }
  };

  const filteredOption = useMemo(() => {
    return options.filter(
      (option) => option.label.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }, [options, search]);

  const displayValues = useMemo(() => {
    const labels = selectedValue.map(
      (item) => options.find((option) => option.value === item)?.label ?? ''
    );

    if (!labels.length) {
      return label;
    } else {
      if (multi) {
        let placeholder = '';
        let more = '';
        labels.forEach((value, index) => {
          if (index < 2) {
            placeholder += `${value}, `;
            more = '';
          } else {
            more = ` + ${index - 1}`;
          }
        });

        return placeholder + more;
      }

      return labels[0];
    }
  }, [options, selectedValue, label, multi]);

  return (
    <Popper
      className="w-full sm:w-fit"
      placement="bottom-start"
      onChangeOpening={setExpanded}
      anchor={
        <div
          className={
            'flex items-center justify-between py-7 px-2 mt-2 rounded-md border border-opacity-0 h-[41px] w-[325px] text-sm bg-[#EFF0F6] hover:shadow-dropdown hover:ring-[0.5px] hover:ring-primary-dark'
          }>
          <span className="mr-3 anchor-label">{displayValues}</span>
          <Icon className={clsx(expanded && 'rotate-180')} name="caret-down" />
        </div>
      }>
      <div
        className={clsx(
          'rounded-xl px-3 shadow-dropdown mt-2',
          searchable ? 'pt-[22px] pb-3 w-[338px]' : 'w-[183px] py-4'
        )}>
        <div
          className={clsx(
            'rounded px-2',
            searchable && 'border border-primary px-5'
          )}>
          {searchable && (
            <input
              className="text-sm placeholder:text-secondary h-10 border-b border-b-grey w-full focus:outline-0"
              placeholder={placeholder}
              value={search}
              onChange={handleSearch}
            />
          )}
          <div className="py-2">
            <div className="max-h-[244px] overflow-y-auto">
              {filteredOption.map((item, index) => (
                <div
                  key={index}
                  className={clsx('flex items-center py-2', {
                    'pt-0': index === 0,
                    'pb-0': index === filteredOption.length - 1
                  })}>
                  <Checkbox
                    checked={selectedValue.includes(item.value)}
                    onChange={handleChangeOption(item.value)}
                    className="shrink-0"
                  />
                  {item.avatar && (
                    <span className="avatar-wrapper">
                      <img
                        className="w-full h-full"
                        src={item.avatar}
                        alt="avatar"
                      />
                    </span>
                  )}
                  <span
                    className={clsx(
                      'text-sm break-word pl-3',
                      selectedValue.includes(item.value) && 'font-semibold'
                    )}>
                    {item.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Popper>
  );
};

export default DropdownFilter;
