import React from 'react';
import Icon from '@/components/common/Icon';
import Table from '@/components/common/Table';
import { TableColumn } from '@/type/table';
import { users } from '../UserTable/dummy';

const userColumns: TableColumn[] = [
  {
    field: 'name',
    label: 'Name',
    render: (row: any) => (
      <div className="flex items-center justify-center">
        <span className="text-sm">{row.value}</span>
      </div>
    )
  },
  {
    field: 'email',
    label: 'Email'
  },
  {
    field: 'phone',
    label: 'Phone number'
  },
  {
    field: 'role',
    label: 'Role'
  }
];

type Props = {
  data?: any;
};

const TeamTable: React.FC<Props> = () => {
  return (
    <div className="shadow-paper bg-white rounded-xl pl-2.5 pr-5 py-6">
      <div className="flex items-start mb-6">
        <div>
          <h3 className="text-subtitle font-medium">Other team members</h3>
        </div>
      </div>

      <Table
        pagination={false}
        columns={userColumns}
        data={users.map((item) => ({
          ...item,
          role: 'Manager'
        }))}
      />
    </div>
  );
};

export default TeamTable;
