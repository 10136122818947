import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { chartColor } from '@/constants/colors';

export type LineChartRow = {
  x: Array<string | number>;
  y: Array<number>;
};

type Props = {
  data: LineChartRow[];
};

const LineChart: React.FC<Props> = ({ data }) => {
  const options = useMemo(
    () => ({
      chart: {
        height: 200,
        type: 'areaspline'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          fillColor: '',
          marker: {
            enabled: false
          },
          pointPlacement: 'on'
        }
      },
      xAxis: {
        categories: data[0].x,
        labels: {
          style: {
            fontSize: 12,
            color: '#6E7191'
          },
          autoRotation: true,
          align: 'left',
          step: Math.ceil(data[0].x.length / 12)
        }
      },
      yAxis: {
        title: {
          enabled: false
        },
        labels: {
          style: {
            fontSize: 12,
            color: '#6E7191'
          }
        },
        gridLineWidth: 0,
        tickPixelInterval: 32,
        tickColor: '#131415',
        tickAmount: 6
      },
      tooltip: {
        useHTML: true,
        headerFormat: '',
        formatter: function () {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { category: x, y } = this.point;
          const content: any = `<div class="chartTooltip"> 
          <div class="text-center text-xs font-bold">
          ${y} 
          </div>
          <div class="text-center text-xs">
          Date: ${x} 
          </div>
          </div>`;
          return content;
        },
        style: {
          color: '#000'
        },
        padding: 12,
        valueDecimals: 0,
        backgroundColor: '#fff',
        borderColor: '#fff',
        borderRadius: 16,
        shadow: true
      },
      series: data.map((item, index) => ({
        data: item.y,
        color: chartColor[index],
        lineWidth: 2.5,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 1,
            x2: 0,
            y2: 0
          },
          stops: [
            [0, 'transparent'],
            [1, `${chartColor[index]}30`]
          ]
        }
      })),
      legend: {
        enabled: false
      }
    }),
    [data]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LineChart;
