import React, { useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import Button from '@/components/common/Button';
import NormalSection from '@/components/widgets/sections/NormalSection';
import DraggableGridSection from '@/components/widgets/sections/DraggableGridSection';
import TotalBalanceCard from '@/components/widgets/cards/TotalBalanceCard';
import DepositWithdrawCard from '@/components/widgets/cards/DepositWithdrawCard';
import ActiveUsersCard from '@/components/widgets/cards/ActiveUsersCard';
import NonCustodialWalletsCard from '@/components/widgets/cards/NonCustodialWalletsCard';
import CoinsByHoldersCard from '@/components/widgets/cards/CoinsByHoldersCard';
import MapCard from '@/components/widgets/cards/MapCard';
import RampChartCard from '@/components/widgets/cards/RampChartCard';
import { getIntervalDates } from '@/utils/date';
import PieChart from '@/components/widgets/charts/PieChart';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getUserInformation,
  getFinanceInformation,
  getUserGeography,
  getSubscriptionPlans,
  getNonCustodialWallets,
  getCoinsByHolders,
  userInformation,
  financeInformation,
  userGeography,
  plansAndStatistics,
  nonCustodialWallets,
  coinsByHolders
} from '@/store/dashboard';
import FinanceInfoCard from '@/components/widgets/cards/FinanceInfoCard';
import Loader from '@/components/widgets/loaders/Loader';
import { toNormalizedNumber } from '@/utils/string';
import { getFilterOptions, saveFilterOptions } from '@/utils/filter';
import Card from '@/components/common/Card';
import HeaderSection from '@/components/widgets/sections/HeaderSection';

const Analytics = () => {
  const dispatch = useAppDispatch();

  const savedOption = getFilterOptions('home');

  const [loading, setLoading] = useState<boolean>(true);
  const [date, setDate] = useState(() => {
    if (savedOption?.from && savedOption?.to) {
      return {
        startDate: savedOption.from,
        endDate: savedOption.to
      };
    } else {
      return {
        startDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      };
    }
  });
  const [duration, setDuration] = useState<string[]>([]);
  const [financeInformationChartData, setFinanceInformationChartData] =
    useState({ deposit: 0, withdraw: 0 });
  const [nonCustodialWalletsTotal, setNonCustodialWalletsTotal] = useState(0);
  const userInformationData = useAppSelector(userInformation);
  const financeInformationData = useAppSelector(financeInformation);
  const userGeographyData = useAppSelector(userGeography);
  const nonCustodialWalletsData = useAppSelector(nonCustodialWallets);
  const coinsByHoldersData = useAppSelector(coinsByHolders);

  useEffect(() => {
    const dateFilter = { from: date.startDate, to: date.endDate };

    const fetchData = async () => {
      await dispatch(getUserInformation(dateFilter));
      await dispatch(getFinanceInformation(dateFilter));
      await dispatch(getUserGeography(dateFilter));
      await dispatch(getSubscriptionPlans(dateFilter));
      await dispatch(getNonCustodialWallets(dateFilter));
      await dispatch(getCoinsByHolders(dateFilter));
      setLoading(false);
    };

    const filter = {
      from: date.startDate,
      to: date.endDate
    };

    saveFilterOptions(filter, 'home');

    setDuration(getIntervalDates(date.startDate, date.endDate));
    fetchData();
  }, [date]);

  if (loading) return <Loader />;

  return (
    <>
      <HeaderSection
        date={date}
        setDate={setDate}
        pageName="Dashboard"
        origin="analytics"
      />
      <div className="mx-6">
        {userInformationData && (
          <DraggableGridSection
            data={userInformationData}
            title="General information"
            cols={5}
          />
        )}

        {financeInformationData && (
          <NormalSection title="Finance Information">
            <div className="grid grid-cols-3 gap-3 mb-[15px]">
              <div className="col-span-2">
                <DepositWithdrawCard
                  data={financeInformationData.depositVsWithdraws}
                  initialX={duration}
                  setFinanceInformationChartData={
                    setFinanceInformationChartData
                  }
                />
              </div>
              <div className="col-span-1">
                <FinanceInfoCard data={financeInformationChartData} />
              </div>
            </div>

            <div className="grid grid-cols-4 gap-3 mb-[50px]">
              <div className="col-span-1 flex flex-col">
                <Card title="Internal Amount Transacted">
                  <div className="flex items-center mb-6 -mt-2">
                    <h3 className="font-bold text-title">
                      {financeInformationData
                        ?.externalVsInternalTransactions?.[1]
                        ?.transacionNetworkType === 'internal' // if the second element is the internal
                        ? `${toNormalizedNumber(
                            financeInformationData
                              ?.externalVsInternalTransactions[1]
                              ?.totalAmountUSD,
                            'currency'
                          )}`
                        : financeInformationData
                            ?.externalVsInternalTransactions?.[0]
                            ?.transacionNetworkType === 'internal' // if the first element is the internal
                        ? `${toNormalizedNumber(
                            financeInformationData
                              ?.externalVsInternalTransactions[0]
                              ?.totalAmountUSD,
                            'currency'
                          )}`
                        : 'N/A'}
                    </h3>
                  </div>
                </Card>
                <Card title="External Amount Transacted">
                  <div className="flex items-center mb-6 -mt-2">
                    <h3 className="font-bold text-title">
                      {financeInformationData?.externalVsInternalTransactions[0]
                        ?.transacionNetworkType === 'external' // if the first element is the external
                        ? `${toNormalizedNumber(
                            financeInformationData
                              ?.externalVsInternalTransactions[0]
                              ?.totalAmountUSD,
                            'currency'
                          )}`
                        : financeInformationData
                            ?.externalVsInternalTransactions[1]
                            ?.transacionNetworkType === 'external' // if the second element is the internal
                        ? `${toNormalizedNumber(
                            financeInformationData
                              ?.externalVsInternalTransactions[1]
                              ?.totalAmountUSD,
                            'currency'
                          )}`
                        : 'N/A'}
                    </h3>
                  </div>
                </Card>
                <div></div>
              </div>
              <div className="col-span-1">
                <RampChartCard x={[]} y={[]} />
              </div>
              <div className="col-span-2">
                <ActiveUsersCard
                  data={financeInformationData.dailyActiveUsers}
                  initialX={duration}
                />
              </div>
            </div>
          </NormalSection>
        )}

        {userGeographyData && (
          <div>
            <MapCard data={userGeographyData.userGeographies} />
          </div>
        )}

        {nonCustodialWalletsData && coinsByHoldersData && (
          <div className="grid grid-cols-3 gap-y-5 gap-x-8 mb-[50px]">
            <div className="col-span-1">
              <NonCustodialWalletsCard
                data={nonCustodialWalletsData.nonCustodialWallets}
                nonCustodialWalletsTotal={nonCustodialWalletsTotal}
                setNonCustodialWalletsTotal={setNonCustodialWalletsTotal}
              />
            </div>
            <div className="col-span-2">
              <CoinsByHoldersCard data={coinsByHoldersData.coinsByHolders} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Analytics;
