import React, { useContext } from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import Button from '@/components/common/Button';
import { CurrentThemeContext } from '@/provider/ThemeProvider';

type Props = ModalProps & {
  message: string;
  onCancel: any;
  onConfirm: any;
  fromCancel: boolean;
};

const CancelOrConfirmModal: React.FC<Props> = ({
  message,
  onCancel,
  fromCancel,
  onConfirm,
  open,
  onClose
}) => {
  const currentTheme = useContext(CurrentThemeContext);
  return (
    <Modal open={open} onClose={onClose} smallScreen={true}>
      <h2 className="text-title font-bold mb-5 mx-8 text-left">{message}</h2>
      <div className="flex justify-center gap-5">
        <Button
          variant="secondary"
          onClick={() => onClose()}
          className="h-50 min-w-[20px] font-semibold text-md p-7 bg-[#EFF0F6] !border-none">
          Keep Editing
        </Button>
        <button
          onClick={() => {
            fromCancel ? onCancel() : onConfirm();
            onClose();
          }}
          className={`font-semibold text-md p-7 disabled:text-white disabled:opacity-50 disabled:cursor-not-allowed min-w-[160px] rounded-3xl h-[50px] px-5 flex items-center justify-center hover:shadow-button text-white ${
            currentTheme.theme === 'YELLOWAFRICA'
              ? 'bg-beautiful-yellow-africa'
              : 'bg-beautiful'
          } disabled:bg-grey active:shadow-activeButton active:bg-primary`}>
          {fromCancel ? 'Yes, discard.' : 'Yes, save.'}
        </button>
      </div>
    </Modal>
  );
};

export default CancelOrConfirmModal;
