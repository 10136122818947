import React, { useState, useMemo, useEffect } from 'react';
import DropdownFilter from '@/components/widgets/filters/DropdownFilter';
import InputFilter from '@/components/widgets/filters/InputFilter';
import FilterContainer from '@/components/widgets/filters/FilterContainer';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getEmployees,
  employeeFilterDataSelector,
  getEmployeeFilterData
} from '@/store/employee';
import moment from 'moment';
import { optimizeObject } from '@/utils/object';
import { getFilterOptions, saveFilterOptions } from '@/utils/filter';
import DateTimeFilter from '@/components/widgets/filters/DateTimeFilter';
import Button from '@/components/common/Button';

const FilterEmployee = () => {
  const dispatch = useAppDispatch();

  const savedOption = getFilterOptions('employee');

  const filterData = useAppSelector(employeeFilterDataSelector);
  const [employeeId, setEmployeeId] = useState<string>(
    () => savedOption?.employeeid ?? ''
  );
  const [name, setName] = useState<string>(
    () => savedOption?.employeenamep ?? ''
  );
  const [surname, setSurname] = useState<string>(
    () => savedOption?.employeesurnamep ?? ''
  );
  const [status, setStatus] = useState<string[]>([]);
  const [country, setCountry] = useState<string[]>([]);
  const [employeeType, setEmployeeType] = useState<string[]>([]);
  const [department, setDepartment] = useState<string[]>([]);
  const [phone, setPhone] = useState<string>(
    () => savedOption?.employeephonep ?? ''
  );
  const [role, setRole] = useState<string>(
    () => savedOption?.employeerole ?? ''
  );

  const [date, setDate] = useState(() => {
    if (savedOption?.from && savedOption?.to) {
      return {
        startDate: savedOption.from,
        endDate: savedOption.to
      };
    } else {
      return {
        startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    }
  });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        setLoading(true);
        await dispatch(getEmployeeFilterData());
      } finally {
        setLoading(false);
      }
    };

    fetchFilterData();
  }, []);

  const onClear = () => {
    setEmployeeId('');
    setName('');
    setSurname('');
    setStatus(['All Statuses']);
    setCountry(['All Countries']);
    setEmployeeType(['All Employee Types']);
    setDepartment(['All Departments']);
    setPhone('');
    setRole('');
  };

  const onApply = () => {
    const filter = {
      employeeid: employeeId.trim(),
      firstname: name.trim(),
      lastname: surname.trim(),
      department: department[0] === 'All Departments' ? '' : department[0],
      countrycode: country[0] === 'All Countries' ? '' : country[0],
      status: status[0] === 'All Statuses' ? '' : status[0],
      phonenumber: phone.trim(),
      employeetype:
        employeeType[0] === 'All Employee Types' ? '' : employeeType[0],
      employeerole: role.trim()
    };

    saveFilterOptions(filter, 'employee');

    const { employeeid, ...rest } = filter;
    dispatch(getEmployees(optimizeObject(employeeid ? { employeeid } : rest)));
  };

  useEffect(() => {
    if (filterData) {
      onApply();
    }
  }, [filterData]);

  const countryOptions = useMemo(() => {
    // Since we are not expecting an initial All ____ from the backend
    if (filterData?.countries) {
      const options = filterData.countries.map((item) => ({
        label: item.countryName,
        value: item.countryName
      }));

      options.unshift({ label: 'All Countries', value: 'All Countries' });
      setCountry([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const employeeTypes = useMemo(() => {
    // Since we are not expecting an initial All ____ from the backend
    if (filterData?.employeeTypes) {
      const fixedFilterData = [...filterData.employeeTypes];
      const options = fixedFilterData.map((item) => ({
        label: item,
        value: item
      }));

      const savedOption = getFilterOptions('employee');
      if (savedOption?.employeeTypes) {
        setEmployeeType([savedOption.employeeTypes]);
      } else {
        setEmployeeType([options[0].value]);
      }
      return options;
    }
    return [];
  }, [filterData]);

  const departments = useMemo(() => {
    if (filterData?.departments) {
      // Since we are not expecting an initial All ____ from the backend
      const fixedFilterData = [...filterData.departments];
      const options = fixedFilterData.map((item) => ({
        label: item,
        value: item
      }));

      const savedOption = getFilterOptions('employee');
      if (savedOption?.departments) {
        setDepartment([savedOption.departments]);
      } else {
        setDepartment([options[0].value]);
      }
      return options;
    }
    return [];
  }, [filterData]);

  const statuses = useMemo(() => {
    const options = [
      { label: 'All Statuses', value: 'All Statuses' },
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '2' }
    ];

    const savedOption = getFilterOptions('employee');
    if (savedOption?.status) {
      setStatus([savedOption.status]);
    } else {
      setStatus([options[0].value]);
    }
    return options;
  }, [filterData]);

  return (
    <div className="shadow-paper bg-white rounded-xl pl-2.5 pr-4 py-6 mb-4">
      <h3 className="text-subtitle font-medium mb-4">Employee Filters</h3>
      <FilterContainer>
        <InputFilter
          label="Employee ID"
          value={employeeId}
          onChange={setEmployeeId}
        />
        <DropdownFilter
          options={statuses}
          label="All Statuses"
          placeholder="Search for statuses"
          selectedValue={status}
          onChangeFilter={setStatus}
          searchable
          pickFirstValueWhenEmpty
        />
        <DropdownFilter
          options={countryOptions}
          label="All Countries"
          placeholder="Search for country"
          selectedValue={country}
          onChangeFilter={setCountry}
          searchable
          pickFirstValueWhenEmpty
        />
        <DropdownFilter
          options={employeeTypes}
          label="All Employee Types"
          placeholder="Search for employee type"
          selectedValue={employeeType}
          onChangeFilter={setEmployeeType}
          searchable
          pickFirstValueWhenEmpty
        />
        <DropdownFilter
          options={departments}
          label="AllDepartments"
          placeholder="Search for departments"
          selectedValue={department}
          onChangeFilter={setDepartment}
          searchable
          pickFirstValueWhenEmpty
        />
        <InputFilter label="Name" value={name} onChange={setName} />
        <InputFilter label="Surname" value={surname} onChange={setSurname} />
        <DateTimeFilter
          label="Registration date"
          setDate={setDate}
          date={date}
        />
        <InputFilter label="Phone" value={phone} onChange={setPhone} />
        <InputFilter label="Role" value={role} onChange={setRole} />
      </FilterContainer>
      <div className="flex justify-end mt-6 gap-2">
        <Button variant="secondary" onClick={onClear}>
          Clear filters
        </Button>
        <Button onClick={onApply}>Apply filters</Button>
      </div>
    </div>
  );
};

export default FilterEmployee;
