import React, { useState, useMemo, useEffect } from 'react';
import DropdownFilter from '@/components/widgets/filters/DropdownFilter';
import FilterContainer from '@/components/widgets/filters/FilterContainer';
import DateTimeFilter from '@/components/widgets/filters/DateTimeFilter';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getUsers,
  userFilterDataSelector,
  getUserFilterData
} from '@/store/user';
import { optimizeObject } from '@/utils/object';
import Loader from '@/components/widgets/loaders/Loader';
import moment from 'moment/moment';
import { getFilterOptions, saveFilterOptions } from '@/utils/filter';
import Button from '@/components/common/Button';
import SearcgBarFilter from '@/components/widgets/filters/SearchBarFilter';

const FilterUser = () => {
  const dispatch = useAppDispatch();

  const savedOption = getFilterOptions('user');

  const filterData = useAppSelector(userFilterDataSelector);
  const [kyc, setKyc] = useState<string[]>([]);
  const [date, setDate] = useState(() => {
    if (savedOption?.from && savedOption?.to) {
      return {
        startDate: savedOption.from,
        endDate: savedOption.to
      };
    } else {
      return {
        startDate: moment().add(-1, 'd').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    }
  });
  const [searchBar, setSearchBar] = useState<string>(
    () => savedOption?.searchBar ?? ''
  );

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        setLoading(true);
        await dispatch(getUserFilterData());
      } finally {
        setLoading(false);
      }
    };

    fetchFilterData();
  }, []);

  const onApply = () => {
    const filter = {
      searchBar: searchBar.trim(),
      from: date.startDate,
      to: date.endDate,
      kycStatus: kyc[0]
    };

    saveFilterOptions(filter, 'user');

    dispatch(getUsers(optimizeObject(filter)));
  };

  const onClear = () => {
    setSearchBar('');
    setKyc(['All KYC Statuses']);
  };

  useEffect(() => {
    if (filterData) {
      onApply();
    }
  }, [filterData]);

  const kycOptions = useMemo(() => {
    if (filterData?.kycddlData) {
      const options = filterData.kycddlData.map((item) => ({
        label: item.kyc,
        value: item.kyc
      }));

      const savedOption = getFilterOptions('user');
      if (savedOption?.kycStatus) {
        setKyc([savedOption.kycStatus]);
      } else {
        setKyc([options[0].value]);
      }

      return options;
    }
    return [];
  }, [filterData]);

  return (
    <div className="shadow-paper bg-white rounded-xl pl-1 pr-4 pt-2 pb-6 mb-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          <FilterContainer>
            <SearcgBarFilter
              searchBar={searchBar}
              setSearchBar={setSearchBar}
            />
            <DateTimeFilter
              label="Registration date"
              date={date}
              setDate={setDate}
            />
            <DropdownFilter
              options={kycOptions}
              label="KYC"
              placeholder="Search for kyc"
              selectedValue={kyc}
              onChangeFilter={setKyc}
              searchable
              pickFirstValueWhenEmpty
            />
          </FilterContainer>
        </>
      )}
      <div className="flex justify-end mt-6 gap-2">
        <Button variant="secondary" onClick={onClear}>
          Clear filters
        </Button>
        <Button disabled={loading} onClick={onApply}>
          Apply filters
        </Button>
      </div>
    </div>
  );
};

export default FilterUser;
