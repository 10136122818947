import React, { useMemo } from 'react';
import Card from '@/components/common/Card';
import { CoinByHolders } from '@/type/dashboard';
import ColumnChart from '@/components/widgets/charts/ColumnChart';
type Props = {
  data: CoinByHolders[];
};

const SubscriptionTransactionCard: React.FC<Props> = ({ data }) => {
  const processed = useMemo(() => {
    const arr = data.map((item) => {
      return [item.cryptoCurrencyType, item.holders];
    });
    return arr;
  }, [data]);

  return (
    <Card title="Coin by holders">
      {processed.length > 0 ? (
        <ColumnChart data={processed} />
      ) : (
        <h1 className="font-bold text-title">N/A</h1>
      )}
    </Card>
  );
};

export default SubscriptionTransactionCard;
