import React, { useMemo, useState } from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import ToggleInput from '@/components/common/ToggleInput';
import Button from '@/components/common/Button';
import * as Yup from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import * as authApi from '@/service/auth.service';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required')
    .trim('Required')
});

const EMAIL_REGEX =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

type Props = ModalProps & {
  onSuccess?: () => void;
};

const ForgotPasswordModal: React.FC<Props> = ({ open, onClose, onSuccess }) => {
  const currentTheme = useContext(CurrentThemeContext);
  const [error, setError] = useState('');

  const profileInfo = useMemo(() => {
    const info = window.localStorage.getItem('dd_account');
    if (info) return JSON.parse(info);
    return {};
  }, []);

  const initialValue = {
    email: profileInfo?.email
  };

  const handleSubmit = async (
    values: { email: string },
    { setSubmitting }: FormikHelpers<{ email: string }>
  ) => {
    setSubmitting(true);
    await authApi
      .sendResetPasswordEmail(values)
      .then(() => {
        onSuccess?.();
        onClose();
      })
      .catch((error) => {
        // TODO: please add error message
        console.log('[Error] sending reset password email', error);
        setError('Email does not exist.');
      });
    setSubmitting(false);
  };

  return (
    <Modal open={open} onClose={onClose} smallScreen={true}>
      <div className="max-w-[583px]">
        <h2 className="text-title mb-5 font-semibold">Forgotten password</h2>
        <p className="text-sm leading-6 max-w-[414px] mb-5 text-[#A0A3BD]">
          Enter the email you use to login in the system, so we can send you a
          link for password reset.
        </p>

        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}>
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting,
            setFieldValue
          }) => {
            console.log(values);
            return (
              <Form autoComplete="off">
                <div className="mb-5">
                  <ToggleInput
                    editable={true}
                    placeholder="Email"
                    value={values.email}
                    onChangeValue={(newVal) => {
                      setFieldValue('email', newVal);
                      setError('');
                    }}
                  />
                  {values.email !== undefined &&
                    !EMAIL_REGEX.test(values.email) && (
                      <p className="block pt-0 text-xs pb-2 text-red-500">
                        Please fix this email format
                      </p>
                    )}
                  {error !== '' && (
                    <p className="block pt-0 text-xs pb-2 text-red-500">
                      {error}
                    </p>
                  )}
                </div>

                <div className="flex justify-end self-end gap-5">
                  <button
                    className="font-semibold text-md py-2 disabled:opacity-50 disabled:cursor-not-allowed w-[7rem] rounded-full h-[3.5rem] flex items-center justify-center hover:shadow-button bg-greyscale-greyishWhite"
                    onClick={onClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={
                      !isValid || isSubmitting || error !== '' || errors?.email
                        ? true
                        : false
                    }
                    className={`font-semibold text-md py-2 disabled:text-white disabled:opacity-50 disabled:cursor-not-allowed w-[7rem] rounded-full h-[3.5rem] flex items-center justify-center hover:shadow-button text-white ${
                      currentTheme.theme === 'YELLOWAFRICA'
                        ? 'bg-beautiful-yellow-africa'
                        : 'bg-beautiful'
                    } disabled:bg-grey active:shadow-activeButton active:bg-primary`}>
                    Send
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
