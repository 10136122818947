import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import {
  Formik,
  Form,
  FormikHelpers,
  FormikErrors,
  FormikTouched
} from 'formik';
import * as Yup from 'yup';
import Input from '@/components/common/Input';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import ReportModal from '@/components/widgets/modal/ReportModal';
import ForgotPasswordModal from '@/components/widgets/modal/ForgotPasswordModal';
import ResetSuccessModal from '@/components/widgets/modal/ResetSuccessModal';
import PasswordInput from '@/components/common/PasswordInput';
import { Role, LoginValue } from '@/type/user';
import { useAppDispatch } from '@/store';
import { login } from '@/store/auth';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Enter email first')
    .trim('required'),
  password: Yup.string().required('Enter a password').trim('Enter a password')
});

const initialValues: LoginValue = {
  email: '',
  password: ''
};

const Login = () => {
  const currentTheme = useContext(CurrentThemeContext);
  const dispatch = useAppDispatch();

  const [isShowReportModal, setIsShowReportModal] = useState<boolean>(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);
  const [isShowForgotPasswordModal, setIsShowForgotPasswordModal] =
    useState<boolean>(false);
  const [error, setError] = useState('');

  const displayError = (
    touched: FormikTouched<LoginValue>,
    error: FormikErrors<LoginValue>
  ) => {
    let message = '';

    if (touched.email && error.email) message = error.email;
    if (touched.password && error.password) message = error.password;
    if (touched.email && error.email && touched.password && error.password)
      message = 'Please enter valid email and password';

    return message;
  };

  const handleSubmit = async (
    values: LoginValue,
    { setSubmitting }: FormikHelpers<LoginValue>
  ) => {
    setError('');
    setSubmitting(true);
    const loginDispatch = await dispatch(login(values));
    if (loginDispatch.meta.requestStatus === 'rejected') {
      setError(loginDispatch.payload);
    }
    setSubmitting(false);
  };

  return (
    <div className="flex-1 relative ml-10">
      <h1 className="text-heading mb-3 !text-[#A0A3BD] font-bold">
        Application backoffice
      </h1>
      <div className="w-full max-w-[400px] mb-3">
        <p className="text-md mb-8 font-bold text-[#344054]">
          Log in with the email and password which you have confirmed for this
          platform from SendCrypto
        </p>
        <div className="shadow-paper rounded-2xl bg-white p-5">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={handleSubmit}>
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              isValid,
              isSubmitting
            }) => {
              return (
                <Form>
                  <Input
                    className="mb-3"
                    placeholder="Email Address"
                    name="email"
                    error={touched.email && !!errors.email}
                    success={!!values.email && isValid}
                    value={values.email}
                    onChange={(event) => {
                      handleChange(event);
                      setError('');
                    }}
                    onBlur={handleBlur}
                  />
                  <PasswordInput
                    className="mb-3"
                    placeholder="Password"
                    name="password"
                    error={touched.password && !!errors.password}
                    success={!!values.password && isValid}
                    value={values.password}
                    onChange={(event: Event) => {
                      handleChange(event);
                      setError('');
                    }}
                    onBlur={handleBlur}
                  />
                  <div className="flex">
                    {error && (
                      <div className="font-semibold text-errorscale-dark text-xs mb-3 mr-2">
                        {error || displayError(touched, errors)}
                      </div>
                    )}
                    <div
                      className="text-primary text-xs font-medium cursor-pointer tracking-wider hover:text-primary-dark mb-3"
                      onClick={() => setIsShowForgotPasswordModal(true)}>
                      Forgot your password?
                    </div>
                  </div>

                  <Button
                    className={`w-full
                    ${
                      isValid && !isSubmitting
                        ? currentTheme.theme === 'YELLOWAFRICA'
                          ? 'bg-beautiful-yellow-africa'
                          : 'bg-beautiful'
                        : 'bg-[#9B9B9B]'
                    }`}
                    type="submit"
                    disabled={isSubmitting || !isValid}>
                    Log in
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      <div className="flex items-center text-primary hover:text-primary-dark">
        <Icon name="alert-circle" />
        <span
          className="ml-3 font-medium text-sm hover:cursor-pointer tracking-wider"
          onClick={() => setIsShowReportModal(true)}>
          Report a problem
        </span>
      </div>

      <ReportModal
        open={isShowReportModal}
        onClose={() => setIsShowReportModal(false)}
      />

      <ForgotPasswordModal
        open={isShowForgotPasswordModal}
        onClose={() => setIsShowForgotPasswordModal(false)}
        onSuccess={() => setIsShowSuccessModal(true)}
      />

      <ResetSuccessModal
        open={isShowSuccessModal}
        onClose={() => setIsShowSuccessModal(false)}
      />
    </div>
  );
};

export default Login;
