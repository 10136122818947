import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';

const selectSelf = (state: RootState) => state.user;

export const usersSelector = createSelector(selectSelf, (state) => state.users);

export const transactionsSelector = createSelector(
  selectSelf,
  (state) => state.transactions
);

export const userFilterDataSelector = createSelector(
  selectSelf,
  (state) => state.filter
);

export const transactionFilterDataSelector = createSelector(
  selectSelf,
  (state) => state.transactionFilter
);