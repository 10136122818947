import React, { useMemo } from 'react';
import Icon from '@/components/common/Icon';
import { UserInformation, WidgetCardType, WidgetType } from '@/type/dashboard';
import WidgetCard from '@/components/widgets/cards/WidgetCard';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';

type Props = {
  data: UserInformation | null;
  title: string;
  subtitle?: string;
  cols: number;
  onAdd?: () => void;
  hidden?: boolean;
};

const DraggableGridSection: React.FC<Props> = ({
  data,
  title,
  subtitle,
  cols,
  onAdd,
  hidden
}) => {
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
  };

  const items = useMemo(() => {
    if (data) {
      const category: WidgetCardType[] = [
        {
          id: '1',
          title: 'Total Balance',
          value: data.totalBalanceUSD,
          changes: Number((Math.random() * 10).toFixed(1)),
          unit: 'currency'
        },
        {
          id: '2',
          title: 'Registered Users',
          value: data.registeredUsers,
          changes: Number((Math.random() * 10).toFixed(1))
        },
        {
          id: '3',
          title: 'Total amount transacted',
          value: data.totalAmountTradedUSD,
          changes: Number((Math.random() * 10).toFixed(1)),
          unit: 'currency'
        },
        {
          id: '4',
          title: 'New Users',
          value: data.newRegisteredUsers,
          changes: Number((Math.random() * 10).toFixed(1))
        },
        {
          id: '5',
          title: 'Active Users',
          value: data.activeUsers,
          changes: Number((Math.random() * 10).toFixed(1))
        }
      ];

      return category;
    }

    return [];
  }, [data]);

  return (
    <div className="mb-6">
      <div className="flex justify-between items-start mb-1 px-2">
        <div className="flex-1">
          <h2 className="text-title font-medium mb-2.5">{title}</h2>
          {subtitle && <p className="text-grey text-sm">{subtitle}</p>}
        </div>
        {onAdd && (
          <div
            className="font-semibold text-primary flex items-center text-sm hover:cursor-pointer"
            onClick={onAdd}>
            <Icon className="mr-2.5 text-primary" name="add-circle" />
            Add
          </div>
        )}
      </div>

      <div className="mb-[50px]">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable section">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={clsx(
                  'grid gap-3 grid-cols-1 md:grid-cols-2 md:gap-3',
                  cols === 3 && 'lg:grid-cols-3',
                  cols === 5 && '2xl:grid-cols-5 xl:grid-cols-2'
                )}>
                {items.map((item, index) => (
                  <WidgetCard key={index} data={item} hidden={hidden} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default DraggableGridSection;
