import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EmployeeInformation from '@/components/widgets/sections/EmployeeInformation';
import { getEmployeeInfo } from '@/service/employee.service';
import Loader from '@/components/widgets/loaders/Loader';
import { EmployeeDetails } from '@/type/employee';
import CancelOrConfirmModal from '@/components/widgets/modal/CancelOrConfirmModal';
import { permissionArraysObject } from '@/components/widgets/sections/PermissionsSection/permissionArray';
import { Store } from 'react-notifications-component';
import HeaderSection from '@/components/widgets/sections/HeaderSection';
import { putEmployees } from '@/service/employee.service';
import './style.css';

const fillPermissionObjectWithCurrentEmployeePermissions = (data: any) => {
  const tempPermObject = structuredClone(permissionArraysObject); // an object of arrays of the special permissions user has
  data.permissionIds.map((item: number, index: number) => {
    const userPermFoundIndex = tempPermObject.userPermissions.findIndex(
      (x: any) => x.permissionCode === item
    );
    const employeePermFoundIndex = tempPermObject.employeePermissions.findIndex(
      (x: any) => x.permissionCode === item
    );
    const downloadPermFoundIndex = tempPermObject.downloadPermissions.findIndex(
      (x: any) => x.permissionCode === item
    );
    userPermFoundIndex !== -1
      ? (tempPermObject.userPermissions[userPermFoundIndex].isTrue = true)
      : employeePermFoundIndex !== -1
      ? (tempPermObject.employeePermissions[employeePermFoundIndex].isTrue =
          true)
      : downloadPermFoundIndex !== -1
      ? (tempPermObject.downloadPermissions[downloadPermFoundIndex].isTrue =
          true)
      : '';
  });
  return tempPermObject;
};

const EmployeeDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [profileInfo, setProfileInfo] = useState<EmployeeDetails>(
    {} as EmployeeDetails
  ); // Holds the original profile information returned from the database. Doesn't change

  const [formData, setFormData] = useState<EmployeeDetails>(); // Holds the edited state of the data.

  const [initialEmployeeName, setInitialEmployeeName] = useState('');
  const [isEditScreen, setIsEditScreen] = useState(false);
  const [isConfirmOrCancelModalOpen, setIsConfirmOrCancelModalOpen] =
    useState(false);
  const [cancelOrConfirmMessage, setCancelOrConfirmMessage] = useState('');
  const [fromCancel, setFromCancel] = useState(false);
  const [permissionArrayObject, setPermissionArrayObject] = useState<any>();

  const isStateDirty = () => {
    const temp =
      fillPermissionObjectWithCurrentEmployeePermissions(profileInfo);
    return (
      JSON.stringify(profileInfo) !== JSON.stringify(formData) ||
      JSON.stringify(temp) !== JSON.stringify(permissionArrayObject)
    );
  };

  useEffect(() => {
    if (profileInfo.permissionIds) {
      const temp =
        fillPermissionObjectWithCurrentEmployeePermissions(profileInfo);
      setPermissionArrayObject(temp);
      setFormData({ ...profileInfo });
    }
  }, [profileInfo]);

  const handleChangeValue = (field: string) => (value: string) => {
    console.log(value);
  };

  useEffect(() => {
    if (params.id) {
      Promise.all([getEmployeeInfo(params.id)])
        .then(([{ employee }]) => {
          setProfileInfo(employee);
          setInitialEmployeeName(employee.firstName + ' ' + employee.lastName);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.id]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    //PUT EMPLOYEE NEW DATA
    const permissionCodes: number[] = [];
    Object.keys(permissionArrayObject).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      permissionArrayObject[key].forEach((item: permissionObjectType) => {
        if (item.isTrue) {
          permissionCodes.push(item.permissionCode);
        }
      });
    });

    const ddmmyyyyArr = formData?.birthDate
      ? formData.birthDate.split('/')
      : [];

    const birthDate =
      ddmmyyyyArr.length > 2
        ? `${ddmmyyyyArr[2]}-${ddmmyyyyArr[1]}-${ddmmyyyyArr[0]}`
        : null;

    const putEmployeeObject = {
      firstName: formData?.firstName || '',
      lastName: formData?.lastName || '',
      phoneNumber: formData?.phoneNumber || '',
      email: formData?.email || '',
      birthDate: birthDate,
      countryCode: formData?.countryCode || '',
      city: formData?.city || '',
      address: formData?.address || '',
      roleIds: formData?.roleIds.join(',') || '',
      permissionIds:
        permissionCodes.length > 0 ? permissionCodes.join(',') : '',
      department: formData?.department || '',
      employeeRole: formData?.employeeRole || '',
      status: formData?.status || '',
      managerName: formData?.managerName || '',
      employeeType: formData?.employeeType || '',
      isRemote: formData?.isRemote || '',
      employeeId: formData?.employeeId.toString() || undefined
    };

    const resp = await putEmployees(putEmployeeObject);

    if (resp.status === 200) {
      Store.addNotification({
        title: 'Changes has been successfully saved! ✅',
        message: '',
        type: 'success',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      if (formData?.employeeId) {
        const id = formData.employeeId.toString();
        const { employee } = await getEmployeeInfo(id);
        setProfileInfo(employee);
        setInitialEmployeeName(employee.firstName + ' ' + employee.lastName);
        setFormData({ ...employee });
      }
      setIsEditScreen(false);
    } else {
      Store.addNotification({
        title: 'There was a problem saving ',
        message: '',
        type: 'warning',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  };

  const handleCancel = () => {
    //PUT EMPLOYEE NEW DATA
    setFormData({ ...profileInfo });
    const temp =
      fillPermissionObjectWithCurrentEmployeePermissions(profileInfo);
    setPermissionArrayObject(temp);
    setIsEditScreen(false);
  };

  if (loading) return <Loader />;

  return (
    <>
      <HeaderSection
        pageName="Employee profile"
        setFromCancel={setFromCancel}
        setCancelOrConfirmMessage={setCancelOrConfirmMessage}
        setIsConfirmOrCancelModalOpen={setIsConfirmOrCancelModalOpen}
        isStateDirty={isStateDirty()}
        handleCancel={handleCancel}
        setIsEditScreen={setIsEditScreen}
        isEditScreen={isEditScreen}
        origin={'employeeDetails'}
      />

      <div className="mb-8 mx-6">
        {formData && (
          <EmployeeInformation
            formData={formData}
            handleChangeValue={handleChangeValue}
            permissionArrayObject={permissionArrayObject}
            setPermissionArrayObject={setPermissionArrayObject}
            setFormData={setFormData}
            isEditScreen={isEditScreen}
            initialEmployeeName={initialEmployeeName}
            handleSubmit={handleSubmit}
          />
        )}
      </div>

      <CancelOrConfirmModal
        message={cancelOrConfirmMessage}
        fromCancel={fromCancel}
        onConfirm={handleSubmit}
        onCancel={handleCancel}
        open={isConfirmOrCancelModalOpen}
        onClose={() => setIsConfirmOrCancelModalOpen(false)}
      />
    </>
  );
};

export default EmployeeDetail;
