import React, { useState, useEffect, useMemo } from 'react';
import SendCryptoInformation from '@/components/widgets/sections/EmployeeInformation/SendCryptoInformation';
import EmployeeDetails from '@/components/widgets/sections/EmployeeInformation/EmployeeDetails';
import MuiSwitch from '@/components/common/Switch';
import { EmployeeDetails as EmployeeDetailsType } from '@/type/employee';
import { useParams } from 'react-router-dom';
import Button from '@/components/common/Button';
import PermissionsSection from '../PermissionsSection';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getEmployeeFilterData,
  employeeFilterDataSelector
} from '@/store/employee';
import { titles, managers } from '@/constants/addNewEmployeeOptions';

type Props = {
  formData: EmployeeDetailsType;
  setFormData: any;
  handleChangeValue: any;
  permissionArrayObject: any;
  setPermissionArrayObject: any;
  initialEmployeeName: string;
  handleSubmit: any;
  isEditScreen: boolean;
};

const EmployeeInformation: React.FC<Props> = ({
  formData,
  permissionArrayObject,
  setPermissionArrayObject,
  initialEmployeeName,
  handleChangeValue,
  setFormData,
  handleSubmit,
  isEditScreen
}) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const filterData = useAppSelector(employeeFilterDataSelector);

  useEffect(() => {
    const fetchFilterData = async () => {
      await dispatch(getEmployeeFilterData());
    };

    fetchFilterData();
  }, []);

  const employeeRoleOptions = useMemo(() => {
    if (titles) {
      const options = titles.map((item) => ({
        label: item,
        value: item
      }));

      return options;
    }
    return [];
  }, [filterData]);

  const employeeTypeOptions = useMemo(() => {
    if (filterData?.employeeTypes) {
      const options = filterData.employeeTypes.map((item: any) => ({
        label: item,
        value: item
      }));

      return options;
    }
    return [];
  }, [filterData]);

  const employeeManagerOptions = useMemo(() => {
    if (managers) {
      const options = managers.map((item) => ({
        label: item,
        value: item
      }));

      return options;
    }
    return [];
  }, [filterData]);

  const countryOptions = useMemo(() => {
    // Since we are not expecting an initial All ____ from the backend
    if (filterData?.countries) {
      const options = filterData.countries.map((item) => ({
        label: item.countryName,
        value: item.countryName
      }));

      return options;
    }
    return [];
  }, [filterData]);

  const departmentOptions = useMemo(() => {
    if (filterData?.departments) {
      // Since we are not expecting an initial All ____ from the backend
      const fixedFilterData = [...filterData.departments];
      const options = fixedFilterData.map((item) => ({
        label: item,
        value: item
      }));

      return options;
    }
    return [];
  }, [filterData]);

  return (
    <div>
      <div className="grid gap-4 grid-cols-2 row-span-1 mb-10">
        <div className="col-span-1">
          <h2 className="text-title font-medium mb-4">Personal information</h2>
          <div className="shadow-paper bg-white p-4 py-5 rounded-3xl">
            <EmployeeDetails
              data={formData}
              setData={setFormData}
              id={params.id}
              countryOptions={countryOptions}
              isEditScreen={isEditScreen}
            />
          </div>
        </div>

        <div className="grid grid-rows-3 col-span-1 gap-4">
          <div className="row-span-2">
            <h2 className="text-title font-medium mb-4">
              SendCrypto information
            </h2>
            <div className="shadow-paper p-4 bg-white rounded-3xl">
              <SendCryptoInformation
                id={params.id}
                data={formData}
                setData={setFormData}
                name={formData.firstName + ' ' + formData.lastName}
                initialEmployeeName={initialEmployeeName}
                status={formData?.status}
                title="Test User"
                onChangeValue={handleChangeValue('employeeName')}
                fromEmployee={false}
                departmentOptions={departmentOptions}
                employeeManagerOptions={employeeManagerOptions}
                employeeTypeOptions={employeeTypeOptions}
                employeeRoleOptions={employeeRoleOptions}
                isEditScreen={isEditScreen}
              />
            </div>
          </div>
          <div className="shadow-paper bg-white px-4 rounded-3xl flex">
            <div
              className={
                'flex content-center self-center justify-between w-full'
              }>
              <div>
                <p className="font-semibold">Deactivate Profile</p>
                <p className="text-sm text-gray-300">
                  {"Activate / deactivate this employee's profile"}
                </p>
              </div>
              <div>
                <MuiSwitch
                  isDisabled={!isEditScreen}
                  className={`${
                    isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
                  }`}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      status:
                        formData.status === 'Active' ? 'Inactive' : 'Active'
                    });
                  }}
                  isChecked={formData.status === 'Active' ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {permissionArrayObject && (
        <div>
          <PermissionsSection
            permissionArrayObject={permissionArrayObject}
            setPermissionArrayObject={setPermissionArrayObject}
            isEditScreen={isEditScreen}
          />
        </div>
      )}
    </div>
  );
};

export default EmployeeInformation;
