import { IconType } from '@/components/common/Icon';

type Menu = {
  label: string;
  icon: IconType;
  path: string;
};

export const menu: Menu[] = [
  {
    label: 'Dashboard',
    path: '/',
    icon: 'dashboard-menu-icon'
  },
  // {
  //   label: 'Analytics',
  //   path: '/analytics',
  //   icon: 'line-chart'
  // },
  {
    label: 'Users',
    path: '/users',
    icon: 'user-menu-icon'
  },
  {
    label: 'Transactions',
    path: '/transactions',
    icon: 'transaction-menu-icon'
  },
  {
    label: 'Employees',
    path: '/employees',
    icon: 'employee-menu-icon'
  }
  // {
  //   label: 'Wallets',
  //   path: '/wallets',
  //   icon: 'wallet'
  // }
];
