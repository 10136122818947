import { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@/components/common/IconButton';
import Icon from '@/components/common/Icon';
import MuiSwitch from '@/components/common/Switch';
import Input from '@/components/common/Input';
import Tabs from '@/components/common/Tabs';
import AddNewEmployeeDropdownFilter from '@/components/widgets/filters/AddNewEmployeeDropdownFilter';
import EditEmployeeDropdownFilter from '@/components/widgets/filters/EditEmployeeDropdownFilter';
import { titles, managers } from '@/constants/addNewEmployeeOptions';
import { postEmployees } from '@/service/employee.service';
import './style.css';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  permissionArraysObject,
  permissionArrayObjectType,
  permissionObjectType
} from '../../../components/widgets/sections/PermissionsSection/permissionArray';
import {
  getEmployeeFilterData,
  employeeFilterDataSelector
} from '@/store/employee';
import {
  ALPHA_DASH_REGEX,
  NUMERIC_DASH_REGEX
} from '@/components/common/Regex';
import PermissionsSection from '../../../components/widgets/sections/PermissionsSection';
import ToggleInput from '@/components/common/ToggleInput';
import HeaderSection from '@/components/widgets/sections/HeaderSection';
import { Store } from 'react-notifications-component';
import './style.css';

const PHONE_REGEX =
  /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
const EMAIL_REGEX =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
const DATE_REGEX = /^$|^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;

const AddNewEmployee = () => {
  const currentTheme = useContext(CurrentThemeContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [country, setCountry] = useState<string[]>([]);
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [role, setRole] = useState('Regular Employee');
  const [department, setDepartment] = useState<string[]>([]);
  const [title, setTitle] = useState<string[]>([]);
  const [manager, setManager] = useState<string[]>([]);
  const [employeeType, setEmployeeType] = useState<string[]>([]);
  const [isRemote, setIsRemote] = useState(0);
  const filterData = useAppSelector(employeeFilterDataSelector);

  useEffect(() => {
    const fetchFilterData = async () => {
      await dispatch(getEmployeeFilterData());
    };
    fetchFilterData();
  }, []);

  const [permissionArrayObject, setPermissionArrayObject] = useState<any>({
    userPermissions: [
      {
        title: 'Deactivate User',
        description:
          "Checking this permission will give the employee the right to deactivate a user's account",
        isTrue: false,
        permissionCode: 499
      },
      {
        title: 'Create User Note',
        description:
          "Checking this permission will give the employee the right to create a note on a user's account",
        isTrue: false,
        permissionCode: 408
      },
      {
        title: 'Update User Profile Information',
        description:
          "Checking this permission will give the employee the right to update a user's profile information",
        isTrue: false,
        permissionCode: 406
      },
      {
        title: 'View User KYC Data',
        description:
          "Checking this permission will give the employee the right to view a user's KYC data, including images of their personal documents",
        isTrue: false,
        permissionCode: 407
      }
    ],
    employeePermissions: [
      {
        title: 'Create Employee',
        description:
          'Checking this permission will give the employee the right to add new employees',
        isTrue: false,
        permissionCode: 501
      },
      {
        title: 'Edit Employee',
        description:
          'Checking this permission will give the employee the right to modify employees',
        isTrue: false,
        permissionCode: 505
      }
    ],
    downloadPermissions: [
      {
        title: 'Download Permissions',
        description:
          'Checking this permission will give the employee the right to download table data as CSV files',
        isTrue: false,
        permissionCode: 601
      }
    ]
  });

  const employeeTitleOptions = useMemo(() => {
    if (titles) {
      const options = titles.map((item) => ({
        label: item,
        value: item
      }));
      setTitle([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const employeeTypeOptions = useMemo(() => {
    if (filterData?.employeeTypes) {
      const options = filterData.employeeTypes.map((item) => ({
        label: item,
        value: item
      }));

      setEmployeeType([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const employeeManagerOptions = useMemo(() => {
    if (managers) {
      const options = managers.map((item) => ({
        label: item,
        value: item
      }));

      setManager([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const countryOptions = useMemo(() => {
    // Since we are not expecting an initial All ____ from the backend
    if (filterData?.countries) {
      const options = filterData.countries.map((item) => ({
        label: item.countryName,
        value: item.countryName
      }));

      options.unshift({ label: 'All Countries', value: 'All Countries' });
      setCountry([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const departmentOptions = useMemo(() => {
    if (filterData?.departments) {
      // Since we are not expecting an initial All ____ from the backend
      const fixedFilterData = [...filterData.departments];
      const options = fixedFilterData.map((item) => ({
        label: item,
        value: item
      }));

      setDepartment([options[0].value]);

      return options;
    }
    return [];
  }, [filterData]);

  const handleCreateEmployee = async () => {
    const permissionCodes: number[] = [];
    Object.keys(permissionArrayObject).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      permissionArrayObject[key].forEach((item: permissionObjectType) => {
        if (item.isTrue) {
          permissionCodes.push(item.permissionCode);
        }
      });
    });

    try {
      const ddmmyyyyArr = birthdate.split('/');
      const yyyymmdd =
        ddmmyyyyArr.length > 2
          ? `${ddmmyyyyArr[2]}-${ddmmyyyyArr[1]}-${ddmmyyyyArr[0]}`
          : undefined;
      const newEmployeeObject = {
        FirstName: name,
        LastName: surname,
        CountryCode: country[0] !== 'All Countries' ? country[0] : '',
        PhoneNumber: phone,
        Email: email,
        BirthDate: yyyymmdd,
        City: city,
        Address: address,
        Password: 'Pa23rlocz2!rwq12451',
        RoleIds: role === 'Regular Employee' ? [3] : [1],
        Department: department[0] !== 'All Departments' ? department[0] : '',
        EmployeeRole: title[0] !== 'All Titles' ? title[0] : '',
        PermissionIds: permissionCodes,
        ManagerName: manager[0] !== 'All Managers' ? manager[0] : '',
        Status: 'Active',
        EmployeeType:
          employeeType[0] !== 'All Employee Types' ? employeeType[0] : '',
        IsRemote: isRemote ? 'Yes' : 'No'
      };

      const resp = await postEmployees(newEmployeeObject);

      if (
        // if there is an error.
        resp?.response?.status &&
        [400, 401, 402, 403].includes(resp.response.status)
      ) {
        throw new Error(resp.response.data.title || resp.response.data.message);
      } else {
        Store.addNotification({
          title: 'Employee has been successfully added! ✅',
          message: '',
          type: 'success',
          insert: 'top',
          container: 'bottom-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        navigate(-1);
      }
    } catch (err: any) {
      console.log(err);
      Store.addNotification({
        title: 'Employee creation failed! ❌',
        message: '',
        type: 'danger',
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  };

  const isContinueDisabled = () => {
    return name.length < 2 ||
      surname.length < 2 ||
      !PHONE_REGEX.test(phone) ||
      !EMAIL_REGEX.test(email) ||
      !DATE_REGEX.test(birthdate)
      ? 'disabled'
      : false;
  };

  const isDateOfBirthProblematic = () => {
    if (!DATE_REGEX.test(birthdate)) {
      return true;
    }

    if (
      birthdate?.substring(0, 2) &&
      birthdate?.substring(3, 5) &&
      birthdate?.substring(6, 10)
    ) {
      const dd = parseInt(birthdate.substring(0, 2));
      const mm = parseInt(birthdate.substring(3, 5));
      const yyyy = parseInt(birthdate.substring(6, 10));

      if (dd > 31 || dd < 1) return true;
      if (mm > 12 || mm < 1) return true;
      if (yyyy < 1900 || yyyy > new Date().getFullYear()) return true;
    }

    return false;
  };

  return (
    <>
      <HeaderSection pageName="Add new employee" origin="addNewEmployee" />

      <div className="mx-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-8 ">
          <div className="mb-10">
            <p className="text-title font-medium mb-4">Personal information:</p>
            <div className="shadow-paper bg-white p-4 py-5 rounded-3xl h-full">
              <div className="grid grid-cols-2 gap-2 mb-5">
                <div className="col-span-1">
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setName(newVal)}
                    value={name}
                    regex={ALPHA_DASH_REGEX}
                    label={'Name'}
                    placeholder="Jane"
                  />
                  {/* {name.length < 2 && (
                    <p className="block pt-0 text-xs pb-2 text-red-500">
                      Name must be at least 2 characters
                    </p>
                  )} */}
                </div>
                <div className="col-span-1">
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setSurname(newVal)}
                    value={surname}
                    regex={ALPHA_DASH_REGEX}
                    label={'Surname'}
                    placeholder="Cooper"
                  />
                  {/* {surname.length < 2 && (
                    <p className="block pt-0 text-xs pb-2 text-red-500">
                      Surname must be at least 2 characters
                    </p>
                  )} */}
                </div>
                <div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setPhone(newVal)}
                    value={phone}
                    regex={NUMERIC_DASH_REGEX}
                    label={'Phone number'}
                    placeholder="359xxxxxxxxx"
                  />
                  {/* {!PHONE_REGEX.test(phone) && (
                    <p className="block pt-0 text-xs pb-2 text-red-500">
                      Please fix this phone number format
                    </p>
                  )} */}
                </div>
                <div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setEmail(newVal)}
                    value={email}
                    label={'Email'}
                    isWrongFormat={!EMAIL_REGEX.test(email) && email !== ''}
                    placeholder="janecooper@gmail.com"
                  />
                  {email !== '' && !EMAIL_REGEX.test(email) && (
                    <p className="block pt-0 text-xs pb-2 text-errorscale-dark">
                      Please fix this email format
                    </p>
                  )}
                </div>
                <div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => {
                      setBirthdate(
                        (birthdate.length === 1 || birthdate.length === 4) &&
                          birthdate.length < newVal.length
                          ? newVal + '/'
                          : newVal
                      );
                    }}
                    value={birthdate}
                    label={'Date of birth'}
                    isWrongFormat={isDateOfBirthProblematic()}
                    placeholder="DD/MM/YYYY"
                  />
                  {isDateOfBirthProblematic() && (
                    <p className="block pt-0 text-xs pb-2 text-errorscale-dark">
                      Please fix this date format
                    </p>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="form-row-input">
                  <EditEmployeeDropdownFilter
                    options={countryOptions}
                    label="All Countries"
                    selectedValue={country[0]}
                    searchable={true}
                    placeholder='Search for country'
                    onChangeFilter={(e) => {
                      setCountry([e]);
                    }}
                  />
                </div>
                <div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setCity(newVal)}
                    value={city}
                    label={'City'}
                    placeholder="City"
                  />
                </div>
                <div>
                  <div className="label"></div>
                  <ToggleInput
                    editable={true}
                    onChangeValue={(newVal) => setAddress(newVal)}
                    value={address}
                    label={'Address'}
                    placeholder="Address"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-10">
            <p className="text-title font-medium mb-4">
              SendCrypto information:
            </p>
            <div className="shadow-paper bg-white p-4 py-5 rounded-3xl h-full">
              <div className="grid grid-cols-2 gap-2 mb-5">
                <div className="flex mb-5">
                  <EditEmployeeDropdownFilter
                    options={[
                      { label: 'Regular Employee', value: 'Regular Employee' },
                      { label: 'Admin', value: 'Admin' }
                    ]}
                    label="All Roles"
                    selectedValue={role}
                    onChangeFilter={(e) => {
                      setRole(e);
                    }}
                  />
                </div>
                <div className="flex justify-between mb-5">
                  <div className="label">Remote</div>
                  <div className="flex items-center">
                    <MuiSwitch
                      className="isEditScreen"
                      isChecked={isRemote === 1 ? true : false}
                      onClick={() => setIsRemote(isRemote === 0 ? 1 : 0)}
                    />
                    <span className="text-sm text-grey ml-2 mb-1">
                      {isRemote === 0 ? 'No' : 'Yes'}
                    </span>
                  </div>
                </div>
                <div className="flex mb-5">
                  <EditEmployeeDropdownFilter
                    options={departmentOptions}
                    label="All Departments"
                    selectedValue={department[0]}
                    onChangeFilter={(e) => {
                      setDepartment([e]);
                    }}
                  />
                </div>
                <div className="flex mb-5">
                  <EditEmployeeDropdownFilter
                    options={employeeTitleOptions}
                    label="All Titles"
                    selectedValue={title[0]}
                    onChangeFilter={(e) => {
                      setTitle([e]);
                    }}
                  />
                </div>
                <div className="flex mb-5">
                  <EditEmployeeDropdownFilter
                    options={employeeManagerOptions}
                    label="All Managers"
                    selectedValue={manager[0]}
                    onChangeFilter={(e) => {
                      setManager([e]);
                    }}
                  />
                </div>
                <div className="flex mb-5">
                  <EditEmployeeDropdownFilter
                    options={employeeTypeOptions}
                    label="All Employee Types"
                    selectedValue={employeeType[0]}
                    onChangeFilter={(e) => {
                      setEmployeeType([e]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <PermissionsSection
          permissionArrayObject={permissionArrayObject}
          isEditScreen={true}
          setPermissionArrayObject={setPermissionArrayObject}
        />
        <div className="flex-col float-right">
          <button
            onClick={() => handleCreateEmployee()}
            className={`disabled:text-white disabled:opacity-50 disabled:cursor-not-allowed min-w-[260px] my-10 rounded-3xl font-semibold h-[50px] px-5 flex items-center justify-center hover:shadow-button text-white ${
              currentTheme.theme === 'YELLOWAFRICA'
                ? 'bg-beautiful-yellow-africa'
                : 'bg-beautiful'
            } disabled:bg-grey active:shadow-activeButton active:bg-primary`}
            // @ts-expect-error disabled attribute in typescript expects boolean but it needs to receive 'disabled' as a string
            disabled={isContinueDisabled()}>
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNewEmployee;
