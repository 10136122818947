import React, { useMemo, PropsWithChildren } from 'react';
import Icon from '@/components/common/Icon';
import IconButton from '@/components/common/IconButton';
import Button from '@/components/common/Button';
import { useNavigate } from 'react-router-dom';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';
import DateRangePicker from '@/components/widgets/tools/DateRangePicker';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

type HeaderProps = {
  date?: any;
  setDate?: any;
  pageName: string;
  origin?: string;
  setFromCancel?: any;
  setCancelOrConfirmMessage?: any;
  isStateDirty?: boolean;
  handleCancel?: any;
  setIsConfirmOrCancelModalOpen?: any;
  setIsEditScreen?: any;
  isEditScreen?: boolean;
  triggerExportEvent?: any;
};

const backButtonPages = [
  'addNewEmployee',
  'editEmployee',
  'addNewEmployee',
  'userDetails',
  'employeeDetails'
];

const HeaderSection: React.FC<HeaderProps> = ({
  date,
  setDate,
  triggerExportEvent,
  pageName,
  setFromCancel,
  setCancelOrConfirmMessage,
  isStateDirty,
  handleCancel,
  setIsConfirmOrCancelModalOpen,
  setIsEditScreen,
  isEditScreen,
  origin
}) => {
  const currentTheme = useContext(CurrentThemeContext);
  const user = useMemo(() => {
    const account = localStorage.getItem('dd_account');
    if (account) return JSON.parse(account);
    return null;
  }, []);
  const navigate = useNavigate();
  const goToAddNew = () => {
    navigate('/employee/add');
  };

  const handleBack = () => {
    if (isStateDirty) {
      setFromCancel(true);
      setCancelOrConfirmMessage(
        'Are you sure you want to discard these changes? ⚠️'
      );
      setIsConfirmOrCancelModalOpen(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex items-center p-4 mb-6 flex-col md:flex-row bg-white">
      {origin && backButtonPages.includes(origin) && (
        <button
          onClick={handleBack}
          className="p-5 mr-6 self-center rounded-full bg-[#EFF0F6] hover:bg-[#E9E9E9] hover:duration-300 hover:cursor-pointer">
          <WestOutlinedIcon />
        </button>
      )}
      <h1 className="text-heading font-bold ml-2 text-[#A0A3BD]">{pageName}</h1>
      <div className="flex flex-1 flex-wrap justify-end gap-4 lg:gap-0">
        <div className="bg-[#EFF0F6] py-3 rounded-full">
          {date ? (
            <DateRangePicker date={date} setDate={setDate} />
          ) : (
            <div></div>
          )}
        </div>

        {origin === 'employees' ? (
          <Button
            className="sm:w-auto px-10 ml-1 my-2.5"
            variant="secondary"
            onClick={goToAddNew}
            disabled={!user.permissionIds.includes(501)}>
            <Icon className="mr-3.5" name="add" />
            Add new employee
          </Button>
        ) : origin === 'employeeDetails' ? (
          <div className="my-[0.17rem]">
            {isEditScreen ? (
              <div className="flex self-center items-center justify-center">
                <button
                  className="self-center font-medium cursor-pointer bg-[#EFF0F6] mr-3 p-5 rounded-full hover:bg-[#E9E9E9] hover:duration-300 hover:cursor-pointer"
                  onClick={() => {
                    if (isStateDirty) {
                      setFromCancel(true);
                      setCancelOrConfirmMessage(
                        'Are you sure you want to discard these changes? ⚠️ Changes will not be saved.'
                      );
                      setIsConfirmOrCancelModalOpen(true);
                    } else {
                      handleCancel();
                    }
                  }}>
                  <CloseIcon />
                </button>
                <button
                  disabled={!isStateDirty}
                  onClick={() => {
                    setFromCancel(false);
                    setCancelOrConfirmMessage(
                      'Are you sure you want to confirm these changes?'
                    );
                    setIsConfirmOrCancelModalOpen(true);
                  }}
                  className={`disabled:text-white disabled:opacity-50 disabled:cursor-not-allowed rounded-full font-semibold py-5 px-8 flex self-center justify-center hover:shadow-button text-white ${
                    currentTheme.theme === 'YELLOWAFRICA'
                      ? 'bg-beautiful-yellow-africa'
                      : 'bg-beautiful'
                  } disabled:bg-grey active:shadow-activeButton active:bg-primary`}>
                  <CheckIcon className="mr-2" />
                  Confirm edition
                </button>
              </div>
            ) : (
              <button
                onClick={() => setIsEditScreen(true)}
                disabled={!user.permissionIds.includes(505)}
                className="flex self-center items-center justify-center p-6 rounded-full bg-[#EFF0F6] hover:bg-[#E9E9E9] hover:duration-300 hover:cursor-pointer disabled:bg-grey-disabled disabled:hover:cursor-not-allowed">
                <Icon name="pencil" />
              </button>
            )}
          </div>
        ) : origin !== 'addNewEmployee' ? (
          <button
            onClick={triggerExportEvent}
            disabled={
              user.permissionIds.includes(601) || origin === 'analytics'
            }
            className="ml-7 p-5 self-center rounded-full bg-[#EFF0F6] hover:bg-[#E9E9E9] hover:duration-300 hover:cursor-pointer disabled:bg-grey-disabled disabled:hover:cursor-not-allowed">
            <Icon name="download" />
          </button>
        ) : (
          <div className="p-[2.2rem]"></div>
        )}
      </div>
    </div>
  );
};

export default HeaderSection;
