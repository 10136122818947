import React from 'react';
import { Navigate, Outlet, OutletProps } from 'react-router-dom';
import './style.css';
import { useAppSelector } from '@/store';
import { isSignedInSelector } from '@/store/auth';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';

const AuthLayout: React.FC<OutletProps> = (props) => {
  const currentTheme = useContext(CurrentThemeContext);
  const isSigned = useAppSelector(isSignedInSelector);

  return isSigned ? (
    <Navigate to="/"></Navigate>
  ) : (
    <div
      className={`auth-layout ${
        currentTheme.theme === 'YELLOWAFRICA'
          ? "!bg-[url('/src/assets/images/auth_bg_yellow_africa.png')] !bg-[#f3f3f3]"
          : "bg-[url('/src/assets/images/auth_bg.png')] bg-[#eff0f7]"
      }`}>
      {currentTheme.theme === 'YELLOWAFRICA' ? (
        <div className="mb-[53px] flex items-center ml-10">
          <img
            className="flex-shrink-0"
            src="/logo-mark-yellow-africa.svg"
            alt="logo"
          />
          <div className="flex flex-col">
            <div className="text-xl font-bold ml-2.5 whitespace-nowrap">
              <p>YellowDot</p>
            </div>
            <div className="ml-2.5 uppercase text-xs font-medium">
              My Experience
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-[53px] flex items-center ml-10">
          <img src="/logo-mark.svg" alt="logo" />
          <h2 className="font-bold uppercase text-sm ml-2.5">SendCrypto</h2>
        </div>
      )}
      <Outlet {...props}></Outlet>
    </div>
  );
};

export default AuthLayout;
