import Icon from '@/components/common/Icon';
import Input from '@/components/common/Input';

type Props = {
  searchBar: string;
  setSearchBar: any;
};

const SearchBarFilter: React.FC<Props> = ({ searchBar, setSearchBar }) => {
  return (
    <Input
      className="min-w-[325px] w-2/5 mt-2 py-7 bg-[#EFF0F6] border-0 text-[#6E7191]"
      placeholder="Search for a user (user ID/ Name/ Sendtag/ Email/ phone number)"
      value={searchBar}
      startAdornment={<Icon className="ml-3.5 text-grey" name="search" />}
      onChange={(e) => setSearchBar(e.target.value)}
    />
  );
};
export default SearchBarFilter;
