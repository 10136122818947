import service from '@/service/http.service';
import {
  GetUserRequest,
  UserFilterDataResponse,
  GetUserResponse,
  GetUserInfoRequest,
  GetTransactionRequest,
  TransactionFilterDataResponse,
  GetTransactionResponse
} from '@/type/api';
import { UserInformation, UserWallet, UserNotes } from '@/type/user';
import { Buffer } from 'buffer';

export const getUserFilterData = (): Promise<UserFilterDataResponse> => {
  return service
    .get('/users/user-filters-data')
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getUsers = (params: GetUserRequest): Promise<GetUserResponse> => {
  return service
    .get('/users', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getUserInfo = (
  params: GetUserInfoRequest
): Promise<UserInformation> => {
  return service
    .get('/users/user-info', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getUserWallets = (
  params: GetUserInfoRequest
): Promise<UserWallet> => {
  return service
    .get('/users/user-wallets', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getUserNotes = (
  params: GetUserInfoRequest
): Promise<UserNotes> => {
  return service
    .get(`/users/user-notes`, {}, params)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postUserNote = (
  customerIdp: string,
  subject: string,
  note: any
) => {
  return service
    .post(`/users/${customerIdp}/notes`, {
      subject: subject,
      note: note
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTransactions = (
  params: GetTransactionRequest
): Promise<GetTransactionResponse> => {
  return service
    .get('/users/user-transactions', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getTransactionFilters = (
  customerId?: string
): Promise<TransactionFilterDataResponse> => {
  const params = customerId ? { customerId } : {};
  return service
    .get('/users/user-transactions-filters', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const putUserInfo = (
  firstName: string,
  lastName: string,
  userId: any
) => {
  return service
    .put(`/users/${userId}`, {
      firstName: firstName,
      lastName: lastName
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.status);
};

export const deactivateUser = (customerIdp: string) => {
  return service
    .put(`/users/close-account?customeridp=${customerIdp}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getUserReferrals = (customerIdp: string) => {
  return service
    .get(`/users/user-referrals?customeridp=${customerIdp}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getKYCData = (customerIdp: string) => {
  return service
    .get(`/kycdata/${customerIdp}`)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getKYAttachmentById = (
  customerIdp: string,
  attachmentId: string
) => {
  return service
    .get(
      `/kycdata/${customerIdp}/details/attachments/${attachmentId}`,
      undefined,
      undefined,
      { responseType: 'arraybuffer' }
    )
    .then((response) => {
      const image = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      const resss = `data:${response.headers[
        'content-type'
      ].toLowerCase()};base64,${image}`;
      return resss;
    })
    .catch((error) => {
      throw error;
    });
};
