import { Link } from 'react-router-dom';
import Icon from '@/components/common/Icon';
import Table from '@/components/common/Table';
import Status from '@/components/common/Status';
import AvatarPlaceholder from '@/assets/images/avatar-placeholder.png';
import { TableColumn } from '@/type/table';
import { useAppSelector } from '@/store';
import { employeesSelector } from '@/store/employee';

const employeeColumns: TableColumn[] = [
  {
    field: 'employeeId',
    label: 'EmployeeId',
    cellClassName: 'max-w-[80px]',
    tooltip: true,
    render: (row: any) => (
      <div className="flex items-center justify-center">
        <img
          className="mr-2 w-[18px] h-[18px] rounded-full"
          src={row?.row?.avatar ? row.row.avatar : AvatarPlaceholder}
          alt="User"
        />

        <Link
          to={`/employee/${row.value}`}
          className="text-sm text-left text-ctascale-lightMain font-semibold">
          {row.value}
        </Link>
      </div>
      // <Link
      //   to={`/employee/${row.avatar}`}
      //   className="text-sm text-left text-primary font-semibold">
      //   {row.name}
      // </Link>
    )
  },
  {
    field: 'firstName',
    label: 'Name'
  },
  {
    field: 'lastName',
    label: 'Surname'
  },
  {
    field: 'department',
    label: 'Department'
  },
  {
    field: 'city',
    label: 'City'
  },
  {
    field: 'countryCode',
    label: 'Country'
  },
  {
    field: 'email',
    label: 'Email'
  },
  {
    field: 'status',
    label: 'Status'
  },
  {
    field: 'phoneNumber',
    label: 'Phone number'
  },
  {
    field: 'employeeType',
    label: 'Type'
  },
  {
    field: 'employeeRole',
    label: 'Title'
  }
];

const EmployeeTable = () => {
  const employees = useAppSelector(employeesSelector);
  return (
    <div className="rounded-xl ">
      <Table pagination columns={employeeColumns} data={employees || []} />
    </div>
  );
};

export default EmployeeTable;
