import React from 'react';
import ModalUnstyled, {
  ModalUnstyledBackdropSlotProps
} from '@mui/base/ModalUnstyled';
import clsx from 'clsx';
import './style.css';

export type ModalProps = React.PropsWithChildren & {
  open: boolean;
  onClose: () => void;
  smallScreen?: boolean;
  noBackground?: boolean;
};

const BackdropUnStyled = React.forwardRef<
  HTMLDivElement,
  {
    open?: boolean;
    className: string;
  } & ModalUnstyledBackdropSlotProps
>((props, ref) => {
  const { open, className, onClick } = props;

  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      onClick={onClick}
    />
  );
});

BackdropUnStyled.displayName = 'BackdropUnStyled';

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  onClose,
  noBackground,
  smallScreen
}) => {
  return (
    <ModalUnstyled
      className="modal"
      open={open}
      onClose={onClose}
      slots={{
        backdrop: BackdropUnStyled
      }}>
      <div
        className={`modal-container ${noBackground && '!bg-transparent'} ${
          smallScreen ? '!max-w-[600px]' : ''
        }`}>
        {children}
      </div>
    </ModalUnstyled>
  );
};

export default Modal;
