import { createSlice } from '@reduxjs/toolkit';
import { login } from './actions';
import { AuthState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LoginResponse } from '@/type/api';

const initialState: AuthState = {
  isSigned: false,
  user: null
};

const setAuthState = (state: AuthState, data: { payload: LoginResponse }) => {
  const {
    id,
    firstName,
    lastName,
    email,
    resetPasswordToken,
    hasLoggedIn,
    permissionIds,
    accessToken,
    refreshToken,
    accessTokenExpiresAt,
    refreshTokenExpiresAt
  } = data.payload;

  state.isSigned = true;

  state.user = {
    id,
    firstName,
    lastName,
    email,
    permissionIds,
    resetPasswordToken,
    hasLoggedIn
  };

  localStorage.setItem(
    'dd_account',
    JSON.stringify({
      id,
      firstName,
      lastName,
      email,
      permissionIds,
      resetPasswordToken,
      hasLoggedIn
    })
  );

  localStorage.setItem(
    'dd_token',
    JSON.stringify({
      accessToken,
      refreshToken,
      accessTokenExpiresAt,
      refreshTokenExpiresAt
    })
  );
};

const setSigned = (state: AuthState, action: PayloadAction<boolean>) => {
  state.isSigned = action.payload;
};

const logout = (state: AuthState) => {
  state.isSigned = false;
  localStorage.removeItem('dd_token');
  localStorage.removeItem('dd_account');
  localStorage.removeItem('employee_filter');
  localStorage.removeItem('home_filter');
  localStorage.removeItem('dd_first_login');
  localStorage.removeItem('date_filter');
  localStorage.removeItem('transaction_filter');
  localStorage.removeItem('user_filter');
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState,
    setSigned,
    logout
  },
  extraReducers(builder) {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      setAuthState(state, { payload });
    });
  }
});
