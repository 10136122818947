import { createSlice } from '@reduxjs/toolkit';
import {
  getEmployees,
  getEmployeeFilterData,
  getTransactionFilterData,
  getTransactions,
  getEmployeeInfo
} from './actions';
import { EmployeeState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  GetEmployeesResponse,
  EmployeeFilterDataResponse,
  GetTransactionResponse,
  TransactionFilterDataResponse,
  GetEmployeeResponse
} from '@/type/api';

const initialState: EmployeeState = {
  filter: null,
  transactionFilter: null,
  employees: null,
  transactions: null,
  employee: null
};

const setEmployeeFilterData = (
  state: EmployeeState,
  action: PayloadAction<any>
) => {
  state.filter = action.payload;
};

const setEmployee = (
  state: EmployeeState,
  action: PayloadAction<GetEmployeeResponse>
) => {
  state.employee = action.payload.employee;
};

const setEmployees = (
  state: EmployeeState,
  action: PayloadAction<GetEmployeesResponse>
) => {
  state.employees = action.payload?.employees ? action.payload.employees : [];
};

const setTransactionFilterData = (
  state: EmployeeState,
  action: PayloadAction<TransactionFilterDataResponse>
) => {
  state.transactionFilter = action.payload;
};

const setTransactions = (
  state: EmployeeState,
  action: PayloadAction<GetTransactionResponse>
) => {
  state.transactions = action.payload.transactions;
};

export const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployees,
    setEmployeeFilterData
  },
  extraReducers(builder) {
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      setEmployees(state, action);
    });
    builder.addCase(getEmployeeFilterData.fulfilled, (state, action) => {
      setEmployeeFilterData(state, action);
    });
    builder.addCase(getTransactionFilterData.fulfilled, (state, action) => {
      setTransactionFilterData(state, action);
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      setTransactions(state, action);
    });
    builder.addCase(getEmployeeInfo.fulfilled, (state, action) => {
      setEmployee(state, action);
    });
  }
});
