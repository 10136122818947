import React, { ChangeEvent } from 'react';
import './style.css';

type Props = {
  label: string;
  value?: string;
  onChange?: (value: string) => void;
};

const InputFilter: React.FC<Props> = ({ label, value, onChange }) => {
  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <div className="filter-input bg-[#EFF0F6] border-opacity-0 rounded-md border w-[325px] py-7 flex">
      <input
        className="w-full text-sm placeholder:text-secondary focus:outline-0 relative z-10 bg-transparent self-center"
        value={value}
        placeholder={!value ? label : ''}
        onChange={handleChangeInput}
      />
    </div>
  );
};

export default InputFilter;
