import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserReferrals } from '@/service/user.service';
import Table from '@/components/common/Table';
import { TableColumn } from '@/type/table';
import { Link } from 'react-router-dom';
import Status from '@/components/common/Status';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';

const SUCCESS_VALUES = ['completed', 'success', 'true', 'registered'];

const referralsColumns: TableColumn[] = [
  {
    field: 'id',
    label: 'User ID',
    cellClassName: 'w-[70px]',
    tooltip: true
  },
  {
    field: 'customerName',
    label: 'Name',
    cellClassName: 'capitalize',
    tooltip: true,
    render: (row: any) => (
      <Link
        to={`/user/${row.row.id}`}
        className="text-sm text-left text-primary font-semibold">
        {row.value}
      </Link>
    )
  },
  {
    field: 'userName',
    label: 'Sendtag',
    cellClassName: 'capitalize',
    tooltip: true
  },
  {
    field: 'phoneNumber',
    label: 'Phone Number'
  },
  {
    field: 'kyc',
    label: 'KYC',
    render: (row: any) => <Status rounded={true} status={row.value} />
  },
  {
    field: 'registrationDate',
    label: 'Registration Data',
    render: (row: any) => (
      <div>{moment(row.value).format('D MMM YY, h:mm')}</div>
    )
  },
  {
    field: 'status',
    label: 'Status',
    render: (row: any) => (
      <div className="bg-[#EFF0F6] rounded-xl p-2">
        {!SUCCESS_VALUES.includes(row.value.toLowerCase()) ? (
          <CloseIcon style={{ color: '#DD2590', height: '18' }} />
        ) : (
          <CheckIcon style={{ color: '#60B527', height: '18' }} />
        )}
        {row.value}
      </div>
    )
  },
  {
    field: 'balanceUSDExt',
    label: 'Balance in USD EXT',
    render: (row: any) => <p>{`$ ${row.value}`}</p>
  },
  {
    field: 'isRewarded',
    label: 'Is Rewarded',
    render: (row: any) => (
      <div className="bg-[#EFF0F6] rounded-xl p-2">
        {!SUCCESS_VALUES.includes(row.value.toLowerCase()) ? (
          <CloseIcon style={{ color: '#DD2590', height: '18' }} />
        ) : (
          <CheckIcon style={{ color: '#60B527', height: '18' }} />
        )}
        {row.value}
      </div>
    )
  },
  {
    field: 'rewardedDate',
    label: 'Rewarded Date',
    render: (row: any) => <div>{moment(row.value).format('D MMM YYYY')}</div>
  }
];

const ReferredUsersTable: React.FC = () => {
  const location = useLocation();
  const [userReferrals, setUserReferrals] = useState([]);
  const userId = location.pathname.split('/')[2];
  useEffect(() => {
    const getReferrals = async () => {
      const referrals = await getUserReferrals(userId);
      setUserReferrals(referrals);
    };
    getReferrals();
  }, [userId]);

  return (
    <div className="bg-white w-full rounded-xl">
      <Table
        pagination
        columns={referralsColumns}
        data={userReferrals ?? []}
        hei="300"
      />
    </div>
  );
};

export default ReferredUsersTable;
