import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthProvider from '@/provider/AuthProvider';
import DefaultLayout from '@/components/layouts/DefaultLayout';
import AuthLayout from '@/components/layouts/AuthLayout';
import Transactions from '@/containers/Transactions';
import Users from '@/containers/Users';
import UserDetail from '@/containers/Users/UserDetail';
import Profile from '@/containers/Profile';
import Analytics from '@/containers/Analytics';
import Wallets from '@/containers/Wallets';
import Login from '@/containers/Login';
import ResetPassword from '@/containers/ResetPassword';
import Employees from '@/containers/Employees';
import EmployeeDetail from '@/containers/Employees/EmployeeDetail';
import AddNewEmployee from '@/containers/Employees/AddNew';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  'f3a22427964faf07e664792437faea60Tz02MjEyMixFPTE3MTA1NDMwNDQzMTUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route key="DefaultLayout" path="/" element={<DefaultLayout />}>
          <Route key="Dashboard" path="" element={<Analytics />} />
          <Route key="Users" path="users" element={<Users />} />
          <Route key="User Detail" path="user/:id" element={<UserDetail />} />
          <Route key="Profile" path="profile" element={<Profile />} />
          <Route
            key="Transaction"
            path="transactions"
            element={<Transactions />}
          />
          <Route key="Employees" path="employees" element={<Employees />} />
          <Route
            key="Employee Detail"
            path="employee/:id"
            element={<EmployeeDetail />}
          />
          {/*<Route*/}
          {/*  key="Transaction"*/}
          {/*  path="transactions"*/}
          {/*  element={<Employees />}*/}
          {/*/>*/}
          <Route
            key="AddEmployee"
            path="/employee/add"
            element={<AddNewEmployee />}
          />
          <Route key="Analytics" path="analytics" element={<Analytics />} />
          <Route key="Wallets" path="wallets" element={<Wallets />} />
        </Route>
        <Route key="AuthLayout" path="/" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
