import { createSlice } from '@reduxjs/toolkit';
import { DashboardState } from './types';
import {
  getDashboard,
  getUserInformation,
  getFinanceInformation,
  getUserGeography,
  getSubscriptionPlans,
  getNonCustodialWallets,
  getCoinsByHolders
} from './actions';
import {
  UserInformation,
  FinanceInformation,
  UserGeography,
  SubscriptionPlan,
  NonCustodialWallet,
  CoinsByHolders
} from '@/type/dashboard';

const initialState: DashboardState = {
  dashboard: null,
  userInformation: null,
  financeInformation: null,
  userGeography: null,
  plansAndStatistics: null,
  nonCustodialWallets: null,
  coinsByHolders: null
};

const setDashboard = (state: DashboardState, action: { payload: any }) => {
  state.dashboard = action.payload;
};

const setUserInformation = (
  state: DashboardState,
  action: { payload: UserInformation }
) => {
  state.userInformation = action.payload;
};

const setFinanceInformation = (
  state: DashboardState,
  action: { payload: FinanceInformation }
) => {
  state.financeInformation = action.payload;
};

const setUserGeography = (
  state: DashboardState,
  action: { payload: UserGeography }
) => {
  state.userGeography = action.payload;
};

const setPlansAndStatistics = (
  state: DashboardState,
  action: { payload: SubscriptionPlan }
) => {
  state.plansAndStatistics = action.payload;
};

const setNonCustodialWallets = (
  state: DashboardState,
  action: { payload: NonCustodialWallet }
) => {
  state.nonCustodialWallets = action.payload;
};

const setCoinsByHolders = (
  state: DashboardState,
  action: { payload: CoinsByHolders }
) => {
  state.coinsByHolders = action.payload;
};

export const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboard,
    setUserInformation,
    setFinanceInformation,
    setUserGeography,
    setPlansAndStatistics,
    setNonCustodialWallets
  },
  extraReducers(builder) {
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      setDashboard(state, action);
    });
    builder.addCase(getUserInformation.fulfilled, (state, action) => {
      setUserInformation(state, action);
    });
    builder.addCase(getFinanceInformation.fulfilled, (state, action) => {
      setFinanceInformation(state, action);
    });
    builder.addCase(getUserGeography.fulfilled, (state, action) => {
      setUserGeography(state, action);
    });
    builder.addCase(getSubscriptionPlans.fulfilled, (state, action) => {
      setPlansAndStatistics(state, action);
    });
    builder.addCase(getNonCustodialWallets.fulfilled, (state, action) => {
      setNonCustodialWallets(state, action);
    });
    builder.addCase(getCoinsByHolders.fulfilled, (state, action) => {
      setCoinsByHolders(state, action);
    });
  }
});
