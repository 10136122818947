// TODO: Please check this component can be useful in latest design and remove it if unnecessary
import React, { PropsWithChildren } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// import Icon from '@/components/common/Icon';

type Props = PropsWithChildren & {
  id: string;
  index: number;
};

const DraggableWrapper: React.FC<Props> = ({ id, index, children }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="relative group">
          {/*<span*/}
          {/*  className="absolute z-10 left-2.5 top-[18px] opacity-0 group-hover:opacity-100"*/}
          {/*  {...provided.dragHandleProps}>*/}
          {/*  <Icon name="drag" />*/}
          {/*</span>*/}
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableWrapper;
