import { AxiosError } from 'axios';
import moment from 'moment';
import { UserItem, UserFilter } from '@/type/user';

export const isUnAuthorized = (error: AxiosError): boolean => {
  if (error.response) {
    return error.response.status === 401;
  }

  return false;
};

// TODO: Please remove after integrating api.
export const filterUsers = (users: UserItem[], filterOption: UserFilter) => {
  const filteredUsers = [];

  if (users) {
    for (let i = 0; i < users.length; i++) {
      if (filterOption.userid) {
        if (!users[i].id?.includes(filterOption.userid)) continue;
      }
      if (filterOption.emailp) {
        if (!users[i].email?.includes(filterOption.emailp)) continue;
      }
      if (filterOption.customernamep) {
        if (!users[i].customerName?.includes(filterOption.customernamep))
          continue;
      }
      if (filterOption.from && filterOption.to) {
        if (!users[i].registrationDate) continue;
        const isBefore = moment(filterOption.from).isBefore(
          moment(users[i].registrationDate)
        );
        const isAfter = moment(filterOption.to).isAfter(
          moment(users[i].registrationDate)
        );
        if (!isBefore || !isAfter) continue;
      }
      if (filterOption.kycStatus) {
        if (
          filterOption.kycStatus !== 'All KYC Statuses' &&
          filterOption.kycStatus !== users[i].kyc
        )
          continue;
      }
      if (filterOption.subscriptionp) {
        if (
          filterOption.subscriptionp !== 'All Subscriptions' &&
          filterOption.subscriptionp !== users[i].subscriptionPlan
        )
          continue;
      }

      filteredUsers.push(users[i]);
    }

    return filteredUsers;
  }
  return [];
};
