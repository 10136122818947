import React, { useState, useEffect } from 'react';
import { getKYAttachmentById, getKYCData } from '@/service/user.service';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ImageModal from '../../modal/ImageModal';

export type DocumentsTableDataType = any;
type Props = {
  userId: string;
};

const DocumentsTable: React.FC<Props> = ({ userId }) => {
  const [imgArray, setImgArray] = useState<string[]>([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    (async () => {
      await getKYAttachmentById(userId, '0')
        .then((resp: string) => {
          setImgArray((prev) => [...prev, resp]);
        })
        .catch((err) => {
          console.log(err);
        });
      await getKYAttachmentById(userId, '1')
        .then((resp: string) => {
          setImgArray((prev) => [...prev, resp]);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
    return () => {
      setImgArray([]);
    };
  }, [userId]);

  const handleCopy = () => {
    const value = imgArray[0];
    navigator.clipboard.writeText(value);
  };

  return (
    <div className="bg-white w-full h-96 min-w-[300px] px-7 pt-5 rounded-3xl">
      <TextField
        label={'Documents / photos'}
        value={imgArray[0] || ' '}
        className="w-full bg-[#EFF0F6] rounded-xl"
        sx={{
          '& label': {
            left: '0.7rem',
            top: '0.3rem'
          },
          '& label.Mui-focused': {
            color: '#636365'
          }
        }}
        InputProps={{
          readOnly: true,
          disableUnderline: true,
          className: 'px-3',
          endAdornment: imgArray[0] && (
            <div
              onClick={() => {
                handleCopy();
              }}
              className="z-10 cursor-pointer">
              <ContentCopyIcon />
            </div>
          )
        }}
        variant="standard"
      />
      {imgArray?.length > 0 ? (
        <div className="flex w-full gap-2">
          {imgArray.map((img, index) => (
            <div
              key={index}
              onClick={() => {
                setSelectedImage(img);
                setIsImageModalOpen(true);
              }}>
              <img className="max-h-min mt-5 rounded-2xl" src={img} />
            </div>
          ))}
        </div>
      ) : (
        <p className="mt-5"> No images found</p>
      )}
      <ImageModal
        open={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
        image={selectedImage}
      />
    </div>
  );
};

export default DocumentsTable;
