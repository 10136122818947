import axios, { AxiosRequestConfig } from 'axios';
import config from '@/config';
import 'react-notifications-component/dist/theme.css';
import * as authApi from '@/service/auth.service';

export const http = axios.create({
  baseURL: `${config.apiServer}/api`
});

console.log('www test env ====> ', config.apiServer);

http.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('dd_token');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  config.headers.common['Backoffice_operator'] =
    window.location.href.startsWith('https://dashboard-dev.sendcrypto.com') ||
    window.location.href.startsWith('https://dashboard.sendcrypto.com')
      ? 'send_crypto'
      : 'yellow_africa';

  if (token) {
    const { accessToken } = JSON.parse(token);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.common['Authorization'] = `bearer ${accessToken}`;
  }
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      if (
        !originalRequest._retry &&
        !originalRequest.url.includes('/auth/refresh-access-token')
      ) {
        originalRequest._retry = true;
        const tokens = localStorage.getItem('dd_token');

        if (tokens) {
          const {
            accessToken,
            refreshToken,
            accessTokenExpiresAt,
            refreshTokenExpiresAt
          } = JSON.parse(tokens);
          const currentTime = new Date().getTime();
          try {
            if (
              currentTime < new Date(accessTokenExpiresAt).getTime() ||
              currentTime > new Date(refreshTokenExpiresAt).getTime()
            ) {
              throw 'Timeout';
            }
            const response = await authApi.refresh({
              accessToken,
              refreshToken
            });
            localStorage.setItem(
              'dd_token',
              JSON.stringify({
                accessToken: response.accessToken,
                accessTokenExpiresAt: response.accessTokenExpiresAt,
                refreshToken,
                refreshTokenExpiresAt
              })
            );
            originalRequest.headers[
              'Authorization'
            ] = `bearer ${response.accessToken}`;
            return http(originalRequest);
          } catch (e) {
            clearAndReload();
          }
        }
      } else {
        clearAndReload();
      }
    }
    return Promise.reject(error);
  }
);

function clearAndReload() {
  if (
    !window.location.pathname.includes('/login') &&
    !window.location.pathname.includes('/')
  ) {
    window.location.reload();
  }
  localStorage.removeItem('employee_filter');
  localStorage.removeItem('home_filter');
  localStorage.removeItem('dd_first_login');
  localStorage.removeItem('date_filter');
  localStorage.removeItem('transaction_filter');
  localStorage.removeItem('user_filter');
}

function get(
  url: string,
  headers: Record<string, any> = {},
  params: Record<string, any> = {},
  config: Record<string, any> = {}
) {
  return http.get(url, {
    params,
    headers,
    ...config
  });
}

function post<T>(
  url: string,
  data: Record<string, any> = {},
  headers: Record<string, any> = {},
  options: Record<string, any> = {}
) {
  return http.post<T>(url, data, {
    ...options,
    headers
  });
}

function put(
  url: string,
  data: Record<string, any> = {},
  headers: Record<string, any> = {},
  options: Record<string, any> = {}
) {
  return http.put(url, data, {
    ...options,
    headers
  });
}

function remove(url: string, headers = {}, options = {}) {
  return http.delete(url, {
    ...options,
    headers
  });
}

export default {
  http,
  get,
  post,
  put,
  remove
};
