import service from '@/service/http.service';
import { GetDashboardRequest } from '@/type/api';
import {
  UserInformation,
  FinanceInformation,
  UserGeography,
  SubscriptionPlan,
  NonCustodialWallet,
  CoinByHolders
} from '@/type/dashboard';

export const getDashboard = (): Promise<any> => {
  return service
    .get('/dashboard')
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getUserInformation = (
  params: GetDashboardRequest
): Promise<UserInformation> => {
  return service
    .get('/dashboard/user-information', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getFinanceInformation = (
  params: GetDashboardRequest
): Promise<FinanceInformation> => {
  return service
    .get('/dashboard/finance-information', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getUserGeography = (
  params: GetDashboardRequest
): Promise<UserGeography> => {
  return service
    .get('/dashboard/user-geography', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getSubscriptionPlans = (
  params: GetDashboardRequest
): Promise<SubscriptionPlan> => {
  return service
    .get('/dashboard/subscription-plans-statistics', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getNonCustodialWallets = (
  params: GetDashboardRequest
): Promise<NonCustodialWallet> => {
  params.resultCount = 4;
  return service
    .get('/dashboard/non-custodial-wallets', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getCoinsByHolders = (
  params: GetDashboardRequest
): Promise<CoinByHolders> => {
  return service
    .get('/dashboard/coins-by-holders', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const updateDashboard = (): Promise<any> => {
  return service
    .put('/dashboard')
    .then((response) => response.data)
    .catch((error) => error.status);
};
