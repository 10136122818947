import React, { PropsWithChildren, useState, useEffect } from 'react';
import { useAppDispatch } from '@/store';
import { setSigned } from '@/store/auth';
import FullScreenLoader from '@/components/widgets/loaders/FullScreenLoader';
import * as authApi from '@/service/auth.service';

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tokens = localStorage.getItem('dd_token');

    if (tokens) {
      const { accessToken, refreshToken, refreshTokenExpiresAt } =
        JSON.parse(tokens);

      authApi
        .refresh({
          accessToken,
          refreshToken
        })
        .then((response) => {
          localStorage.setItem(
            'dd_token',
            JSON.stringify({
              accessToken: response.accessToken,
              refreshToken,
              accessTokenExpiresAt: response.accessTokenExpiresAt,
              refreshTokenExpiresAt
            })
          );

          dispatch(setSigned(true));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) return <FullScreenLoader />;

  return <>{children}</>;
};

export default AuthProvider;
