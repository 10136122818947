import service from '@/service/http.service';
import {
  LoginRequest,
  LoginResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
  SendResetPasswordEmailRequest,
  ResetPasswordRequest
} from '@/type/api';
import { AxiosError } from 'axios';

export const login = (data: LoginRequest): Promise<LoginResponse> => {
  return service
    .post<LoginResponse>('/auth/get-access-token', data)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const refresh = (
  data: RefreshTokenRequest
): Promise<RefreshTokenResponse> => {
  return service
    .post<RefreshTokenResponse>('/auth/refresh-access-token', data)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const sendResetPasswordEmail = (
  data: SendResetPasswordEmailRequest
): Promise<any> => {
  return service
    .post('/employees/send-reset-password-email', data)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const resetPassword = (data: ResetPasswordRequest): Promise<any> => {
  return service
    .put('/employees/reset-password', data)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const resetFirstPassword = (data: ResetPasswordRequest): Promise<any> => {
  return service
    .put('/employees/reset-first-password', data)
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};
