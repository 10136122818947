import React, { useMemo, useState } from 'react';
import Input from '@/components/common/Input';
import Icon from '@/components/common/Icon';
import Coin from '@/components/common/Coin';
import Table from '@/components/common/Table';
import { TableColumn } from '@/type/table';
import { UserWallet } from '@/type/user';
import { toNormalizedNumber, eToNumber } from '@/utils/string';

const walletColumn: TableColumn[] = [
  {
    field: 'wallet',
    label: 'Wallet',
    render: (row: any) => <Coin name={row.value} />
  },
  {
    field: 'balanceCrypto',
    label: 'Balance',
    render: (row: any) => <>{eToNumber(row.value)}</>
  },
  {
    field: 'balanceUSD',
    label: 'Balance (fiat)',
    render: (row: any) => (
      <>
        {('' + row.value)[0] !== '-' //If it is a negative number then put the - sign before the dollar sign
          ? eToNumber(`$${row.value}`)
          : eToNumber(`-$${Math.abs(row.value)}`)}
      </>
    )
  }
];

type Props = {
  data: UserWallet;
};

const WalletTable: React.FC<Props> = ({ data }) => {
  const [searchText, setSearchText] = useState('');
  const userWalletsFiltered = data.userWallets.filter((it) =>
    it.wallet.toLowerCase().includes(searchText.trim().toLowerCase())
  );
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <div className="bg-white rounded-xl">
      <Table
        columns={walletColumn}
        data={userWalletsFiltered}
        pagination={false}
        size="sm"
        odd={false}
      />
    </div>
  );
};

export default WalletTable;
