import React, { useMemo, useState } from 'react';
import ToggleInput from '@/components/common/ToggleInput';
import { EmployeeDetails as EmployeeDetailsType } from '@/type/employee';
import EditEmployeeDropdownFilter from '@/components/widgets/filters/EditEmployeeDropdownFilter';
import {
  ALPHA_DASH_REGEX,
  NUMERIC_DASH_REGEX
} from '@/components/common/Regex';

type Props = {
  data: EmployeeDetailsType;
  setData: any;
  id: string | undefined;
  countryOptions: any;
  isEditScreen: boolean;
};

const EmployeeDetails: React.FC<Props> = ({
  data,
  id,
  setData,
  countryOptions,
  isEditScreen
}) => {
  const editableContactFields: Array<{
    field: keyof EmployeeDetailsType;
    label: string;
    regex?: RegExp;
  }> = [
    {
      field: 'firstName',
      label: 'Name',
      regex: ALPHA_DASH_REGEX
    },
    {
      field: 'lastName',
      label: 'Last Name',
      regex: ALPHA_DASH_REGEX
    },
    {
      field: 'phoneNumber',
      label: 'Phone number',
      regex: NUMERIC_DASH_REGEX
    },
    {
      field: 'email',
      label: 'Email address'
    },
    {
      field: 'birthDate',
      label: 'Birth date'
    }
  ];

  const editableAddressFields: Array<{
    field: keyof EmployeeDetailsType;
    label: string;
  }> = [
    {
      field: 'countryCode',
      label: 'Country of living'
    },
    {
      field: 'city',
      label: 'City'
    },
    {
      field: 'address',
      label: 'Address'
    }
  ];

  return (
    <>
      <div className="flex flex-col justify-around gap-8">
        <div className="grid grid-cols-2 gap-2">
          {editableContactFields.map((item, index) => (
            <div key={index}>
              <ToggleInput
                editable={isEditScreen && item.field !== 'email'}
                label={item.label}
                value={data[item.field]}
                regex={item.regex}
                onChangeValue={(newVal) => {
                  setData({
                    ...data,
                    [item.field]:
                      item.field === 'birthDate' &&
                      (newVal.length === 2 || newVal.length === 5) &&
                      newVal.length > data.birthDate.length
                        ? newVal + '/'
                        : newVal
                  });
                }}
              />
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-2">
          {editableAddressFields.map((item, index) => (
            <div key={index}>
              {!isEditScreen ? (
                <ToggleInput
                  editable={isEditScreen}
                  label={item.label}
                  value={data[item.field]}
                  onChangeValue={(newVal) => {
                    setData({ ...data, [item.field]: newVal });
                  }}
                  country={
                    item.field === 'countryCode' ? data.countryCode : undefined
                  }
                />
              ) : item.field === 'countryCode' ? (
                <div className="form-row-input">
                  <EditEmployeeDropdownFilter
                    options={countryOptions}
                    label="All Countries"
                    selectedValue={data.countryCode}
                    searchable={true}
                    onChangeFilter={(newVal) => {
                      setData({ ...data, countryCode: newVal });
                    }}
                  />
                </div>
              ) : (
                <ToggleInput
                  editable={isEditScreen}
                  label={item.label}
                  value={data[item.field]}
                  onChangeValue={(newVal) => {
                    setData({ ...data, [item.field]: newVal });
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmployeeDetails;
