export const departments = [
  'Management',
  'Research & Development',
  'IT',
  'Marketing',
  'Legal',
  'Human Resources'
];

export const titles = [
  'All Titles',
  'CEO',
  'CTO',
  'Director',
  'Architect',
  'Developer',
  'QA',
  'HR',
  'Designer',
  'Recruiter',
  'Director',
  'Product'
];

export const managers = [
  'All Managers',
  'Executive',
  'Senior Manager',
  'Team Lead',
  'Not Applicable'
];

export const employeeTypes = ['Full Time', 'Part Time', 'Contractor'];

export const countries = [
  'Bulgaria',
  'United States',
  'France',
  'Germany',
  'Albania'
];
