import React, { ReactNode, useImperativeHandle } from 'react';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import PopperUnstyled, { PopperPlacementType } from '@mui/base/PopperUnstyled';
import clsx from 'clsx';
import './style.css';

type Props = {
  anchor: ReactNode | string;
  children: any;
  className?: string;
  popperClassName?: string;
  placement?: PopperPlacementType;
  bodyClickable?: boolean;
  closePopper?: () => void;
  onChangeOpening?: (status: boolean) => void;
  disabled?: boolean;
};

const Popper = React.forwardRef<Props, any>(
  (
    {
      anchor,
      children,
      className,
      popperClassName,
      placement = 'bottom-end',
      bodyClickable,
      onChangeOpening,
      isOpen,
      disabled
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );

    if (isOpen === undefined || isOpen === null) {
      isOpen = Boolean(anchorEl);
    }

    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (isOpen) {
        setAnchorEl(null);
        onChangeOpening?.(false);
      } else {
        setAnchorEl(event.currentTarget);
        buttonRef.current!.focus();
        onChangeOpening?.(true);
      }
    };

    const close = () => {
      setAnchorEl(null);
      buttonRef.current!.focus();
      onChangeOpening?.(false);
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useImperativeHandle(ref, () => ({
      closePopper() {
        close();
      }
    }));

    return (
      <>
        <button
          className={className}
          type="button"
          onClick={handleButtonClick}
          disabled={disabled}
          ref={buttonRef}>
          {anchor}
        </button>
        <PopperUnstyled
          className={clsx('popper', popperClassName)}
          open={isOpen}
          anchorEl={anchorEl}
          placement={placement}>
          <ClickAwayListener onClickAway={close}>
            <div onClick={() => bodyClickable && close()}>{children}</div>
          </ClickAwayListener>
        </PopperUnstyled>
      </>
    );
  }
);

Popper.displayName = 'Popper';

export default Popper;
