import React, { useRef } from 'react';
import Icon from '@/components/common/Icon';
import Popper from '@/components/common/Popper';

type Props = {
  hidden?: boolean;
};

const CardMenu: React.FC<Props> = ({ hidden }) => {
  const ref = useRef(null);

  const handleClickItem = () => {
    const popper = ref.current as any;
    popper.closePopper();
  };

  return (
    <span className="mt-0.5 cursor-pointer">
      {/* <Icon name="more-vert" /> */}
    </span>
    // <Popper
    //   ref={ref}
    //   className="px-2 -mr-2 rounded-full"
    //   popperClassName="border border-primary bg-white rounded-xl w-[230px]"
    //   placement="left-start"
    //   anchor={
    //     <span className="mt-0.5 cursor-pointer">
    //       <Icon name="more-vert" />
    //     </span>
    //   }>
    //   <div>
    //     <div className="dropdown-item" onClick={handleClickItem}>
    //       <Icon name={hidden ? 'eye' : 'closed-eye'} />
    //       <span className="font-semibold text-sm ml-2.5">
    //         {hidden ? 'Show Widget' : 'Hide Widget'}
    //       </span>
    //     </div>
    //     <div className="dropdown-item">
    //       <Icon className="w-[22px]" name="download" />
    //       <span className="font-semibold text-sm ml-2.5">Download data</span>
    //     </div>
    //   </div>
    // </Popper>
  );
};

export default CardMenu;
