import React, { useState } from 'react';
import TransactionTable from '@/components/widgets/tables/TransactionTable';
import FilterTransaction from '@/components/widgets/filters/FilterTransaction';
import HeaderSection from '@/components/widgets/sections/HeaderSection';

const Transactions = () => {
  const [gridReference, setGridReference] = useState();

  const triggerExportEvent = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (gridReference?.current?.exportDataAsCsv) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gridReference.current.exportDataAsCsv();
    }
  };
  return (
    <>
      <HeaderSection
        pageName="Transactions"
        triggerExportEvent={triggerExportEvent}
      />
      <div className="mx-6">
        <FilterTransaction />

        <TransactionTable setGridReference={setGridReference} />
      </div>
    </>
  );
};

export default Transactions;
