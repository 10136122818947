import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { chartColor } from '@/constants/colors';

export type ColumnChartCol = (string | number)[];

type Props = {
  data: ColumnChartCol[];
};

const ColumnChart: React.FC<Props> = ({ data }) => {
  const options = {
    chart: {
      type: 'column'
    },
    title: false,
    colors: [chartColor[0]],
    xAxis: {
      crosshair: true,
      labels: {
        style: {
          fontSize: '12px'
        }
      },
      type: 'category'
    },
    yAxis: {
      min: 0,
      gridLineWidth: 0,
      title: false
    },
    plotOptions: {
      series: {
        borderRadius: 15,
        pointWidth: 20
      }
    },
    series: [
      {
        name: 'Holders',
        colorByPoint: true,
        data: data,
        showInLegend: false 
      }
    ],
    credits: {
      enabled: false
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ColumnChart;
