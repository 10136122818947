import React, { useMemo, useEffect } from 'react';
import moment from 'moment';
import { toNormalizedNumber } from '@/utils/string';
import Icon from '@/components/common/Icon';
import LineChart, { LineChartRow } from '@/components/widgets/charts/LineChart';
import Card from '@/components/common/Card';
import { DepositVsWithdrawItem, DepositVsWithdraw } from '@/type/dashboard';
import { chartColor } from '@/constants/colors';

type Props = {
  data: DepositVsWithdrawItem[];
  initialX: string[];
  setFinanceInformationChartData: (obj: DepositVsWithdraw) => void;
};

const DepositWithdrawCard: React.FC<Props> = ({
  data,
  initialX,
  setFinanceInformationChartData
}) => {
  const processedData = useMemo(() => {
    let depositSum = 0;
    let withDrawSum = 0;
    const depositData: LineChartRow = {
      x: [],
      y: []
    };
    const withDrawData: LineChartRow = {
      x: [],
      y: []
    };

    if (data && data.length) {
      data?.forEach((item) => {
        if (item.transactionType === 'Deposit') {
          depositSum += item.totalAmountTradedUSD;
          depositData.x.push(moment(item.transactionDate).format('MMM DD'));
          depositData.y.push(item.totalAmountTradedUSD);
        } else if (item.transactionType === 'Withdraw') {
          withDrawSum += item.totalAmountTradedUSD;
          withDrawData.x.push(moment(item.transactionDate).format('MMM DD'));
          withDrawData.y.push(item.totalAmountTradedUSD);
        }
      });
    } else {
      depositData.x = initialX;
      withDrawData.x = initialX;
    }
    return {
      depositSum,
      depositData,
      withDrawSum,
      withDrawData
    };
  }, [data, initialX]);

  useEffect(() => {
    setFinanceInformationChartData({
      deposit: processedData.depositSum,
      withdraw: processedData.withDrawSum
    });
  }, [processedData])

  return (
    <Card noData={!data?.length}>
      <div className="flex items-center mb-2 gap-6">
        <div className="flex flex-col ml-4">
          <div className="flex items-center">
            <span
              className="w-3 h-3 rounded-full mr-2.5"
              style={{ backgroundColor: chartColor[0] }}
            />
            <p>Deposit</p>
          </div>
          <p className="font-bold text-xl mr-2">
            {`${toNormalizedNumber(processedData.depositSum, 'currency')}`}
          </p>
        </div>

        <div className="flex flex-col ml-4">
          <div className="flex items-center">
            <span
              className="w-3 h-3 rounded-full mr-2.5"
              style={{ backgroundColor: chartColor[1] }}
            />
            <p>Withdraw</p>
          </div>
          <p className="font-bold text-xl mr-2">
            {`${toNormalizedNumber(processedData.withDrawSum, 'currency')}`}
          </p>
        </div>
      </div>

      <div className="-mb-4 -mx-2 mt-5">
        <LineChart
          data={[processedData.depositData, processedData.withDrawData]}
        />
      </div>
    </Card>
  );
};

export default DepositWithdrawCard;
