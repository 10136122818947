import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';

const selectSelf = (state: RootState) => state.dashboard;

export const dashboardSelector = createSelector(
  selectSelf,
  (state) => state.dashboard
);

export const userInformation = createSelector(
  selectSelf,
  (state) => state.userInformation
);

export const financeInformation = createSelector(
  selectSelf,
  (state) => state.financeInformation
);

export const userGeography = createSelector(
  selectSelf,
  (state) => state.userGeography
);

export const plansAndStatistics = createSelector(
  selectSelf,
  (state) => state.plansAndStatistics
);

export const nonCustodialWallets = createSelector(
  selectSelf,
  (state) => state.nonCustodialWallets
);

export const coinsByHolders = createSelector(
  selectSelf,
  (state) => state.coinsByHolders
);
