import React, { useMemo, useState } from 'react';
import ToggleInput from '@/components/common/ToggleInput';
import { UserInformation } from '@/type/user';
import Icon from '@/components/common/Icon';
import IconButton from '@/components/common/IconButton';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';

type Props = {
  data: UserInformation;
  id: string | undefined;
};

const editableContactFields: Array<{
  field: keyof UserInformation;
  label: string;
}> = [
  {
    field: 'email',
    label: 'Email address'
  },
  {
    field: 'birthDate',
    label: 'Birth date'
  },
  {
    field: 'phoneNumber',
    label: 'Phone number'
  },
  {
    field: 'isUSSD',
    label: 'Registered via'
  }
];

const editableAddressFields: Array<{
  field: keyof UserInformation;
  label: string;
}> = [
  {
    field: 'countryName',
    label: 'Country of living'
  },
  {
    field: 'address',
    label: 'Address'
  },
  {
    field: 'city',
    label: 'City'
  },
  {
    field: 'zipCode',
    label: 'Zip Code'
  }
];

const PersonalDetails: React.FC<Props> = ({ data, id }) => {
  const currentTheme = useContext(CurrentThemeContext);
  const [editable, setEditable] = useState(false);

  const fixedFields = useMemo(
    () => [
      {
        value: id || '',
        label: 'User ID'
      },
      {
        value: data?.['customerId'],
        label: 'CustomerId ID'
      },
      {
        value: data?.['username'],
        label: 'Sendtag'
      },
      {
        value: data?.['referralBy'],
        label: 'Referred By'
      }
    ],
    [data]
  );

  return (
    <>
      <div className="flex flex-wrap w-full justify-end mb-4 md:mb-0">
        {editable ? (
          <div className="flex mb-3">
            <IconButton
              className="flex items-center font-medium hover:text-primary cursor-pointer bg-gray-200 mr-3"
              onClick={() => setEditable(false)}>
              <Icon name="close" />
            </IconButton>
            <button
              onClick={() => setEditable(false)}
              className={`disabled:text-white disabled:opacity-50 disabled:cursor-not-allowed rounded-3xl font-semibold px-5 flex items-center justify-center hover:shadow-button text-white ${
                currentTheme.theme === 'YELLOWAFRICA'
                  ? 'bg-beautiful-yellow-africa'
                  : 'bg-beautiful'
              } disabled:bg-grey active:shadow-activeButton active:bg-primary`}>
              <Icon name="save" className="mr-2" />
              Confirm
            </button>
          </div>
        ) : (
          <div>
            <IconButton
              onClick={() => setEditable(true)}
              className="flex w-full bg-gray-200 mb-3">
              <Icon name="pencil" />
            </IconButton>
          </div>
        )}
      </div>
      <div className="flex justify-around gap-x-4">
        <div className="w-1/3">
          {editableAddressFields.map((item, index) => (
            <div key={index}>
              <ToggleInput
                editable={editable}
                label={item.label}
                value={data[item.field]}
                country={
                  item.field === 'countryName' ? data.countryName : undefined
                }
              />
            </div>
          ))}
        </div>
        <div className="w-1/3">
          {editableContactFields.map((item, index) => (
            <div key={index}>
              {item.field !== 'isUSSD' ? (
                <ToggleInput
                  editable={editable}
                  label={item.label}
                  value={data[item.field]}
                />
              ) : (
                <ToggleInput
                  label={item.label}
                  value={data[item.field] === 'Yes' ? 'USSD' : 'SendCrypto'}
                />
              )}
            </div>
          ))}
        </div>
        <div className="w-1/3">
          {fixedFields.map((item, index) => (
            <div key={index}>
              <ToggleInput
                className={index === 1 ? 'col-span-2' : ''}
                label={item.label}
                value={item.value}
                userId={item.label === 'User ID' ? item.value : undefined}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
