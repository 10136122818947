import MuiSwitch from '@/components/common/Switch';
import { permissionArrayObjectType } from './permissionArray';

type Props = {
  permissionArrayObject: permissionArrayObjectType | undefined;
  setPermissionArrayObject: any;
  isEditScreen?: boolean;
};

const PermissionsSection: React.FC<Props> = ({
  permissionArrayObject,
  setPermissionArrayObject,
  isEditScreen
}) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-1">
        <div className="mb-2">
          <p className="text-title font-medium mb-4">User Permissions:</p>
        </div>
        <div>
          <div className="bg-white pl-7 pr-6 py-3 rounded-3xl shadow-paper">
            {permissionArrayObject?.userPermissions.map((item, index) => (
              <div
                className={'flex items-center justify-between my-2'}
                key={index}>
                <div>
                  <p className="font-semibold mb-0.5">{item.title}</p>
                  <p className="text-sm text-gray-300">{item.description}</p>
                </div>
                <MuiSwitch
                  isDisabled={!isEditScreen}
                  isChecked={item.isTrue}
                  className={`${
                    isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
                  }`}
                  onChange={() => {
                    const tempArr = permissionArrayObject;
                    tempArr.userPermissions[index].isTrue =
                      !tempArr.userPermissions[index].isTrue;
                    setPermissionArrayObject({ ...tempArr });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-1 grid content-between">
        <div>
          <div className="mb-2">
            <p className="text-title font-medium mb-4">
              Emplpoyee Permissions:
            </p>
          </div>
          <div>
            <div className="bg-white pl-7 pr-6 py-3 rounded-3xl shadow-paper">
              {permissionArrayObject?.employeePermissions.map((item, index) => (
                <div
                  className={'flex items-center justify-between my-2'}
                  key={index}>
                  <div>
                    <p className="font-semibold mb-0.5">{item.title}</p>
                    <p className="text-sm text-gray-300">{item.description}</p>
                  </div>
                  <MuiSwitch
                    className={`${
                      isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
                    }`}
                    isDisabled={!isEditScreen}
                    isChecked={item.isTrue}
                    onChange={() => {
                      const tempArr = permissionArrayObject;
                      tempArr.employeePermissions[index].isTrue =
                        !tempArr.employeePermissions[index].isTrue;
                      setPermissionArrayObject({ ...tempArr });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <p className="text-title font-medium mb-4">Download Permissions:</p>
          </div>
          <div>
            <div className="bg-white rounded-3xl pl-7 pr-6 py-3 shadow-paper">
              {permissionArrayObject?.downloadPermissions.map((item, index) => (
                <div
                  className={'flex items-center justify-between my-2'}
                  key={index}>
                  <div>
                    <p className="font-semibold mb-0.5">{item.title}</p>
                    <p className="text-sm text-gray-300">{item.description}</p>
                  </div>
                  <MuiSwitch
                    className={`${
                      isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
                    }`}
                    isDisabled={!isEditScreen}
                    isChecked={item.isTrue}
                    onChange={() => {
                      const tempArr = permissionArrayObject;
                      tempArr.downloadPermissions[index].isTrue =
                        !tempArr.downloadPermissions[index].isTrue;
                      setPermissionArrayObject({ ...tempArr });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionsSection;
