import React from 'react';
import clsx from 'clsx';

const values = {
  success: ['completed', 'success', 'true'],
  error: ['failed', 'false', 'null'],
  warning: ['pending'],
  info: ['uncompleted']
};

type Props = {
  status: string;
  rounded?: boolean;
  fromKYC?: boolean;
  fullWidth?: boolean;
};

const Status: React.FC<Props> = ({ status, rounded, fullWidth, fromKYC }) => {
  return (
    <div
      className={clsx(
        'flex items-center',
        fullWidth && 'w-full justify-center'
      )}>
      <span
        className={clsx(
          'h-8 px-3 flex items-center justify-center text-[13px] capitalize',
          {
            'bg-successscale-light': values.success.includes(
              status?.toLowerCase()
            ),
            'bg-greyscale-lightGrey text-info': values.info.includes(
              status?.toLowerCase()
            ),
            'bg-errorscale-light text-errorscale-dark': values.error.includes(
              status?.toLowerCase()
            ),
            'bg-warning-light text-warning': values.warning.includes(
              status?.toLowerCase()
            ),
            '!text-black': fromKYC
          },
          rounded ? 'rounded-full' : 'rounded-lg',
          fullWidth ? 'w-full' : 'w-fit'
        )}>
        {status}
      </span>
    </div>
  );
};

export default Status;
