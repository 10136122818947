import React from 'react';
import { SetStateAction } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import clsx from 'clsx';

export type FileUploadProps = {
  imageButton?: boolean;
  accept: string;
  hoverLabel?: string;
  dropLabel?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  setLoadingFileUpload: React.Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  image?: {
    url: string;
    imageStyle?: {
      width?: string;
      height?: string;
    };
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
};

const useStyle = makeStyles({
  root: {
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    '&:hover p,&:hover svg,& img': {
      opacity: 1
    },
    '& p, svg': {
      opacity: 0.4
    },
    '&:hover img': {
      opacity: 0.3
    }
  },
  noMouseEvent: {
    pointerEvents: 'none'
  },
  iconText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  hidden: {
    display: 'none'
  },
  onDragOver: {
    '& img': {
      opacity: 0.3
    },
    '& p, svg': {
      opacity: 1
    }
  }
});

export const FileUploader: React.FC<FileUploadProps> = ({
  accept,
  imageButton = false,
  hoverLabel = 'Drag & Drop your file',
  dropLabel = 'Drop file here',
  height = '100px',
  backgroundColor = 'transparent',
  setLoadingFileUpload,
  loading,
  image: {
    url,
    imageStyle = {
      height: 'inherit'
    }
  } = {},
  onChange,
  onDrop
}) => {
  const classes = useStyle();
  const [imageUrl, setImageUrl] = React.useState(url);
  const [labelText, setLabelText] = React.useState<string>(hoverLabel);
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = React.useState<boolean>(false);
  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      setLoadingFileUpload(true);
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);
      if (imageButton && e.dataTransfer.files[0]) {
        setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]));
      }
      onDrop(e);
      setLoadingFileUpload(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingFileUpload(true);
    setLabelText(hoverLabel);
    setIsDragOver(false);
    onChange(event);
    setLoadingFileUpload(false);
  };

  return (
    <>
      <input
        onChange={handleChange}
        accept={accept}
        className={classes.hidden}
        id="file-upload"
        type="file"
      />

      <label
        htmlFor="file-upload"
        {...dragEvents}
        className={clsx(classes.root, isDragOver && classes.onDragOver)}>
        <Box
          width={'100%'}
          height={height}
          bgcolor={backgroundColor}
          className={classes.noMouseEvent}>
          {imageButton && (
            <Box height={height} width={'100%'}>
              <img alt="file upload" src={imageUrl} style={imageStyle} />
            </Box>
          )}

          {(!imageButton || isDragOver || isMouseOver) && (
            <>
              <Box height={height} width={'100%'} className={classes.iconText}>
                <CloudUploadIcon fontSize="large" />
                <Typography>{labelText}</Typography>
              </Box>
            </>
          )}
        </Box>
      </label>
    </>
  );
};