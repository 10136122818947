import React from 'react';
import CurrencyPlaceholder from '@/assets/images/currency-placeholder.png';

type Props = {
  name: string;
};

const Coin: React.FC<Props> = ({ name }) => {
  return (
    <div className="flex items-center">
      <img
        className="mr-2 w-6 h-6 rounded-full"
        src={`/crypto_icons/${name?.toLowerCase()?.split('_')?.[0]}.svg`}
        alt={name}
        onError={(event: any) => {
          event.target.src = CurrencyPlaceholder;
          event.onerror = null;
        }}
      />
      <span>{name}</span>
    </div>
  );
};

export default Coin;
