import { UserRowData } from '@/type/table';
import User1 from '@/assets/images/users/user1.png';
import User2 from '@/assets/images/users/user2.png';
import User3 from '@/assets/images/users/user3.png';
import User4 from '@/assets/images/users/user4.png';
import User5 from '@/assets/images/users/user5.png';

export const users: UserRowData[] = [
  {
    avatar: User1,
    id: 2134,
    name: 'John Frank',
    country: 'Germany',
    email: 'frank@gmail.com',
    phone: '+44 631 5124',
    kyc: 'completed',
    date: '19 Dec 2022 19:48',
    plan: 'Dolphin'
  },
  {
    avatar: User2,
    id: 21341,
    name: 'Anne Smith',
    country: 'Italy',
    email: 'smith@gmail.com',
    phone: '+32 145 5124',
    kyc: 'uncompleted',
    date: '18 Dec 2022 19:48',
    plan: 'Shark'
  },
  {
    avatar: User3,
    id: 21342,
    name: 'John Frank',
    country: 'Germany',
    email: 'frank@gmail.com',
    phone: '+44 631 5124',
    kyc: 'completed',
    date: '19 Dec 2022 19:48',
    plan: 'Fish'
  },
  {
    avatar: User4,
    id: 21343,
    name: 'Anne Smith',
    country: 'Italy',
    email: 'frank@gmail.com',
    phone: '+23 631 5124',
    kyc: 'failed',
    date: '14 Dec 2021 19:48',
    plan: 'Whale'
  },
  {
    avatar: User5,
    id: 21344,
    name: 'John Frank',
    country: 'Germany',
    email: 'frank@gmail.com',
    phone: '+44 631 5124',
    kyc: 'failed',
    date: '19 Dec 2022 19:48',
    plan: 'Dolphin'
  },
  {
    avatar: User1,
    id: 21345,
    name: 'John Frank',
    country: 'Germany',
    email: 'frank@gmail.com',
    phone: '+44 631 5124',
    kyc: 'completed',
    date: '19 Dec 2022 19:48',
    plan: 'Dolphin'
  },
  {
    avatar: User2,
    id: 21346,
    name: 'Anne Smith',
    country: 'Italy',
    email: 'smith@gmail.com',
    phone: '+32 145 5124',
    kyc: 'uncompleted',
    date: '18 Dec 2022 19:48',
    plan: 'Shark'
  },
  {
    avatar: User3,
    id: 21347,
    name: 'John Frank',
    country: 'Germany',
    email: 'frank@gmail.com',
    phone: '+44 631 5124',
    kyc: 'completed',
    date: '19 Dec 2022 19:48',
    plan: 'Fish'
  },
  {
    avatar: User4,
    id: 21348,
    name: 'Anne Smith',
    country: 'Italy',
    email: 'frank@gmail.com',
    phone: '+23 631 5124',
    kyc: 'failed',
    date: '14 Dec 2021 19:48',
    plan: 'Whale'
  },
  {
    avatar: User5,
    id: 21349,
    name: 'John Frank',
    country: 'Germany',
    email: 'frank@gmail.com',
    phone: '+44 631 5124',
    kyc: 'failed',
    date: '19 Dec 2022 19:48',
    plan: 'Dolphin'
  }
];
