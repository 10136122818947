import React from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import ToggleInput from '@/components/common/ToggleInput';

type Props = {
  subject: any;
  note: any;
  open: any;
  onClose: any;
};

const ViewNoteModal: React.FC<Props> = ({ open, onClose, subject, note }) => {
  return (
    <>
      <Modal open={open} onClose={onClose} smallScreen={true}>
        <div className=" flex-col mx-auto max-w-[583px] px-5 pt-5">
          <h2 className="text-title mb-8 font-bold px-3">Preview</h2>
          <ToggleInput
            editable={false}
            label="Subject"
            value={subject}
            className="w-full text-sm placeholder:text-secondary focus:outline-0 relative z-10 bg-transparent self-center"
          />
          <ToggleInput
            editable={false}
            label="Note"
            value={note}
            isMultiline={true}
            className="w-full text-sm placeholder:text-secondary focus:outline-0 relative z-10 bg-transparent break-words"
          />
        </div>
      </Modal>
    </>
  );
};

export default ViewNoteModal;
