import { createSlice } from '@reduxjs/toolkit';
import {
  getUsers,
  getUserFilterData,
  getTransactionFilterData,
  getTransactions
} from './actions';
import { UserState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  GetUserResponse,
  UserFilterDataResponse,
  GetTransactionResponse,
  TransactionFilterDataResponse
} from '@/type/api';

const initialState: UserState = {
  filter: null,
  transactionFilter: null,
  users: null,
  transactions: null,
  employee: null
};

const setUserFilterData = (
  state: UserState,
  action: PayloadAction<UserFilterDataResponse>
) => {
  state.filter = action.payload;
};

const setUsers = (state: UserState, action: PayloadAction<GetUserResponse>) => {
  state.users = action.payload.users;
};

const setTransactionFilterData = (
  state: UserState,
  action: PayloadAction<TransactionFilterDataResponse>
) => {
  state.transactionFilter = action.payload;
};

const setTransactions = (
  state: UserState,
  action: PayloadAction<GetTransactionResponse>
) => {
  state.transactions = action.payload.transactions;
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers,
    setUserFilterData
  },
  extraReducers(builder) {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      setUsers(state, action);
    });
    builder.addCase(getUserFilterData.fulfilled, (state, action) => {
      setUserFilterData(state, action);
    });
    builder.addCase(getTransactionFilterData.fulfilled, (state, action) => {
      setTransactionFilterData(state, action);
    });
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      setTransactions(state, action);
    });
  }
});
