import React, { useState, useEffect, useMemo } from 'react';
import Icon from '@/components/common/Icon';
import KYCTable from '@/components/widgets/tables/KYCTable';
import PersonalTable from '@/components/widgets/tables/PersonalTable';
import DocumentsTable from '@/components/widgets/tables/DocumentsTable';
import PersonalToolbar from '@/components/widgets/sections/PersonalInformation/PersonalToolbar';
import PersonalDetails from '@/components/widgets/sections/PersonalInformation/PersonalDetails';
import { commonDateFormat } from '@/utils/date';
import Status from '@/components/common/Status';
import { UserInformation } from '@/type/user';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

// currently hard code, will change later
const documentAttachedPhotos =
  'https://criterio-sevilla/?si=51bab2a3a7c741f892db81a81013cb3c&utm_so';

type Props = {
  data: UserInformation;
  setProfileInfo: any;
  initialUserName: string;
  userId: string;
};

const PersonalInformation: React.FC<Props> = ({
  setProfileInfo,
  data,
  initialUserName,
  userId
}) => {
  const params = useParams();
  const [copied, setCopied] = useState(false);

  const handleChangeValue = (field: string) => (value: string) => {
    setProfileInfo((prev: any) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleCopy = () => {
    const value = documentAttachedPhotos;
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const personalData = useMemo(() => {
    return [
      {
        type: 'Registration date',
        information: commonDateFormat(data.registrationDate)
      },
      {
        type: 'Registered IP',
        information: data.registrationIP,
        country: 'uk'
      },
      {
        type: 'Last logged IP',
        information: data.lastLoginIP,
        country: 'uk'
      },
      {
        type: 'Last logged date',
        information: commonDateFormat(data.lastLoginDate)
      },
      {
        type: 'Device',
        information: '14 Pro - OS: 16.0.2'
      }
    ];
  }, [data]);

  const kycData = useMemo(() => {
    return [
      {
        type: 'Email',
        status: data.emailVerify,
        date: '19.10.2022'
      },
      {
        type: 'Phone',
        status: data.phoneNumber ? 'completed' : 'uncompleted',
        date: '19.10.2022'
      },
      {
        type: 'ID + Selfie',
        status: data.selfieCompleted,
        date: ''
      },
      {
        type: 'Address',
        status: data.addressCompleted,
        date: ''
      },
      {
        type: 'AML',
        status: data.amlStatus,
        date: ''
      }
    ];
  }, [data]);

  return (
    <div>
      <div className="grid gap-4 grid-cols-3">
        <div className="shadow-paper bg-white p-4 sm:py-5 sm:px-8 col-span-1 rounded-3xl">
          <PersonalToolbar
            id={params.id}
            name={data?.customerName}
            initialUserName={initialUserName}
            status={data?.userStatus}
            title="Test User"
            onChangeValue={handleChangeValue('customerName')}
            fromEmployee={false}
          />
        </div>
        <div className="shadow-paper bg-white p-4 sm:py-5 sm:px-8 col-span-2 rounded-3xl">
          <PersonalDetails data={data} id={params.id} />
        </div>
      </div>
      <div className="flex flex-wrap justify-between gap-3 mt-4 p-1">
        <div className="flex-1">
          <div className="flex gap-4 !text-xl font-semibold">
            <p className="text-xl my-4">KYC</p>
            <Status
              rounded={true}
              fromKYC={true}
              status={
                ['completed', 'success', 'true'].includes(
                  data?.kycStatus?.toLowerCase()
                )
                  ? 'Completed'
                  : 'Uncompleted'
              }
            />
          </div>
          <KYCTable data={kycData} />
        </div>
        <div className="w-full flex-1">
          <p className="text-xl font-semibold my-4">Technical data</p>
          <PersonalTable data={personalData} country={data.countryName} />
        </div>
        <div className="w-full flex-1">
          <p className="text-xl font-semibold my-4">Documents</p>
          <DocumentsTable userId={userId} />
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
