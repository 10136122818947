import React, { useState } from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import Button from '@/components/common/Button';
import { useLocation } from 'react-router-dom';
import { postUserNote } from '@/service/user.service';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';
import ToggleInput from '@/components/common/ToggleInput';

const AddNoteToUserModal: React.FC<ModalProps> = ({ open, onClose }) => {
  const currentTheme = useContext(CurrentThemeContext);
  const [subject, setSubject] = useState('');
  const [note, setNote] = useState('');
  const location = useLocation();
  const userId = location.pathname.split('/')[2];

  const handleConfirm = async () => {
    try {
      await postUserNote(userId, subject, note);
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose} smallScreen={true}>
        <div className=" flex-col mx-auto max-w-[583px] px-5 pt-5">
          <h2 className="text-title mb-8 font-bold px-3">Add a note</h2>
          <ToggleInput
            editable={true}
            placeholder={'Subject'}
            onChangeValue={(val) => setSubject(val)}
            value={subject}
            className="w-full text-sm placeholder:text-secondary focus:outline-0 relative z-10 bg-transparent self-center mb-5"
          />
          <ToggleInput
            editable={true}
            placeholder={'Note'}
            onChangeValue={(val) => setNote(val)}
            isMultiline={true}
            value={note}
            className="w-full text-sm placeholder:text-secondary focus:outline-0 relative z-10 bg-transparent self-center break-words"
          />
          <div className="flex justify-end mt-10 w-full">
            <Button
              variant="secondary"
              onClick={() => onClose()}
              className="h-50 min-w-[20px] font-normal text-sm mr-5 bg-[#EFF0F6] !border-none">
              Cancel
            </Button>
            <button
              onClick={() => handleConfirm()}
              disabled={subject === '' || note === ''}
              className={`disabled:text-white disabled:opacity-50 disabled:cursor-not-allowed min-w-[160px] rounded-3xl font-semibold h-[50px] px-5 flex items-center justify-center hover:shadow-button text-white ${
                currentTheme.theme === 'YELLOWAFRICA'
                  ? 'bg-beautiful-yellow-africa'
                  : 'bg-beautiful'
              } disabled:bg-grey active:shadow-activeButton active:bg-primary`}>
              Confirm
            </button>
          </div>
          <pre className="block text-xs py-3 text-red-500 text-center"></pre>
        </div>
      </Modal>
    </>
  );
};

export default AddNoteToUserModal;
