import React from 'react';
import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/base';
import clsx from 'clsx';
import './style.css';

type Props = ButtonUnstyledProps & {
  variant?: 'primary' | 'secondary' | 'bg-beautiful';
  isDisabled?: boolean;
};

const Button: React.FC<Props> = ({
  className,
  variant = 'primary',
  isDisabled,
  ...rest
}) => {
  return (
    <ButtonUnstyled
      disabled={isDisabled}
      className={clsx('button disabled:!bg-grey-disabled', variant, className)}
      {...rest}
    />
  );
};

export default Button;
