import React, { useState, useEffect } from 'react';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import TeamTable from '@/components/widgets/tables/TeamTable';
import PersonalToolbar from '@/components/widgets/sections/PersonalInformation/PersonalToolbar';
import ToggleInput from '@/components/common/ToggleInput';
import ForgotPasswordModal from '@/components/widgets/modal/ForgotPasswordModal';
import DeleteAccountModal from '@/components/widgets/modal/DeleteAccountModal';
import { useAppDispatch, useAppSelector } from '@/store';
import { getEmployeeInfo, employeeInfoSelector } from '@/store/employee';
import dayjs from 'dayjs';

const Profile = () => {
  const dispatch = useAppDispatch();

  const [editable, setEditable] = useState(false);
  const [isShowResetPasswordModal, setIsShowPasswordModal] = useState(false);
  const [isShowDeleteAccountModal, setIsShowDeleteAccountModal] =
    useState(false);
  const employeeInfo = useAppSelector(employeeInfoSelector);

  useEffect(() => {
    const info = window.localStorage.getItem('dd_account');
    if (info) {
      const { id } = JSON.parse(info);
      dispatch(getEmployeeInfo(id));
    }
  }, []);

  const personalData = [
    {
      label: 'First Name',
      value: employeeInfo?.firstName ?? ''
    },
    {
      label: 'Last Name',
      value: employeeInfo?.lastName ?? ''
    },
    {
      label: 'Email address',
      value: employeeInfo?.email ?? ''
    },
    {
      label: 'Phone number',
      value: employeeInfo?.phoneNumber ?? ''
    },
    {
      label: 'Birth date',
      value: employeeInfo?.birthDate
        ? dayjs(employeeInfo.birthDate.split('/').reverse().join('-')).format(
            'DD/MM/YYYY'
          )
        : ''
    },
    {
      label: 'Address',
      value: employeeInfo?.address ?? ''
    },
    {
      label: 'City',
      value: employeeInfo?.city ?? ''
    },
    {
      label: 'Department',
      value: employeeInfo?.department ?? ''
    }
  ];

  return (
    <div className="py-2 mx-6">
      <div className="shadow-paper bg-white rounded-xl py-5 px-8 mb-[50px]">
        <PersonalToolbar
          id={employeeInfo?.employeeId}
          initialUserName=""
          name={`${employeeInfo?.firstName} ${employeeInfo?.lastName}`}
          status={employeeInfo?.status ?? ''}
          title={employeeInfo?.employeeRole ?? 'Test User'}
          fromEmployee={true}
        />

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-6">
          {personalData.map((it, id) => (
            <ToggleInput
              key={id}
              label={it.label}
              value={it.value ?? ''}
              editable={editable}
            />
          ))}
        </div>

        {editable && (
          <div className="flex justify-between">
            <span
              className="text-sm text-primary font-medium cursor-pointer"
              onClick={() => setIsShowPasswordModal(true)}>
              Change password
            </span>
            <div
              className="flex items-center"
              onClick={() => setIsShowDeleteAccountModal(true)}>
              <span className="mr-1.5 text-sm text-error font-medium cursor-pointer">
                Delete profile
              </span>
              <Icon name="delete" />
            </div>
          </div>
        )}
      </div>
      <TeamTable />

      <DeleteAccountModal
        open={isShowDeleteAccountModal}
        onClose={() => setIsShowDeleteAccountModal(false)}
      />
      <ForgotPasswordModal
        open={isShowResetPasswordModal}
        onClose={() => setIsShowPasswordModal(false)}
      />
    </div>
  );
};

export default Profile;
