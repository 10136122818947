import React, { ChangeEvent } from 'react';
import Input from '@/components/common/Input';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ReactCountryFlag from 'react-country-flag';
import lookup from 'country-code-lookup';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
  label?: string;
  value: string | number | number[] | boolean;
  regex?: RegExp;
  isWrongFormat?: boolean;
  country?: string | undefined;
  userId?: string | number;
  placeholder?: string;
  editable?: boolean;
  isMultiline?: boolean;
  onChangeValue?: (value: string) => void;
  className?: string;
};

const ToggleInput: React.FC<Props> = ({
  label,
  country,
  userId,
  value,
  regex,
  isWrongFormat,
  editable,
  placeholder,
  isMultiline,
  onChangeValue,
  className
}) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (regex) {
      const val = e.target.value;
      if (val !== '' && !regex?.test(val)) {
        return;
      }
      onChangeValue?.(val);
    } else {
      onChangeValue?.(e.target.value);
    }
  };

  return (
    <div
      className={clsx(
        className,
        'flex mb-2 gap-1.5 bg-slate-200 bg-opacity-10',
        editable ? 'flex-col' : ''
      )}>
      {editable ? (
        <TextField
          id="filled-input"
          label={label}
          value={value || ''}
          multiline={isMultiline}
          rows={isMultiline ? 4 : 1}
          placeholder={placeholder}
          onChange={handleChangeInput}
          className={`w-full rounded-xl truncate text-left ${
            !isWrongFormat ? 'bg-greyscale-greyishWhite' : 'bg-errorscale-light'
          }`}
          sx={{
            '& label': {
              left: '0.7rem',
              top: '0.3rem'
            },
            '& .MuiInputBase-multiline': {
              left: '0.7rem',
              top: '0.3rem'
            }
          }}
          InputProps={{
            disableUnderline: true,
            className: !label ? 'p-3' : 'p-1',
            startAdornment: (
              <InputAdornment position="start">
                {country && lookup?.byCountry(country)?.iso2 && (
                  <ReactCountryFlag
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    countryCode={lookup.byCountry(country).iso2}
                    style={{
                      width: '1.25rem',
                      height: '1.25rem',
                      borderRadius: '50%',
                      marginLeft: '0.7rem'
                    }}
                    svg
                  />
                )}
              </InputAdornment>
            )
          }}
          variant="standard"
        />
      ) : (
        <TextField
          label={label}
          value={value || ''}
          multiline={isMultiline}
          rows={isMultiline ? 5 : 1}
          className="w-full rounded-xl truncate text-left bg-greyscale-offWhite"
          sx={{
            '& label': {
              left: '0.7rem',
              top: '0.3rem'
            },
            '& label.Mui-focused': {
              color: '#636365'
            },
            '& .MuiInputBase-multiline': {
              left: '0.2rem',
              top: '0.3rem'
            }
          }}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            className: 'p-1',
            startAdornment: (
              <InputAdornment position="start">
                {country && lookup?.byCountry(country)?.iso2 && (
                  <ReactCountryFlag
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    countryCode={lookup.byCountry(country).iso2}
                    style={{
                      width: '1.25rem',
                      height: '1.25rem',
                      borderRadius: '50%',
                      marginLeft: '0.7rem'
                    }}
                    svg
                  />
                )}
              </InputAdornment>
            )
          }}
          variant="standard"
        />
      )}
    </div>
  );
};

export default ToggleInput;
