import { createAsyncThunk } from '@reduxjs/toolkit';
import * as dashboardApi from '@/service/dashboard.service';
import { AxiosError } from 'axios';
import { GetDashboardRequest } from '@/type/api';
import {
  UserInformation,
  FinanceInformation,
  UserGeography,
  SubscriptionPlan,
  NonCustodialWallet,
  CoinsByHolders
} from '@/type/dashboard';

// Async actions
export const getDashboard = createAsyncThunk(
  'dashboard/get',
  async (data: any, { rejectWithValue }): Promise<any> => {
    return dashboardApi
      .getDashboard()
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error?.message || 'Failed');
      });
  }
);

export const getUserInformation = createAsyncThunk(
  'dashboard/getUserInformation',
  async (
    data: GetDashboardRequest,
    { rejectWithValue }
  ): Promise<UserInformation | any> => {
    return dashboardApi
      .getUserInformation(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error?.message || 'Failed');
      });
  }
);

export const getFinanceInformation = createAsyncThunk(
  'dashboard/getFinanceInformation',
  async (
    data: GetDashboardRequest,
    { rejectWithValue }
  ): Promise<FinanceInformation | any> => {
    return dashboardApi
      .getFinanceInformation(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error?.message || 'Failed');
      });
  }
);

export const getUserGeography = createAsyncThunk(
  'dashboard/getUserGeography',
  async (
    data: GetDashboardRequest,
    { rejectWithValue }
  ): Promise<UserGeography | any> => {
    return dashboardApi
      .getUserGeography(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error?.message || 'Failed');
      });
  }
);

export const getSubscriptionPlans = createAsyncThunk(
  'dashboard/getSubscriptionPlans',
  async (
    data: GetDashboardRequest,
    { rejectWithValue }
  ): Promise<SubscriptionPlan | any> => {
    return dashboardApi
      .getSubscriptionPlans(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error?.message || 'Failed');
      });
  }
);

export const getNonCustodialWallets = createAsyncThunk(
  'dashboard/getNonCustodialWallets',
  async (
    data: GetDashboardRequest,
    { rejectWithValue }
  ): Promise<NonCustodialWallet | any> => {
    return dashboardApi
      .getNonCustodialWallets(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error?.message || 'Failed');
      });
  }
);

export const getCoinsByHolders = createAsyncThunk(
  'dashboard/getCoinsByHolders',
  async (
    data: GetDashboardRequest,
    { rejectWithValue }
  ): Promise<CoinsByHolders | any> => {
    return dashboardApi
      .getCoinsByHolders(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error?.message || 'Failed');
      });
  }
);
