import React, { useState, useEffect, useMemo } from 'react';
import { Navigate, Outlet, OutletProps } from 'react-router-dom';
import Sidebar from '@/components/layouts/DefaultLayout/Sidebar';
import ResetPasswordModal from '@/components/widgets/modal/ResetPasswordModal';
import { useResponsive } from '@/utils/hook/useResponsive';
import clsx from 'clsx';
import { useAppSelector } from '@/store';
import { isSignedInSelector } from '@/store/auth';

const DefaultLayout: React.FC<OutletProps> = (props) => {
  const isSigned = useAppSelector(isSignedInSelector);

  const [collapsed, setCollapsed] = useState(false);
  const [isShowResetPasswordModal, setIsShowResetPasswordModal] =
    useState(false);
  const user = useMemo(() => {
    const account = localStorage.getItem('dd_account');
    if (account) return JSON.parse(account);
    return null;
  }, []);

  const { isTablet } = useResponsive();

  useEffect(() => {
    setCollapsed(isTablet);
  }, [isTablet]);

  useEffect(() => {
    if (user) {
      if (
        !user.hasLoggedIn &&
        localStorage.getItem('dd_first_login') !== 'true'
      ) {
        setIsShowResetPasswordModal(true);
      }
    }
  }, []);

  const handleCloseResetPasswordModal = () => {
    // TODO:
    localStorage.setItem('dd_first_login', 'true');
    setIsShowResetPasswordModal(false);
  };

  return !isSigned ? (
    <Navigate to="/login"></Navigate>
  ) : (
    <div className="flex min-h-[100vh] relative">
      <Sidebar
        isTablet={isTablet}
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      />
      <div
        className={clsx(
          'flex-1 duration-300 overflow-hidden',
          !isTablet && !collapsed ? 'ml-[210px]' : 'ml-[70px]'
        )}>
        <Outlet {...props}></Outlet>
      </div>

      <ResetPasswordModal
        open={isShowResetPasswordModal}
        resetPasswordToken={user.resetPasswordToken}
        onClose={handleCloseResetPasswordModal}
      />
    </div>
  );
};

export default DefaultLayout;
