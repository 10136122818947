import Modal, { ModalProps } from '@/components/common/Modal';
import Button from '@/components/common/Button';
import React, { useMemo, useRef } from 'react';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';

type Props = Partial<ModalProps> & {
  deactivateUser: () => void;
  setUserStatus: any;
};

const DeactivateUserModal: React.FC<Props> = ({
  open = true,
  onClose = () => null,
  deactivateUser,
  setUserStatus
}) => {
  const currentTheme = useContext(CurrentThemeContext);
  const logError = useRef(null);

  const handleDeactivate = async () => {
    const logErr: any = logError.current;
    try {
      await deactivateUser();
      setUserStatus('Closed By Agent(Not Active)');
      onClose();
    } catch (err: any) {
      logErr.innerHTML =
        'You do not have the right permissions to deactivate users.';
    }
  };

  return (
    <Modal open={open} onClose={onClose} smallScreen={true}>
      <div className="mx-auto max-w-[583px]">
        <h2 className="text-title mb-5 font-bold px-3">
          Are you sure to deactivate this user?
        </h2>
        <p className="bg-[#FFF8E9] text-[#946300] rounded-xl px-5 py-7 font-semibold text-xs">
          The user should have no balance in his SendCrypto wallet to be
          effective.
        </p>
        <div className="flex justify-end mt-10 mb-3 w-full">
          <Button
            variant="secondary"
            onClick={() => handleDeactivate()}
            className="h-50 min-w-[20px] font-semibold text-sm mr-5 bg-[#EFF0F6] !border-none">
            Yes, deactivate
          </Button>
          <button
            onClick={() => onClose()}
            className={`disabled:text-white disabled:opacity-50 disabled:cursor-not-allowed min-w-[80px] rounded-3xl h-[50px] px-5 flex items-center justify-center hover:shadow-button text-white ${
              currentTheme.theme === 'YELLOWAFRICA'
                ? 'bg-beautiful-yellow-africa'
                : 'bg-beautiful'
            } disabled:bg-grey active:shadow-activeButton active:bg-primary`}>
            Cancel
          </button>
        </div>
        <p
          ref={logError}
          className="block text-xs text-errorscale-dark text-center"></p>
      </div>
    </Modal>
  );
};

export default DeactivateUserModal;
