import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetUserRequest,
  GetUserResponse,
  UserFilterDataResponse,
  GetTransactionResponse,
  GetTransactionRequest,
  TransactionFilterDataResponse
} from '@/type/api';
import * as userApi from '@/service/user.service';
import { AxiosError } from 'axios';

// Async actions
export const getUserFilterData = createAsyncThunk(
  'user/getFilterData',
  async (
    data: undefined,
    { rejectWithValue }
  ): Promise<UserFilterDataResponse | any> => {
    return userApi
      .getUserFilterData()
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error.message);
      });
  }
);

export const getUsers = createAsyncThunk(
  'user/getUsers',
  async (
    data: GetUserRequest,
    { rejectWithValue }
  ): Promise<GetUserResponse | any> => {
    return userApi
      .getUsers(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        rejectWithValue(error.message);
      });
  }
);

export const getTransactionFilterData = createAsyncThunk(
  'user/getTransactionFilterData',
  async (
    data: string | undefined,
    { rejectWithValue }
  ): Promise<TransactionFilterDataResponse | any> => {
    return userApi
      .getTransactionFilters(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        return rejectWithValue(error.message);
      });
  }
);

export const getTransactions = createAsyncThunk(
  'user/getTransactions',
  async (
    data: GetTransactionRequest,
    { rejectWithValue }
  ): Promise<GetTransactionResponse | any> => {
    return userApi
      .getTransactions(data)
      .then((response) => response)
      .catch((error: AxiosError) => {
        rejectWithValue(error.message);
      });
  }
);
