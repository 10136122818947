import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import DropdownFilter from '@/components/widgets/filters/DropdownFilter';
import DateTimeFilter from '@/components/widgets/filters/DateTimeFilter';
import FilterContainer from '@/components/widgets/filters/FilterContainer';
import Button from '@/components/common/Button';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getTransactionFilterData,
  getTransactions,
  transactionFilterDataSelector
} from '@/store/user';
import Loader from '@/components/widgets/loaders/Loader';
import { optimizeObject } from '@/utils/object';
import { getFilterOptions, saveFilterOptions } from '@/utils/filter';

const FilterTransaction = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const filterData = useAppSelector(transactionFilterDataSelector);

  const savedOption = getFilterOptions('transaction');

  const [coin, setCoin] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [networkType, setNetworkType] = useState<string[]>([]);
  const [transactionDate, setTransactionDate] = useState(() => {
    if (savedOption?.from && savedOption?.to) {
      return {
        startDate: savedOption.from,
        endDate: savedOption.to
      };
    } else {
      return {
        startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      };
    }
  });
  const [operationType, setOperationType] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        setLoading(true);
        await dispatch(getTransactionFilterData(params?.id));
      } finally {
        setLoading(false);
      }
    };

    fetchFilterData();
  }, []);

  const coinOptions = useMemo(() => {
    if (filterData?.coinsDDLData) {
      const options = filterData.coinsDDLData.map((item) => ({
        label: item.coinName,
        value: item.coinName
      }));

      const savedOption = getFilterOptions('transaction');
      if (savedOption?.cryptocurrencyType) {
        setCoin([savedOption.cryptocurrencyType]);
      } else {
        setCoin([options[0].value]);
      }

      return options;
    }
    return [];
  }, [filterData]);

  const operationTypeOptions = useMemo(() => {
    if (filterData?.operationTypeDDLData) {
      const options = filterData.operationTypeDDLData
        .filter((it) => it.operationTypes)
        .map((item) => ({
          label: item.operationTypes,
          value: item.operationTypes
        }));

      const savedOption = getFilterOptions('transaction');
      if (savedOption?.transactionType) {
        setOperationType([savedOption.transactionType]);
      } else {
        setOperationType([options[0].value]);
      }

      return options;
    }
    return [];
  }, [filterData]);

  const transactionStatusOptions = useMemo(() => {
    if (filterData?.transactionStatusDDLData) {
      const options = filterData.transactionStatusDDLData.map((item) => ({
        label: item.tranStatus,
        value: item.tranStatus
      }));

      const savedOption = getFilterOptions('transaction');
      if (savedOption?.status) {
        setStatus([savedOption.status]);
      } else {
        setStatus([options[0].value]);
      }

      return options;
    }
    return [];
  }, [filterData]);

  const transactionNetworkOptions = useMemo(() => {
    if (filterData?.networkTypeDDLData) {
      const options = filterData.networkTypeDDLData.map((item) => ({
        label: item.networkType,
        value: item.networkType
      }));

      const savedOption = getFilterOptions('transaction');
      if (savedOption?.networkType) {
        setNetworkType([savedOption.networkType]);
      } else {
        setNetworkType([options[0].value]);
      }

      return options;
    }
    return [];
  }, [filterData]);

  const onClear = () => {
    setStatus(['All Statuses']);
    setCoin(['All Coins']);
    setNetworkType(['All Types']);
    setOperationType(['All Operation Types']);
  };

  const onApply = () => {
    const filter = {
      from: transactionDate.startDate,
      to: transactionDate.endDate,
      status: status[0],
      cryptocurrencyType: coin.join(','),
      customerId: params?.id,
      transactionType: operationType[0],
      networkType: networkType[0]
    };

    saveFilterOptions(filter, 'transaction');

    dispatch(getTransactions(optimizeObject(filter)));
  };

  useEffect(() => {
    if (filterData) {
      onApply();
    }
  }, [filterData]);

  return (
    <div className="shadow-paper bg-white rounded-xl pl-2.5 pr-4 py-6 mb-4">
      <h3 className="text-subtitle font-medium mb-4">Transaction Filters</h3>
      {loading ? (
        <Loader />
      ) : (
        <>
          <FilterContainer>
            <DropdownFilter
              options={coinOptions}
              label="All Coins"
              placeholder="Search for coin"
              selectedValue={coin ? coin : ['All Coins']}
              onChangeFilter={setCoin}
              searchable
              multi={true}
            />
            <DropdownFilter
              options={transactionStatusOptions}
              label="All Statuses"
              selectedValue={status}
              onChangeFilter={setStatus}
            />
            <DropdownFilter
              options={transactionNetworkOptions}
              label="All Network Types"
              selectedValue={networkType}
              onChangeFilter={setNetworkType}
            />
            <DateTimeFilter
              label="Transaction date"
              date={transactionDate}
              setDate={setTransactionDate}
            />
            <DropdownFilter
              options={operationTypeOptions}
              label="All Operation Types"
              selectedValue={operationType}
              onChangeFilter={setOperationType}
            />
          </FilterContainer>
        </>
      )}
      <div className="flex justify-end mt-6 gap-2">
        <Button variant="secondary" onClick={onClear}>
          Clear filters
        </Button>
        <Button disabled={loading} onClick={onApply}>
          Apply filters
        </Button>
      </div>
    </div>
  );
};

export default FilterTransaction;
