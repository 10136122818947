import React from 'react';
import Icon from '@/components/common/Icon';
import DraggableWrapper from '@/components/common/DraggableWrapper';
import type { WidgetCardType } from '@/type/dashboard';
import { toNormalizedNumber, showChanges } from '@/utils/string';
import CardMenu from '@/components/widgets/menus/CardMenu';
import clsx from 'clsx';
import './style.css';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from "react";

type Props = {
  data: WidgetCardType;
  editable?: boolean;
  disabled?: boolean;
  hidden?: boolean;
};

const WidgetCard: React.FC<Props> = ({ data, disabled }) => {
  const currentTheme = useContext(CurrentThemeContext);
  // TODO: Please check DraggableWrapper can be useful in latest design and remove it. If we don't need drag and drop card functionality, please remove DraggableWrapper
  return (
    <DraggableWrapper id={data.id} index={Number(data.id)}>
      <div
        className={clsx('card', disabled && 'opacity-50 pointer-events-none')}>
        <div className="flex items-start">
          <div className="pr-4 px-3 py-5">
            <h3 className={`mb- font-medium`}>{data.title}</h3>
            <h2
              className={`mr-3 text-title font-bold whitespace-nowrap  ${
                data.title == 'Total Balance'
                  ? `font-extrabold text-transparent !bg-clip-text ${
                      currentTheme.theme === 'YELLOWAFRICA'
                        ? 'text-orange'
                        : 'text-beautiful'
                    }`
                  : ''
              }`}>
              {toNormalizedNumber(data.value, data.unit)}
            </h2>
          </div>
        </div>
      </div>
    </DraggableWrapper>
  );
};

export default WidgetCard;
