/**
 * It returns the number which is normalized currency format as string
 * @Input 1234567
 * @Output 1,234,567
 * */
export const toNormalizedNumber = (num: number | string, unit?: string) => {
  let value = ('' + num).replace(
    /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
    function (m, s1, s2) {
      return s2 || s1 + ',';
    }
  );

  if (unit && unit === 'currency') value = '$' + ' ' + value;
  if (unit && unit !== 'currency') value = value + ' ' + unit;

  return value;
};

export const eToNumber = (num: any): any => {
  let sign = '';
  (num += '').charAt(0) == '-' && ((num = num.substring(1)), (sign = '-'));
  const arr = num.split(/[e]/gi);
  if (arr.length < 2) return sign + num;
  let n = arr[0];
  const dot = (0.1).toLocaleString().substr(1, 1);
  const exp = +arr[1];
  let w = (n = n.replace(/^0+/, '')).replace(dot, '');
  const pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp;
  const L = pos - w.length;
  const s = '' + BigInt(w);
  w =
    exp >= 0
      ? L >= 0
        ? s + '0'.repeat(L)
        : r()
      : pos <= 0
      ? '0' + dot + '0'.repeat(Math.abs(pos)) + s
      : r();
  const p = w.split(dot);
  if ((p[0] == 0 && p[1] == 0) || (+w == 0 && +s == 0)) w = 0; //** added 9/10/2021
  return sign + w;
  function r() {
    return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`);
  }
}

/**
 * It returns the changes with plus or minus and unit
 * @Input -12, 34
 * @Output -12$, +34$
 * */
export const showChanges = (changes: number, unit = '') => {
  let prefix = '+';
  if (changes === 0) prefix = '';
  if (changes < 0) prefix = '-';

  return `${prefix} ${Math.abs(changes)}${unit}`;
};

/**
 * It returns two digits number
 * @Input 1, 2, 10, 15
 * @Output 01, 02, 10, 15
 * */
export const dateNumber = (value: string | number) => {
  const temp = '0' + value;
  return temp.slice(temp.length - 2, temp.length);
};
