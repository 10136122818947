import React from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import Button from '@/components/common/Button';

const ResetSuccessModal: React.FC<ModalProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="mx-auto max-w-[583px]">
        <h2 className="text-title mb-5 font-semibold text-center">
          Password successfully sent
        </h2>
        <p className="text-lg text-center leading-6 mx-auto max-w-[414px] mb-[160px]">
          New password has been sent successfuly by email. You can check your
          email and use it.
        </p>

        <div className="flex justify-center gap-5">
          <Button className="max-w-[217px]" variant="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ResetSuccessModal;
