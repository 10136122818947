import React, { forwardRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { styled } from '@mui/material/styles';
import {
  SingleInputDateRangeField,
  SingleInputDateRangeFieldProps
} from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DateRangePickerDay as MuiDateRangePickerDay } from '@mui/x-date-pickers-pro/DateRangePickerDay';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';

type Props = {
  date: any;
  setDate: any;
  readOnly?: boolean;
  label?: string;
};

const DateRangePickerDayColor =
  window.location.href.startsWith('https://dashboard-dev.sendcrypto.com') ||
  window.location.href.startsWith('https://dashboard.sendcrypto.com')
    ? '#610BEF !important'
    : '#D5A23D !important';

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({
    theme,
    isHighlighting,
    isStartOfHighlighting,
    isEndOfHighlighting,
    outsideCurrentMonth,
    selected
  }) => ({
    ...(!outsideCurrentMonth &&
      isHighlighting && {
        borderRadius: 0,
        backgroundColor: '#EBECFE !important',
        color: theme.palette.common.white,
        ...(isStartOfHighlighting && {
          borderTopLeftRadius: '50% !important',
          backgroundColor: DateRangePickerDayColor,
          borderBottomLeftRadius: '50% !important'
        }),
        ...(isEndOfHighlighting && {
          borderTopRightRadius: '50% !important',
          backgroundColor: DateRangePickerDayColor,
          borderBottomRightRadius: '50% !important'
        }),
        ...{
          '&:hover, &:focus': {
            backgroundColor: DateRangePickerDayColor
          },
          '[class*="Mui-selected"]': {
            backgroundColor: DateRangePickerDayColor
          }
        }
      })
  })
);

type FieldComponent = (<TDate>(
  props: SingleInputDateRangeFieldProps<TDate> &
    React.RefAttributes<HTMLInputElement>
) => JSX.Element) & { fieldType?: string };

const WrappedSingleInputDateRangeField = forwardRef(function myInput(
  props: SingleInputDateRangeFieldProps<Dayjs>,
  ref: React.Ref<HTMLInputElement>
) {
  return <SingleInputDateRangeField {...props} ref={ref} />;
}) as FieldComponent;

WrappedSingleInputDateRangeField.fieldType = 'single-input';

const DateTimeFilter: React.FC<Props> = ({
  setDate,
  date,
  readOnly,
  label
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['SingleInputDateRangeField']}>
        <DateRangePicker
          onChange={(newValue) => {
            setDate({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              startDate: newValue[0].format('YYYY-MM-DD'),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              endDate: newValue[1].format('YYYY-MM-DD')
            });
          }}
          closeOnSelect={false}
          className="bg-[#EFF0F6] rounded-md w-[325px]"
          slots={{
            day: DateRangePickerDay,
            field: WrappedSingleInputDateRangeField
          }}
          sx={{ '& input': { color: '#6E7191' } }}
          slotProps={{ textField: { variant: 'outlined' } }}
          disabled={readOnly}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={[dayjs(date.startDate), dayjs(date.endDate)]}
          calendars={1}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DateTimeFilter;
