import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import CardMenu from '@/components/widgets/menus/CardMenu';
import './style.css';

type Props = PropsWithChildren & {
  disabled?: boolean;
  title?: string;
  subtitle?: string;
  description?: string;
  hidden?: boolean;
  onToggleShow?: () => void;
  noData?: boolean;
};

const Card: React.FC<Props> = ({
  disabled,
  title,
  subtitle,
  description,
  children,
  noData
}) => {
  return (
    <div
      className={clsx(
        'card relative overflow-hidden',
        disabled && 'opacity-50 pointer-events-none'
      )}>
      <div className="flex justify-between mb-2.5 content-center">
        <div className="relative">
          <div>
            <h3 className="font-medium mr-2 text-[#6E7191]">{title}</h3>
            {description && (
              <p className="text-xs text-grey-disabled mt-1.5">{description}</p>
            )}
            {subtitle && <p className="text-grey-disabled">{subtitle}</p>}
          </div>
        </div>
        <CardMenu />
      </div>

      {children}

      {noData && (
        <div className="absolute z-20 top-0 bottom-0 left-0 right-0 bg-opacity-white">
          <div className="w-full h-full flex items-center justify-center bg-opacity-black">
            <span className="text-lg font-bold">No data</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
