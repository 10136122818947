import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  title?: string;
  subtitle?: string;
};

const NormalSection: React.FC<Props> = ({ title, subtitle, children }) => {
  return (
    <div className="mb-6">
      <div className="flex justify-between items-start mb-4 px-2">
        <div className="flex-1">
          <h2 className="text-title font-medium mb-2.5">{title}</h2>
          <p className="text-grey text-sm">{subtitle}</p>
        </div>
      </div>
      {children}
    </div>
  );
};

export default NormalSection;
