import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import './index.css';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { ReactNotifications } from 'react-notifications-component';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const currentTheme =
  window.location.href.startsWith('https://dashboard-dev.sendcrypto.com') ||
  window.location.href.startsWith('https://dashboard.sendcrypto.com')
    ? 'SENDCRYPTO'
    : 'YELLOWAFRICA';

document.body.classList.add(`theme-${currentTheme}`);

root.render(
  <CurrentThemeContext.Provider
    value={{
      theme: currentTheme
    }}>
    <BrowserRouter>
      <Provider store={store}>
        <ReactNotifications />
        <App />
      </Provider>
    </BrowserRouter>
  </CurrentThemeContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
