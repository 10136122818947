import React from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import Input from '@/components/common/Input';
import Button from '@/components/common/Button';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required')
});

const initialValue = {
  password: ''
};

const DeleteAccountModal: React.FC<ModalProps & { onSubmit?: () => void }> = ({
  open,
  onClose,
  onSubmit
}) => {
  const handleSubmit = () => {
    onSubmit?.();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="mx-auto max-w-[583px]">
        <h2 className="text-title mb-5 font-semibold text-center">
          Deleting an account
        </h2>
        <p className="text-lg text-center leading-6 mx-auto max-w-[414px] mb-[50px]">
          Please enter your password to permanently delete your back office
          account.
        </p>

        <Formik
          initialValues={initialValue}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}>
          {({ errors, touched, values, handleChange, handleBlur, isValid }) => {
            return (
              <Form>
                <div className="mb-[102px]">
                  <p className="font-semibold mb-3 leading-[18px]">Password</p>
                  <Input
                    placeholder="Enter here"
                    name="password"
                    type="password"
                    error={touched.password && !!errors.password}
                    success={!!values.password && isValid}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="flex justify-center gap-5">
                  <Button
                    className="max-w-[217px]"
                    variant="secondary"
                    onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isValid}
                    className="max-w-[217px]">
                    Delete
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
