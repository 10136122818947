import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { slice as authSlice } from './auth';
import { slice as userSlice } from './user';
import { slice as employeeSlice } from './employee';
import { slice as dashboardSlice } from './dashboard';

const reducer = combineReducers({
  auth: authSlice.reducer,
  user: userSlice.reducer,
  employee: employeeSlice.reducer,
  dashboard: dashboardSlice.reducer
});

export const store = configureStore({
  reducer
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
