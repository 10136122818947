import React, { ChangeEvent, useState, useMemo } from 'react';
import Icon from '@/components/common/Icon';
import IconButton from '@/components/common/IconButton';
import MuiSwitch from '@/components/common/Switch';
import ToggleInput from '@/components/common/ToggleInput';
import { EmployeeDetails as EmployeeDetailsType } from '@/type/employee';
import { putUserInfo, deactivateUser } from '@/service/user.service';
import EditEmployeeDropdownFilter from '@/components/widgets/filters/EditEmployeeDropdownFilter';

type Props = {
  onChangeValue?: (value: string) => void;
  initialEmployeeName: string;
  avatar?: string;
  data: EmployeeDetailsType;
  setData: any;
  id: any;
  name: string;
  status: string;
  title: string;
  fromEmployee: boolean;
  departmentOptions: any;
  employeeManagerOptions: any;
  employeeTypeOptions: any;
  employeeRoleOptions: any;
  isEditScreen: boolean;
};

const SendCryptoInformation: React.FC<Props> = ({
  onChangeValue,
  initialEmployeeName,
  departmentOptions,
  employeeManagerOptions,
  employeeTypeOptions,
  employeeRoleOptions,
  id,
  data,
  setData,
  name,
  isEditScreen
}) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeValue?.(e.target.value);
  };

  const editableContactFields: Array<{
    field: keyof EmployeeDetailsType;
    label: string;
  }> = [
    {
      field: 'employeeRole',
      label: 'Role'
    },
    {
      field: 'isRemote',
      label: 'Remote'
    },
    {
      field: 'department',
      label: 'Department'
    },
    {
      field: 'managerName',
      label: 'Manager Name'
    },
    {
      field: 'employeeType',
      label: 'Employee Type'
    }
  ];

  const getDropdownOptions = (label: string) => {
    switch (label) {
      case 'employeeRole':
        return employeeRoleOptions;
      case 'department':
        return departmentOptions;
      case 'employeeType':
        return employeeTypeOptions;
      case 'managerName':
        return employeeManagerOptions;
      default:
        return [];
    }
  };

  const getSelectedDropdownValue = (label: string) => {
    switch (label) {
      case 'employeeRole':
        return data.employeeRole;
      case 'department':
        return data.department;
      case 'employeeType':
        return data.employeeType;
      case 'managerName':
        return data.managerName;
      default:
        return '';
    }
  };

  return (
    // If this is the personal toolbar from an employee's profile.
    <>
      <div className="grid grid-cols-2 gap-2">
        {editableContactFields.map((item, index) => (
          <div key={index}>
            {item.field === 'isRemote' ? (
              <div className="flex justify-between">
                <div className="label">Remote</div>
                <MuiSwitch
                  isDisabled={!isEditScreen}
                  className={`${
                    isEditScreen ? 'isEditScreen' : 'isNotEditScreen'
                  }`}
                  onClick={() => {
                    setData({
                      ...data,
                      isRemote: data.isRemote === 'No' ? 'Yes' : 'No'
                    });
                  }}
                  isChecked={data['isRemote'] === 'Yes' ? true : false}
                />
              </div>
            ) : (
              <div className="form-row-input">
                <EditEmployeeDropdownFilter
                  options={getDropdownOptions(item.field)}
                  label={item.label}
                  isDisabled={!isEditScreen}
                  selectedValue={getSelectedDropdownValue(item.field)}
                  searchable={false}
                  onChangeFilter={(newVal) => {
                    setData({ ...data, [item.field]: newVal });
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default SendCryptoInformation;
