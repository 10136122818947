import React from 'react';
import Icon from '@/components/common/Icon';
import Coin from '@/components/common/Coin';
import Table from '@/components/common/Table';
import Status from '@/components/common/Status';
import { TableColumn } from '@/type/table';
import { commonDateFormat } from '@/utils/date';
import { useAppSelector } from '@/store';
import { transactionsSelector } from '@/store/user';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

const SUCCESS_VALUES = [
  'completed',
  'success - revert',
  'success - completed',
  'true',
  'registered'
];

const transactionColumns: TableColumn[] = [
  {
    field: 'transactionsId',
    label: 'ID',
    cellClassName: 'w-[70px]',
    tooltip: true
  },
  {
    field: 'fromCustomer',
    label: 'From',
    cellClassName: 'capitalize',
    tooltip: true,
    render: (row: any) => (
      <Link
        to={`/user/${row.row.fromCustomerId}`}
        className="text-sm text-left text-ctascale-lightMain">
        {row.value}
      </Link>
    )
  },
  {
    field: 'toCustomer',
    label: 'To',
    cellClassName: 'capitalize',
    tooltip: true,
    render: (row: any) => (
      <Link
        to={`/user/${row.row.toCustomerId}`}
        className="text-sm text-left text-ctascale-lightMain">
        {row.value}
      </Link>
    )
  },
  {
    field: 'sendWalletId',
    label: 'From Wallet',
    cellClassName: 'capitalize',
    tooltip: true
  },
  {
    field: 'receivedWalletId',
    label: 'To Wallet',
    cellClassName: 'capitalize',
    tooltip: true
  },
  {
    field: 'transactionStatus',
    label: 'Status',
    render: (row: any) => (
      <div className="bg-[#EFF0F6] rounded-xl p-2">
        {!SUCCESS_VALUES.includes(row.value.toLowerCase()) ? (
          <CloseIcon style={{ color: '#DD2590', height: '18' }} />
        ) : (
          <CheckIcon style={{ color: '#60B527', height: '18' }} />
        )}
        {row.value}
      </div>
    )
  },
  {
    field: 'transactionType',
    label: 'Trx type',
    cellClassName: '!py-2',
    render: (row: any) => (
      <div className="bg-[#EFF0F6] rounded-xl p-2">
        {!(row.value.toLowerCase() === 'deposit') ? (
          <ArrowUpwardOutlinedIcon style={{ color: '#7A5AF8', height: '18' }} />
        ) : (
          <ArrowDownwardOutlinedIcon
            style={{ color: '#60B527', height: '18' }}
          />
        )}
        {row.value}
      </div>
    )
  },
  {
    field: 'cryptoCurrencyType',
    label: 'Coin',
    render: (data: any) => (
      <div className="flex items-center justify-center min-w-16">
        <Coin name={data.value} />
      </div>
    )
  },
  {
    field: 'amountCrypto',
    label: 'Amount'
  },
  {
    field: 'amountUSD',
    label: 'Amount USD'
  },
  {
    field: 'transactionDate',
    label: 'Transaction date',
    render: (data: any) => <>{commonDateFormat(data.value, true)}</>
  },
  {
    field: 'transactionNetworkType',
    label: 'Network type',
    render: (data: any) => (
      <div
        className={`rounded-xl p-2 ${
          data.value === 'internal' ? 'bg-[#EBECFE]' : 'bg-[#E3FEFF]'
        }`}>
        {data.value}
      </div>
    )
  },
  {
    field: 'transactionApprovedDate',
    label: 'Approved date',
    render: (data: any) => <>{commonDateFormat(data.value, true)}</>
  }
];

type Props = {
  fromUserPage?: boolean;
  setGridReference?: any;
};

const TransactionTable: React.FC<Props> = ({
  fromUserPage,
  setGridReference
}) => {
  const transactions = useAppSelector(transactionsSelector);
  return fromUserPage ? (
    <div className="bg-white rounded-xl">
      <Table
        pagination
        columns={transactionColumns}
        data={transactions ?? []}
      />
    </div>
  ) : (
    <div className="rounded-xl ">
      <Table
        pagination
        setGridReference={setGridReference}
        columns={transactionColumns}
        data={transactions ?? []}
      />
    </div>
  );
};

export default TransactionTable;
