import React, { useMemo } from 'react';
import { toNormalizedNumber } from '@/utils/string';
import Icon from '@/components/common/Icon';
import LineChart from '@/components/widgets/charts/LineChart';
import Card from '@/components/common/Card';
import moment from 'moment/moment';

type Props = {
  x: string[];
  y: number[];
};

const RampChartCard: React.FC<Props> = ({ x, y }) => {
  const processedX = useMemo(() => {
    return x.map((item) => moment(item).format('MMM DD'));
  }, [x]);

  const processedY = useMemo(() => {
    if (!y?.length) {
      return new Array(x.length).fill(0).map(() => 0);
    }
    return y;
  }, [y, x]);

  return (
    <Card title="On Ramp / Off Ramp" noData={!y.length}>
      <div className="flex items-center mb-4">
        <h3 className="text-title font-bold text-xl">{`$ ${toNormalizedNumber(
          0
        )}`}</h3>
      </div>

      <div className="-mb-4 -mx-2">
        <LineChart data={[{ x: processedX, y: processedY }]} />
      </div>
    </Card>
  );
};

export default RampChartCard;
