import React from 'react';
import { useNavigate } from 'react-router-dom';
import ResetPasswordModal from '@/components/widgets/modal/ResetPasswordModal';

const ResetPassword = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/login');
  };

  return <ResetPasswordModal onClose={handleClose} />;
};

export default ResetPassword;
