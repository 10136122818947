import React from 'react';
import './style.css';
import lookup from 'country-code-lookup';
import ReactCountryFlag from 'react-country-flag';

export type MapPinProps = {
  visible: boolean;
  x: number;
  y: number;
  countryName?: string;
  changes?: number;
  users?: number;
};

export type Props = {
  data: MapPinProps;
};

const MapPin: React.FC<Props> = ({ data }) => {
  const { visible, x, y, countryName, changes, users } = data;

  if (!visible) return null;
  return (
    <div
      className="fixed -translate-x-1/2 -translate-y-[130%]"
      style={{ top: y, left: x }}>
      <div className="map-pin flex flex-col gap-1 justify-center content-center">
        <div className="flex">
          {countryName && lookup?.byCountry(countryName)?.iso2 && (
            <ReactCountryFlag
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              countryCode={lookup.byCountry(countryName).iso2}
              style={{
                width: '1.25rem',
                height: '1.25rem',
                borderRadius: '50%',
                marginLeft: '0.7rem'
              }}
              svg
            />
          )}
          <p className="text-md ml-2 font-light">{countryName}</p>
        </div>
        <p className="text-md font-bold mb-2">{users}</p>
      </div>
    </div>
  );
};

export default MapPin;
