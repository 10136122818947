import React from 'react';

const Wallets = () => {
  return (
    <div className="py-2">
      <div className="flex justify-between items-start mb-9">
        <div className="flex-1">
          <h2 className="text-heading font-medium mb-1">Wallets</h2>
          <p className="text-grey-disabled text-sm">
            Here you can see all the wallets for this user
          </p>
        </div>
      </div>
    </div>
  );
};

export default Wallets;
