import React from 'react';
import Status from '@/components/common/Status';

export type KYCTableDataType = {
  type: string;
  status: any;
  date?: string;
};

type Props = {
  data: KYCTableDataType[];
};

const KYCTable: React.FC<Props> = ({ data }) => {
  return (
    <table className="secondary text-[#6E7191]">
      <thead className="bg-[#F7F7FC]">
        <tr>
          <th className="!p-4 w-10">KYC Type</th>
          <th className="border-b">Status</th>
          <th className="border-b">Date</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            key={index}
            className={`${
              index % 2 === 1 ? 'bg-[#F7F7FC]' : 'bg-white'
            } font-semibold`}>
            <td className="capitalize bg-[#F7F7FC] ">{item.type}</td>
            <td className="!py-1 border-b font-semibold">
              <Status
                rounded={true}
                fromKYC={true}
                status={
                  ['completed', 'success', 'true'].includes(item?.status?.toLowerCase())
                    ? 'Completed'
                    : 'Uncompleted'
                }
              />
            </td>
            <td className="border-b">{item.date || '--'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default KYCTable;
