import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { chartColor } from '@/constants/colors';
import { PieChartDataItem } from '@/type/dashboard';
import './style.css';

type Props = {
  data: PieChartDataItem[];
};

const PieChart: React.FC<Props> = ({ data }) => {
  const options = useMemo(
    () => ({
      chart: {
        type: 'pie',
        height: 200,
        width: 200,
        spacing: [0, 0, 0, 0]
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          borderWidth: 10,
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false
        }
      },
      tooltip: {
        useHTML: true,
        headerFormat: '',
        pointFormat:
          '<div class="chartTooltip"><div class="text-center mb-1">{point.name}</div><div class="text-center text-xs font-semibold">{point.percentage:.0f}%</div>',
        style: {
          color: '#000'
        },
        padding: 12,
        valueDecimals: 0,
        backgroundColor: '#fff',
        borderColor: '#fff',
        borderRadius: 16,
        shadow: true
      },
      series: [
        {
          innerSize: 110,
          size: 150,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 3,
            states: {
              hover: {
                enabled: true,
                lineWidth: 1
              }
            }
          },
          data: data.map((item, index) => ({
            y: item.amount,
            name: item.label,
            sliced: false,
            color: chartColor[index]
          }))
        }
      ]
    }),
    [data]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
