export type permissionObjectType = {
  title: string;
  description: string;
  isTrue: boolean;
  permissionCode: number;
};

export type permissionArrayObjectType = {
  userPermissions: permissionObjectType[];
  employeePermissions: permissionObjectType[];
  downloadPermissions: permissionObjectType[];
};

export const permissionArraysObject: permissionArrayObjectType = {
  userPermissions: [
    {
      title: 'Deactivate User',
      description:
        "Checking this permission will give the employee the right to deactivate a user's account",
      isTrue: false,
      permissionCode: 499
    },
    {
      title: 'Create User Note',
      description:
        "Checking this permission will give the employee the right to create a note on a user's account",
      isTrue: false,
      permissionCode: 408
    },
    {
      title: 'Update User Profile Information',
      description:
        "Checking this permission will give the employee the right to update a user's profile information",
      isTrue: false,
      permissionCode: 406
    },
    {
      title: 'View User KYC Data',
      description:
        "Checking this permission will give the employee the right to view a user's KYC data, including images of their personal documents",
      isTrue: false,
      permissionCode: 407
    }
  ],
  employeePermissions: [
    {
      title: 'Create Employee',
      description:
        'Checking this permission will give the employee the right to add new employees',
      isTrue: false,
      permissionCode: 501
    },
    {
      title: 'Edit Employee',
      description:
        'Checking this permission will give the employee the right to modify employees',
      isTrue: false,
      permissionCode: 505
    }
  ],
  downloadPermissions: [
    {
      title: 'Download Permissions',
      description: 'Checking this permission will give the employee the right to download table data as CSV files',
      isTrue: false,
      permissionCode: 601
    }
  ]
};
