import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '@/components/common/Icon';
import IconButton from '@/components/common/IconButton';
import { menu } from '@/constants/menu';
import AvatarPlaceholder from '@/assets/images/avatar-placeholder.png';
import { useAppDispatch } from '@/store';
import { logout } from '@/store/auth';
import clsx from 'clsx';
import './style.css';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';

type Props = {
  collapsed: boolean;
  isTablet: boolean;
  onCollapse: () => void;
};

const Sidebar: React.FC<Props> = ({ collapsed, isTablet, onCollapse }) => {
  const currentTheme = useContext(CurrentThemeContext);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleLink = () => {
    if (isTablet && !collapsed) onCollapse();
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const user = useMemo(() => {
    const account = localStorage.getItem('dd_account');
    if (account) return JSON.parse(account);
    return null;
  }, []);

  return (
    <div
      className={clsx(
        'fixed h-[100vh] z-50',
        isTablet && !collapsed && 'collapsed-bg'
      )}>
      <div className={clsx('sidebar', collapsed && 'collapsed')}>
        <div className="relative pb-8 overflow-hidden">
          {currentTheme.theme === 'YELLOWAFRICA' ? (
            <Link className="flex items-center" to="/">
              <img
                className="flex-shrink-0"
                src="/logo-mark-yellow-africa.svg"
                alt="logo"
              />
              <div className="flex flex-col">
                <div className="text-xl font-bold ml-2.5 whitespace-nowrap">
                  <p>YellowDot</p>
                </div>
                <div className="ml-2.5 uppercase text-xs font-medium">
                  My Experience
                </div>
              </div>
            </Link>
          ) : (
            <>
              <Link className="flex items-center" to="/">
                <img
                  className="flex-shrink-0"
                  src="/logo-mark.svg"
                  alt="logo"
                />
                <p className="text-sm font-semibold uppercase ml-2.5 whitespace-nowrap">
                  SendCrypto
                </p>
              </Link>
              <p className="absolute top-7 left-11 uppercase text-xs font-medium whitespace-nowrap">
                Back office
              </p>
            </>
          )}
        </div>

        <div
          className={clsx(
            'flex-1 pt-2 overflow-hidden',
            collapsed && 'flex flex-col'
          )}>
          {menu.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              onClick={handleLink}
              className={clsx(
                'flex items-center mb-5 hover:text-primary-light',
                {
                  ' border-r-primary border-r-4':
                    item.path === location.pathname
                }
              )}>
              <Icon
                className={clsx('flex-shrink-0', {
                  'text-primary-light font-medium':
                    item.path === location.pathname
                })}
                name={item.icon}
              />
              <p className="ml-3.5">{item.label}</p>
            </Link>
          ))}
        </div>
        <Link
          to="/profile"
          className={clsx(
            'flex items-center overflow-hidden hover:text-primary-light',
            location.pathname === '/profile' && 'text-primary-light font-medium'
          )}>
          <img
            className="flex-shrink-0"
            width={28}
            height={28}
            src={AvatarPlaceholder}
            alt="avatar"
          />
          <p className="ml-2">{user?.email}</p>
        </Link>

        <div
          className={clsx(
            'mt-4 flex items-center text-error cursor-pointer hover:text-errorscale-dark overflow-hidden',
          )}
          onClick={handleLogout}>
          <Icon name="logout" className="flex-shrink-0" />
          <span
            className={clsx(
              'font-medium ml-3 whitespace-nowrap',
              collapsed && 'hidden'
            )}>
            Log out
          </span>
        </div>

        <IconButton
          className={`${
            currentTheme.theme === 'YELLOWAFRICA'
              ? 'bg-beautiful-yellow-africa'
              : 'bg-purple'
          } collapse-button bottom-[100px]`}
          onClick={() => onCollapse()}>
          <Icon name="caret-down" className="text-white rotate-90" />
        </IconButton>
      </div>
    </div>
  );
};

export default Sidebar;
