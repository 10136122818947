import React, { useState, useRef, useEffect } from 'react';
import Modal, { ModalProps } from '@/components/common/Modal';
import Input from '@/components/common/Input';
import {
  FileUploader,
  FileUploadProps
} from '@/components/widgets/tools/FileUploader';
import Loader from '@/components/widgets/loaders/Loader';
import Button from '@/components/common/Button';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import './style.css';
import { CurrentThemeContext } from '@/provider/ThemeProvider';
import { useContext } from 'react';

const ReportModal: React.FC<ModalProps> = ({ open, onClose }) => {
  const currentTheme = useContext(CurrentThemeContext);
  const [fileList, setFileList] = useState<any>([]);
  const [imagesOfFileList, setImagesOfFileList] = useState<any>();
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTooManyFilesMessageActive, setIsTooManyFilesMessageActive] =
    useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [description, setDescription] = useState('');
  const modalImageRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      const fileListImages: any[] = [];
      await fileList.map(async (file: any, index: number) => {
        const url = await getUrl(file);
        fileListImages.push(url);
      });
      setTimeout(() => setImagesOfFileList(fileListImages), 200);
    })();
  }, [fileList]);

  const getUrl = async (file: any) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resolve(e.target.result);
      };
    });
  };

  const openImageModal = (file: any) => {
    const reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
    };
  };

  const removeFile = (name: string) => {
    // find the index of the item
    // remove the item from array

    const fileIndex = fileList.findIndex((e: any) => e.name === name);
    fileList.splice(fileIndex, 1);
    // update validFiles array
    setIsTooManyFilesMessageActive(false);
    setImagesOfFileList([]);
    setFileList([...fileList]);
  };

  const fileSize = (size: number) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleFiles = (files: any) => {
    for (let i = 0; i < files.length; i++) {
      if (fileList.length + i < 5) {
        if (validateFile(files[i])) {
          // add to an array so we can display the name of file
          setImagesOfFileList([]);
          setFileList((prevArray: any) => [...prevArray, files[i]]);
        } else {
          // add a new property called invalid
          files[i]['invalid'] = true;
          // add to the same array so we can display the name of the file
          setImagesOfFileList([]);
          setFileList((prevArray: any) => [...prevArray, files[i]]);
          // set error message
          setErrorMessage('File type not permitted');
        }
      } else {
        setIsTooManyFilesMessageActive(true);
        break;
      }
    }
  };

  const validateFile = (file: any) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon'
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const fileUploadProp: FileUploadProps = {
    accept: 'image/*',
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files?.length) {
        handleFiles(files);
      }
    },
    loading: loadingFileUpload,
    setLoadingFileUpload: setLoadingFileUpload,
    onDrop: (event: React.DragEvent<HTMLElement>) => {
      const files = event.dataTransfer.files;
      if (files.length) {
        handleFiles(files);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose} smallScreen={true}>
      <div className="mx-auto max-w-[583px]">
        <h2 className="text-title mb-5 font-semibold">Report a problem</h2>

        <div className="mb-3">
          <Input
            className="placeholder-grey-disabled mb-5 rounded-xl bg-[#EFF0F6] border-0"
            placeholder="Describe your problem"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            multiline
          />
        </div>

        <div className="bg-[#EFF0F6] p-5 mb-6 rounded-xl">
          <div className="flex flex-col w-full">
            <div className="self-center mb-6">
              {!loadingFileUpload ? (
                <FileUploader
                  {...fileUploadProp}
                  setLoadingFileUpload={setLoadingFileUpload}
                  loading={loadingFileUpload}
                />
              ) : (
                <Loader />
              )}
              {isTooManyFilesMessageActive && (
                <p className="text-errorscale-dark self-center">
                  Too many files! You can only upload up to 5.
                </p>
              )}
            </div>
            {fileList.map((data: any, i: number) => (
              <div
                className="bg-white rounded-xl flex justify-between p-3 mb-3"
                key={i}>
                <div className="flex w-2/3">
                  <img
                    src={`${imagesOfFileList[i]}`}
                    className="w-14 h-14 mr-3"
                    onClick={() => {
                      setIsImageModalOpen(true);
                      openImageModal(data);
                    }}
                  />
                  <div className="flex flex-col w-full">
                    {/* <div className="file-type">{fileType(data.name)}</div> */}
                    <div
                      className={`${
                        data.invalid ? 'text-errorscale-dark' : ''
                      } break-words`}>
                      {data.name}
                    </div>
                    <div className="file-size">
                      ({fileSize(data.size)}){' '}
                      {data.invalid && (
                        <span className="text-errorscale-dark font-bold">
                          ({errorMessage})
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="cursor-pointer text-2xl pb-2 self-center"
                  onClick={() => removeFile(data.name)}>
                  x
                </div>
              </div>
            ))}
          </div>
        </div>
        {isImageModalOpen && (
          <div className="modal" ref={modalRef}>
            <div className="overlay"></div>
            <span
              className="close cursor-pointer"
              onClick={() => {
                setIsImageModalOpen(false);
              }}>
              x
            </span>
            <div className="modal-image" ref={modalImageRef}></div>
          </div>
        )}
        <div className="flex flex-wrap justify-end gap-5">
          <Button
            variant="secondary"
            className="max-w-[217px]"
            onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className={`${
              currentTheme.theme === 'YELLOWAFRICA'
                ? 'bg-beautiful-yellow-africa'
                : 'bg-beautiful'
            }`}
            onClick={(e) => {
              console.log(fileList, description);
            }}>
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReportModal;
