import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import DropdownFilter from '@/components/widgets/filters/DropdownFilter';
import DateTimeFilter from '@/components/widgets/filters/DateTimeFilter';
import FilterContainer from '@/components/widgets/filters/FilterContainer';
import Button from '@/components/common/Button';
import { useAppDispatch, useAppSelector } from '@/store';
import {
  getTransactionFilterData,
  getTransactions,
  transactionFilterDataSelector
} from '@/store/user';
import Loader from '@/components/widgets/loaders/Loader';
import { optimizeObject } from '@/utils/object';

const FilterUserTransaction = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const filterData = useAppSelector(transactionFilterDataSelector);
  const [coin, setCoin] = useState<string[]>(['All Coins']);
  const [status, setStatus] = useState<string[]>(['All Statuses']);
  const [networkType, setNetworkType] = useState<string[]>(['All Types']);
  const [transactionDate, setTransactionDate] = useState({
    startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [operationType, setOperationType] = useState<string[]>([
    'All Operation Types'
  ]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        setLoading(true);
        await dispatch(getTransactionFilterData(params?.id));
      } finally {
        setLoading(false);
      }
    };

    fetchFilterData();
  }, [params.id]);

  const coinOptions = useMemo(() => {
    if (filterData?.coinsDDLData) {
      const options = filterData.coinsDDLData.map((item) => ({
        label: item.coinName,
        value: item.coinName
      }));
      return options;
    }
    return [];
  }, [filterData]);

  const operationTypeOptions = useMemo(() => {
    if (filterData?.operationTypeDDLData) {
      const options = filterData.operationTypeDDLData
        .filter((it) => it.operationTypes)
        .map((item) => ({
          label: item.operationTypes,
          value: item.operationTypes
        }));
      return options;
    }
    return [];
  }, [filterData]);

  const transactionStatusOptions = useMemo(() => {
    if (filterData?.transactionStatusDDLData) {
      const options = filterData.transactionStatusDDLData.map((item) => ({
        label: item.tranStatus,
        value: item.tranStatus
      }));
      return options;
    }
    return [];
  }, [filterData]);

  const transactionNetworkOptions = useMemo(() => {
    if (filterData?.networkTypeDDLData) {
      const options = filterData.networkTypeDDLData.map((item) => ({
        label: item.networkType,
        value: item.networkType
      }));
      return options;
    }
    return [];
  }, [filterData]);

  const onClear = () => {
    setStatus(['All Statuses']);
    setCoin(['All Coins']);
    setNetworkType(['All Types']);
    setOperationType(['All Operation Types']);
  };

  const onApply = () => {
    const filter = {
      from: transactionDate.startDate,
      to: transactionDate.endDate,
      status: status[0],
      cryptocurrencyType: coin[0],
      customerId: params?.id,
      transactionType: operationType[0],
      networkType: networkType[0]
    };

    dispatch(getTransactions(optimizeObject(filter)));
  };

  useEffect(() => {
    if (filterData) {
      onApply();
    }
  }, [filterData]);

  return (
    <div className="shadow-paper bg-white rounded-xl pl-2.5 pr-4 py-6 mb-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          <FilterContainer>
            <DropdownFilter
              options={coinOptions}
              label="All Coins"
              placeholder="Search for coin"
              selectedValue={coin}
              onChangeFilter={setCoin}
              searchable
            />
            <DropdownFilter
              options={transactionStatusOptions}
              label="All Statuses"
              selectedValue={status}
              onChangeFilter={setStatus}
            />
            <DropdownFilter
              options={transactionNetworkOptions}
              label="All Network Types"
              selectedValue={networkType}
              onChangeFilter={setNetworkType}
            />
            <DateTimeFilter
              label="Transaction date"
              date={transactionDate}
              setDate={setTransactionDate}
            />
            <DropdownFilter
              options={operationTypeOptions}
              label="All Operation Types"
              selectedValue={operationType}
              onChangeFilter={setOperationType}
            />
          </FilterContainer>
        </>
      )}
      <div className="flex justify-end mt-6 gap-2">
        <Button variant="secondary" onClick={onClear}>
          Clear filters
        </Button>
        <Button disabled={loading} onClick={onApply}>
          Apply filters
        </Button>
      </div>
    </div>
  );
};

export default FilterUserTransaction;
