import service from '@/service/http.service';
import {
  GetEmployeeRequest,
  EmployeeFilterDataResponse,
  GetEmployeeResponse,
  GetEmployeeInfoRequest,
  GetTransactionRequest,
  TransactionFilterDataResponse,
  PostEmployeeRequest,
  PostEmployeeResponse,
  PutEmployeeRequest,
  PutEmployeeResponse,
  GetTransactionResponse
} from '@/type/api';
import { EmployeeInformation, EmployeeWallet } from '@/type/employee';
import { AxiosError } from 'axios';

export const getEmployeeFilterData =
  (): Promise<EmployeeFilterDataResponse> => {
    return service
      .get('/employees/employees-filters')
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.status;
      });
  };

export const getEmployees = (
  params: GetEmployeeRequest
): Promise<GetEmployeeResponse> => {
  return service
    .get('/employees', {}, params)
    .then((response) => response.data)
    .catch((error) => {
      return error.status;
    });
};

export const postEmployees = (
  body: PostEmployeeRequest
): Promise<PostEmployeeResponse> => {
  return service
    .post('/employees', body)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};

export const putEmployees = (
  body: PutEmployeeRequest
): Promise<PutEmployeeResponse> => {
  return service
    .put(`/employees/${body.employeeId}`, body)
    .then((response) => response)
    .catch((error) => {
      return error;
    });
};

export const getEmployeeWallets = (
  params: GetEmployeeInfoRequest
): Promise<EmployeeWallet> => {
  return service
    .get('/employees/employee-wallets', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getTransactions = (
  params: GetTransactionRequest
): Promise<GetTransactionResponse> => {
  return service
    .get('/employees/employee-transactions', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getTransactionFilters = (
  customerId?: string
): Promise<TransactionFilterDataResponse> => {
  const params = customerId ? { customerId } : {};
  return service
    .get('/employees/employee-transactions-filters', {}, params)
    .then((response) => response.data)
    .catch((error) => error.status);
};

export const getEmployeeInfo = (id: string): Promise<any> => {
  return service
    .get('/employees/getemployeebyid', {}, { id })
    .then((response) => response.data)
    .catch((error: AxiosError) => {
      throw error;
    });
};