import React, { useMemo } from 'react';
import moment from 'moment';
import { toNormalizedNumber } from '@/utils/string';
import LineChart from '@/components/widgets/charts/LineChart';
import Card from '@/components/common/Card';
import { ActiveUserItem } from '@/type/dashboard';

type Props = {
  data: ActiveUserItem[];
  initialX: string[];
};

const ActiveUsersCard: React.FC<Props> = ({ data, initialX }) => {
  const total = useMemo(() => {
    return data?.reduce((sum, b) => sum + b.activeUsers, 0) || 0;
  }, [data]);

  const chartData = useMemo(() => {
    let x: string[] = [];
    const y: number[] = [];

    if (data && data.length) {
      data?.forEach((item) => {
        x.push(moment(item.transactionDate).format('MMM DD'));
        y.push(item.activeUsers);
      });
    } else {
      x = initialX;
    }

    return {
      x,
      y
    };
  }, [data, initialX]);

  return (
    <Card title="Active users" noData={!data?.length}>
      <div className="flex items-center mb-6 -mt-2">
        <h3 className="font-bold text-title">{`${toNormalizedNumber(
          total
        )}`}</h3>
      </div>

      <div className="-mb-4 -mx-2">
        <LineChart data={[chartData]} />
      </div>
    </Card>
  );
};

export default ActiveUsersCard;
